import Utils from "../../utility";
export const onSubmit = (values, navigate) => {
  return (dispatch) => {
    const { email, password } = values;
    const dataToSend = {
      username: email,
      password,
    };
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.postApiCall(
      Utils.endPoints.login,
      dataToSend,
      (respData) => {
        localStorage.setItem("accessToken", respData?.data?.token);
        localStorage.setItem("refreshToken", respData?.data?.refreshToken);

        localStorage.setItem("email", respData?.data?.email);
        localStorage.setItem("id", respData?.data?.id);
        localStorage.setItem("username", respData?.data?.userName);
        if (respData?.data?.id == 953)
          localStorage.setItem("userType", `${respData?.data?.userType}_953`);
        else localStorage.setItem("userType", respData?.data?.userType);
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        if (respData?.data?.userType === "CA") {
          navigate("/products");
          return;
        }
        if (["B", "PO"].includes(respData?.data?.userType)) {
          navigate("/store-royalty");
          return;
        }
        if (["BO"].includes(respData?.data?.userType)) {
          navigate("/store-stocks");
          return;
        } else navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message);
        Utils.showAlert(
          2,
          `${Utils.endPoints.login} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};
// logout;

export const logout = (navigate) => {
  if (localStorage.getItem("refreshToken") == null) {
    localStorage.clear();
    navigate("/");
    return;
  }
  return (dispatch) => {
    const dataToSend = {
      refreshToken: localStorage.getItem("refreshToken"),
    };

    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.postApiCall(
      Utils.endPoints.logout,
      dataToSend,
      (respData) => {
        localStorage.clear();
        navigate("/");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message);
        Utils.showAlert(
          2,
          `${Utils.endPoints.login} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};
