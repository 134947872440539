import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import { updateQuntity } from "./action";
import Input from "../../../component/input";
import { Box } from "@mui/material";
const AddUser = ({ setOpen, isEdit }) => {
  const dispatch = useDispatch();
  const initialValues = {
    quantity: null,
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      // validationSchema={Schema.storeStockSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        dispatch(updateQuntity(values, setOpen, isEdit[0].id));
      }}
    >
      {({
        values,
        errors,
        // touched,
        // setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        // isSubmitting,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit}>
          <Box sx={{ display: "flex", mb: 2 }}>
            <Input
              value={values.quantity}
              name="quantity"
              type="number"
              error={errors.quantity}
              label="quantity"
              onChange={handleChange}
              handleBlur={handleBlur}
            />
          </Box>

          <Box sx={{ display: "flex", mb: 2, justifyContent: "flex-end" }}>
            <Button variant="outlined" type="submit">
              {/* {isEdit?.length ? "" : "Add GRN"} */}
              submit
            </Button>
            <Button
              variant="outlined"
              style={{
                border: "1px solid #e13030",
                color: "#e13030",
                marginLeft: 10,
              }}
            >
              Close
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
    //  </Authlayout>
  );
};

export default AddUser;
