import React, { useState } from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import MenuItem from "@mui/material/MenuItem";
import DatePicker from "../../../component/date";
import AutoComplete from "../../../component/input/autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, FieldArray } from "formik";
import { updateCostAndSellingPrice } from "./action";
import Input from "../../../component/input";
import { Box } from "@mui/material";
import Select from "../../../component/select";
const AddUser = ({ setOpen, isEdit, setIsEdit, storeId }) => {
  const dispatch = useDispatch();
  // const { productsDropDownList } = useSelector((state) => state.productsReducer);
  const [grandTotal, setGrandTotal] = useState(0);
  const initialValues = {
    costPrice: "",
    mrp: "",
    sellingPrice: "",
    productId: "",
    // vendor: isEdit?.length ? isEdit[0]?.warehouseState : "",
  };
  const calculateGrandTotal = (values, index, currentValue, currentField) => {
    const grandTotal = values.reduce((total, item, i) => {
      if (i === index) {
        if (currentField === "productCostPrice")
          total = +currentValue * values[index].quantity + total;
        else total = +currentValue * values[index].productCostPrice + total;
      } else {
        total = item.total + total;
      }
      return total;
    }, 0);
    setGrandTotal(grandTotal);
  };
  // const { storeList } = useSelector((state) => state.storeReducer);
  const {
    storeListForDropdown,
    brandOwnerListForDropdown,
    productsDropDownList,
    // brandOwnerListForDropdown,
  } = useSelector((state) => state.commonReducer);

  // const { brandOwnerList } = useSelector((state) => state.brandOwnerReducer);
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      // validationSchema={Schema.storeStockSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        dispatch(updateCostAndSellingPrice(values));
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit}>
          <h2>Edit selling and cost price </h2>
          <Box sx={{ display: "flex", mb: 2 }}>
            <AutoComplete
              freeSolo={false}
              // onBlur={handleBlur}
              values={values?.productId}
              onChange={(event, value) => {
                if (value !== null) {
                  const pIndex = productsDropDownList.findIndex(
                    (x) => x.id === value.id
                  );
                  setFieldValue(`productId`, value?.id);

                  // handleChange(event);
                }
              }}
              helperText={touched.productId && errors?.productId}
              error={Boolean(touched.productId && errors?.productId)}
              name={`productId`}
              label={"Product Id"}
              // touched={touched.productId}
              options={productsDropDownList}
              getOptionLabel={(option) => `${option?.barcode} | ${option.name}`}
              // isRequired={true}
              getOptionSelected={(option, value) => {
                return `${option.id}` === `${value.id}`;
              }}
              defaultValue={() => {
                const pIndex = productsDropDownList.findIndex(
                  (x) => x?.id == values.productId
                );
                return productsDropDownList[pIndex];
              }}
            />

            <Input
              value={values.mrp}
              name="mrp"
              type="text"
              error={errors.mrp}
              label="mrp"
              onChange={handleChange}
              handleBlur={handleBlur}
            />
            <Input
              value={values.costPrice}
              name="costPrice"
              type="text"
              error={errors.costPrice}
              label="Cost price"
              onChange={handleChange}
              handleBlur={handleBlur}
            />
            <Input
              value={values.sellingPrice}
              name="sellingPrice"
              type="text"
              error={errors.sellingPrice}
              label="selling Price"
              onChange={handleChange}
              handleBlur={handleBlur}
            />
          </Box>
          {/* <Box sx={{ display: "flex", mb: 2 }}> */}

          <Box sx={{ display: "flex", mb: 2, justifyContent: "flex-end" }}>
            <Button variant="outlined" type="submit" >
              Submit
            </Button>
            {/* <Button
              variant="outlined"
              style={{
                border: "1px solid #e13030",
                color: "#e13030",
                marginLeft: 10,
              }}
            >
              Cancel
            </Button> */}
          </Box>
        </Form>
      )}
    </Formik>
    //  </Authlayout>
  );
};

export default AddUser;
