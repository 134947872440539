import React, { useEffect, useState } from "react";
import Button from "../../../component/button";
import MediaCard from "./bannerCard";
import DeleteConfirmationPopup from "../../../component/modal/deleteModel";
import Modal from "../../../component/modal";
import AddBannerPopup from "./addBanner";
import { useDispatch, useSelector } from "react-redux";
import { getBanner, deleteBanner } from "./action";
import { Box } from "@mui/material";
import { getCategoryList } from "../../productRelated/productCategory/action";
import { fileUpload } from "../../productRelated/products/action";
import { getBrandList } from "../../productRelated/brands/action";
const AddUser = () => {
  const dispatch = useDispatch();
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(null);
  const { bannerList } = useSelector((state) => state.bannerReducer);
  useEffect(() => {
    dispatch(getCategoryList());
    dispatch(getBrandList());
    dispatch(getBanner());
  }, []);
  return (
    <>
      <Box>
        <Button text="Add Banner" onPress={() => setOpen(true)} />
      </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {bannerList?.length &&
          typeof bannerList === "object" &&
          bannerList &&
          bannerList.map((item, i) => (
            <MediaCard
              photoURL={item.photoURL}
              position={item.position}
              handleUpdate={() => {
                setIsEdit(item);
                setOpen(true);
              }}
              handleDelete={() => {
                setDeleteConfirmation(item.id);
              }}
              brand={item.brand}
              category={item.category}
            />
          ))}
      </Box>
      <Modal
        header={`${isEdit?.id ? "Update" : "Add"} Banner`}
        children={
          <AddBannerPopup
            setOpen={setOpen}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            handleDelete={() => {
              setDeleteConfirmation(null);
            }}
          />
        }
        handleClose={() => {
          setOpen(false);
          setIsEdit(null);
        }}
        open={open}
        minWidth={1000}
      />
      <DeleteConfirmationPopup
        title="banner"
        handleClose={() => setDeleteConfirmation(null)}
        okButtonClick={() => {
          dispatch(deleteBanner(deleteConfirmation));
          setDeleteConfirmation(null);
        }}
        open={deleteConfirmation}
        minWidth={500}
      />
    </>

    //  </Authlayout>
  );
};

export default AddUser;
