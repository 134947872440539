import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import MenuItem from "@mui/material/MenuItem";

import AutoComplete from "../../../component/input/autocomplete";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, FieldArray } from "formik";
import { makeRtv } from "./action";
import Input from "../../../component/input";
import { Box } from "@mui/material";
import Select from "../../../component/select";
const AddUser = ({ setOpen, getUpdatedListing }) => {
  const dispatch = useDispatch();
  let isWarehouseRoutes = useLocation().pathname;
  // const { productsDropDownList } = useSelector((state) => state.productsReducer);
  const initialValues = {
    stocks: [
      {
        product: null,
        quantity: null,
      },
    ],
    store: null,
    warehouse: null,
  };

  // const { storeListForDropdown } = useSelector((state) => state.storeReducer);
  const {
    warehouseListForDropdown,
    storeListForDropdown,
    // brandOwnerListForDropdown,
    productsDropDownList,
    // brandOwnerListForDropdown,
  } = useSelector((state) => state.commonReducer);
  // const { warehouseListForDropdown } = useSelector(
  //   (state) => state.warehouseReducer
  // );
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      // validationSchema={Schema.makeStockTransferS2W}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        dispatch(
          makeRtv(
            values,
            () => {
              setOpen(false);
              getUpdatedListing();
            },
            setSubmitting
          )
        );
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit}>
          <Box sx={{ display: "flex", mb: 2 }}>
            {isWarehouseRoutes === "/rtvstocks" ? (
              <Select
                handleChange={handleChange}
                value={values.store}
                label={"Select store"}
                option={
                  storeListForDropdown?.length &&
                  storeListForDropdown.map((item) => (
                    <MenuItem key={item.id} value={item?.id}>
                      {item?.storename}
                    </MenuItem>
                  ))
                }
                name="store"
                error={errors.store}
              />
            ) : (
              <Select
                handleChange={handleChange}
                value={values.warehouse}
                label={"Select Warehouse"}
                option={warehouseListForDropdown.map((item) => (
                  <MenuItem key={item.id} value={item?.id}>
                    {item?.warehouseNickname}
                  </MenuItem>
                ))}
                name="warehouse"
                error={errors.warehouse}
              />
            )}
          </Box>

          <FieldArray
            name="stocks"
            render={(arrayHelpers) => {
              return (
                <div>
                  {values.stocks && values.stocks.length > 0
                    ? values.stocks.map((val, index) => (
                        <Box sx={{ display: "flex", mb: 2 }} key={index}>
                          <AutoComplete
                            freeSolo={false}
                            values={values?.stocks[index].product}
                            onChange={(event, value) => {
                              if (value !== null) {
                                const pIndex = productsDropDownList.findIndex(
                                  (x) => x.id === value.id
                                );
                                setFieldValue(
                                  `stocks.${index}.product`,
                                  value?.id
                                );

                                if (pIndex >= 0) {
                                }
                              }
                            }}
                            helperText={
                              touched.product && errors?.stocks[index]?.product
                            }
                            error={Boolean(
                              touched.product && errors?.stocks[index]?.product
                            )}
                            name={`stocks.${index}.product`}
                            label={"Product Id"}
                            options={productsDropDownList}
                            getOptionLabel={(option) =>
                              `${option?.barcode} | ${option.name}`
                            }
                            // isRequired={true}
                            getOptionSelected={(option, value) => {
                              return `${option.id}` === `${value.id}`;
                            }}
                            defaultValue={() => {
                              const pIndex = productsDropDownList.findIndex(
                                (x) => x?.id == values.stocks[index].product
                              );
                              return productsDropDownList[pIndex];
                            }}
                          />
                          <Input
                            value={values.stocks[index].quantity}
                            name={`stocks.${index}.quantity`}
                            type="number"
                            error={
                              errors.stocks && errors.stocks[index]?.quantity
                            }
                            // error={errors.product}
                            label="Inv. Quantity"
                            onChange={handleChange}
                            handleBlur={handleBlur}
                          />
                          {/* <Input
                            value={values.stocks[index].barcode}
                            name={`stocks.${index}.barcode`}
                            type="number"
                            error={
                              errors.stocks && errors.stocks[index]?.barcode
                            }
                            label="Barcode"
                            onChange={handleChange}
                            handleBlur={handleBlur}
                          /> */}

                          <IconButton
                            style={{
                              background: "rgb(231 112 112)",
                              color: "white",
                              width: 35,
                              height: 35,
                              borderRadius: 5,
                            }}
                            aria-label="delete"
                            size="small"
                            // color= "primary"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <DeleteIcon fontSize="inherit" />
                          </IconButton>

                          <IconButton
                            style={{
                              background: "green",
                              color: "white",
                              width: 35,
                              marginLeft: 5,
                              height: 35,
                              borderRadius: 5,
                            }}
                            aria-label="delete"
                            size="small"
                            // color= "primary"
                            onClick={() =>
                              arrayHelpers.push({
                                barcode: null,
                                qunatity: null,
                              })
                            }
                          >
                            <AddBoxTwoToneIcon fontSize="inherit" />
                          </IconButton>
                        </Box>
                      ))
                    : null}
                </div>
              );
            }}
          />

          <Box sx={{ display: "flex", mb: 2, justifyContent: "flex-end" }}>
            <Button variant="outlined" type="submit" disabled={isSubmitting}>
              Submit
            </Button>
            <Button
              variant="outlined"
              style={{
                border: "1px solid #e13030",
                color: "#e13030",
                marginLeft: 10,
              }}
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default AddUser;
