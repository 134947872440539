import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import { AddBanner, updateBanner } from "./action";
import { Box } from "@mui/material";
import AutoComplete from "../../../component/input/autocomplete";
import { fileUpload } from "../../productRelated/products/action";
import Select from "../../../component/select";
import UploadButtons from "../../../component/uploadImage";
const AddUser = ({ setOpen, isEdit, setIsEdit, storeId }) => {
  const dispatch = useDispatch();
  const [bannerUrl, setBannerUrl] = useState(null);
  const initialValues = {
    categoryId: isEdit?.id ? isEdit?.category?.id : null,
    brandId: isEdit?.id ? isEdit?.brand?.id : "",
    typeOfBanner: isEdit?.category?.id
      ? "category"
      : isEdit?.brand?.id
      ? "brand"
      : "",
    position: isEdit?.id ? isEdit?.position : "",
  };
  const { brandsForDropdown: brands, categoryListForDropDown: categoryList } =
    useSelector((state) => state.commonReducer);
  // const { categoryListForDropdown: categoryList } = useSelector(
  //   (state) => state.categoryReducer
  // );
  useEffect(() => {
    setBannerUrl(isEdit?.photoURL);
  }, [isEdit]);
  const handleChanegImage = (e) => {
    dispatch(
      fileUpload(e.target.files[0], (res) => {
        setBannerUrl(res?.Location);
        e.terget.value = null;
      })
    );
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          if (isEdit?.id) {
            dispatch(
              updateBanner(values, bannerUrl, setOpen, isEdit.id, setIsEdit)
            );
          } else {
            dispatch(AddBanner(values, bannerUrl, setOpen));
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box>
              <Box sx={{ display: "flex", mb: 2 }}>
                <Select
                  handleChange={(e) => {
                    handleChange(e);
                    setFieldValue("brandId", "");
                    setFieldValue("categoryId", "");
                  }}
                  value={values.typeOfBanner}
                  label={"Type of banner"}
                  option={["brand", "category"]?.map((item, i) => (
                    <MenuItem key={i} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                  name="typeOfBanner"
                  error={errors.typeOfBanner}
                />

                {values.typeOfBanner === "brand" ? (
                  // <Select
                  //   handleChange={handleChange}
                  //   value={values.brandId}
                  //   label={"brands"}
                  //   option={brands.map((item) => (
                  //     <MenuItem key={item.id} value={item?.id}>
                  //       {item?.brandName}
                  //     </MenuItem>
                  //   ))}
                  //   name="brandId"
                  //   error={errors.brandId}
                  // />
                  <React.Fragment key={values.typeOfBanner}>
                    <AutoComplete
                      freeSolo={false}
                      values={values.brandId}
                      onChange={(event, value) => {
                        if (value !== null) {
                          setFieldValue(`brandId`, value?.id);
                        }
                      }}
                      helperText={touched?.brandId && errors?.brandId}
                      error={Boolean(touched?.brandId && errors?.brandId)}
                      name={`brandId`}
                      label={"brand"}
                      // touched={touched.product}
                      options={brands}
                      getOptionLabel={(option) => `${option?.brandName} `}
                      // isRequired={true}
                      getOptionSelected={(option, value) => {
                        return `${option.id}` === `${value.id}`;
                      }}
                      defaultValue={() => {
                        const pIndex = brands.findIndex(
                          (x) => x?.id == values.brands
                        );
                        return brands[pIndex];
                      }}
                    />
                  </React.Fragment>
                ) : values.typeOfBanner === "category" ? (
                  // <Select
                  //   handleChange={handleChange}
                  //   value={values.categoryId}
                  //   label={"category"}
                  //   option={categoryList?.map((item) => (
                  //     <MenuItem key={item.id} value={item.id}>
                  //       {item.categoryName}
                  //     </MenuItem>
                  //   ))}
                  //   name="categoryId"
                  //   error={errors.categoryId}
                  // />
                  <React.Fragment key={values.typeOfBanner}>
                    <AutoComplete
                      freeSolo={false}
                      // key={values.typeOfBanner}
                      values={values.categoryId}
                      onChange={(event, value) => {
                        if (value !== null) {
                          setFieldValue(`categoryId`, value?.id);
                        }
                      }}
                      helperText={touched?.categoryId && errors?.categoryId}
                      error={Boolean(touched?.categoryId && errors?.categoryId)}
                      name={`categoryId`}
                      label={"category"}
                      // touched={touched.product}
                      options={categoryList}
                      getOptionLabel={(option) => `${option?.categoryName} `}
                      // isRequired={true}
                      getOptionSelected={(option, value) => {
                        return `${option.id}` === `${value.id}`;
                      }}
                      defaultValue={() => {
                        const pIndex = categoryList.findIndex(
                          (x) => x?.id == values.categoryId
                        );
                        return categoryList[pIndex];
                      }}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </Box>
              <Box sx={{ display: "flex", mb: 2 }}>
                <Select
                  handleChange={handleChange}
                  value={values.position}
                  label={"Position"}
                  option={["top", "bottom"]?.map((item, i) => (
                    <MenuItem key={i} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                  name="position"
                  error={errors.position}
                />
              </Box>
              <Box sx={{ width: 300, display: "flex" }}>
                <UploadButtons
                  handleChange={(e) =>
                    handleChanegImage(e, setFieldValue, "fssaiImage")
                  }
                  lable="Upload Banner Image"
                  image={bannerUrl}
                  onDelete={() => setBannerUrl(null)}
                  id="banner image"
                />
              </Box>
              <Box sx={{ display: "flex", mb: 2, justifyContent: "flex-end" }}>
                <Button variant="outlined" type="submit">
                  {isEdit?.id ? "Update" : "Save"}
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddUser;
