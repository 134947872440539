import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateVersion, getAppVersion } from "./action";
import Input from "../../../component/input";
import { Box, Button } from "@mui/material";
const AddUser = () => {
  const dispatch = useDispatch();

  const [appVersion, setAppversion] = useState("");
  useEffect(() => {
    dispatch(getAppVersion((res) => setAppversion(res?.data?.version)));
  }, []);
  return (
    <>
      <Box sx={{ display: "flex", mb: 2 }}>
        <Input
          value={appVersion}
          name="appVersion"
          type="text"
          //   error={errors.terms}
          label="App version"
          onChange={(e) => setAppversion(e.target.value)}
          //   handleBlur={handleBlur}
        />
      </Box>

      <Box sx={{ display: "flex", mb: 2, justifyContent: "flex-end" }}>
        <Button
          variant="outlined"
          onClick={() => dispatch(updateVersion(appVersion))}
        >
          update Version
        </Button>
      </Box>
    </>
  );
};

export default AddUser;
