import Utils from "../../../utility";
import { removeEmptyValues } from "../../../utility/commonFunction";
export const updateProducts = (
  values,
  setOpen,
  id,
  resetForm,
  setIsEdit,
  setSubmitting
) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const {
      frontImage,
      backImage,
      leftImage,
      rightImage,
      fssaiImage,
      nutritionImage,
      subCategory,
      category,
    } = values;
    const images = {
      frontImage,
      backImage,
      leftImage,
      rightImage,
      fssaiImage,
      nutritionImage,
    };
    if (!frontImage) {
      delete images.frontImage;
    }
    if (!backImage) {
      delete images.backImage;
    }
    if (!leftImage) {
      delete images.leftImage;
    }

    if (!rightImage) {
      delete images.rightImage;
    }
    if (!fssaiImage) {
      delete images.fssaiImage;
    }
    if (!nutritionImage) {
      delete images.nutritionImage;
    }
    const dataToSend = { ...values };
    dataToSend.category = subCategory || category;
    dataToSend.images = images;
    delete dataToSend.frontImage;
    delete dataToSend.backImage;
    delete dataToSend.leftImage;
    delete dataToSend.rightImage;
    delete dataToSend.fssaiImage;
    delete dataToSend.nutritionImage;
    dataToSend.commissionPercentage = +values.commissionPercentage;
    dataToSend.productACess = +values.productACess;
    dataToSend.productCess = +values.productCess;
    dataToSend.barcode = `${values.barcode}`;
    if (dataToSend.isRecipe) dataToSend.isRecipe = "true";
    else dataToSend.isRecipe = "";
    if (values.unit) dataToSend.unit = `${dataToSend.unit}`;
    if (Object.keys(images).length === 0) delete dataToSend?.images;
    //  {
    //   name: values.name,
    //   barcode: values.barcode,
    //   description: values.description,
    //   weight: values.weight,
    //   category: values.category,
    //   // subCategory: values.subCategory,
    //   GST: values.GST,
    //   HSNCode: values.HSNCode,
    //   packaging: values.packaging,
    //   images: values.images??"ghgh",
    //   commissionPercentage: values.commissionPercentage,
    //   productACess: values.productACess,
    //   productCess: values.productCess,
    //   storageType: values.storageType,
    //   brands: values.brands,
    //   brandsManager: values.brandsManager,
    //   deliveryType: values.deliveryType,
    // };

    removeEmptyValues(dataToSend);
    if (values.ingradientOrRecipe === "Recipe") {
      dataToSend.isrecipe = "true";
      dataToSend.isIngredient = false;
    } else if (values.ingradientOrRecipe === "Ingredient") {
      dataToSend.isIngredient = true;
      dataToSend.isrecipe = "false";
    } else if (!values.ingradientOrRecipe) {
      dataToSend.isrecipe = "false";
      dataToSend.isIngredient = false;
    }
    delete dataToSend.ingradientOrRecipe;
    delete dataToSend.subCategory;
    delete dataToSend?.categoryName
    Utils.api.putApiCall(
      Utils.endPoints.products + id,
      dataToSend,
      (respData) => {
        setOpen(false);
        Utils.showAlert(1, "Product updated successfully");
        // dispatch(getAllUser());
        resetForm({});
        setIsEdit(null);
        dispatch({
          type: Utils.ActionName.PRODUCTS,
          payload: { images: [] },
        });
        dispatch(getProducts());
        dispatch(getProductsForDropdown());
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        setSubmitting(false);
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        Utils.showAlert(
          2,
          `update ${Utils.endPoints.products} PUT Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
      }
    );
  };
};
export const addProduct = (values, setOpen, setSubmitting) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    // const { images } = getState().productsReducer;
    const {
      frontImage,
      backImage,
      leftImage,
      rightImage,
      fssaiImage,
      nutritionImage,
      subCategory,
      category,
    } = values;
    const dataToSend = { ...values };
    const images = {
      frontImage,
      backImage,
      leftImage,
      rightImage,
      fssaiImage,
      nutritionImage,
    };
    if (!frontImage) {
      delete images.frontImage;
    }
    if (!backImage) {
      delete images.backImage;
    }
    if (!leftImage) {
      delete images.leftImage;
    }

    if (!rightImage) {
      delete images.rightImage;
    }
    if (!fssaiImage) {
      delete images.fssaiImage;
    }
    if (!nutritionImage) {
      delete images.nutritionImage;
    }
    // dataToSend.isDeleted = false;
    dataToSend.images = images;
    dataToSend.barcode = `${values.barcode}`;
    dataToSend.category = subCategory || category;
    // else dataToSend.isrecipe = "";
    if (values.unit) dataToSend.unit = `${dataToSend.unit}`;
    // delete dataToSend.frontImage;
    // delete dataToSend.backImage;
    // delete dataToSend.leftImage;
    // delete dataToSend.rightImage;
    // delete dataToSend.fssaiImage;
    // delete dataToSend.nutritionImage;
    removeEmptyValues(dataToSend);
    // }
    if (values.ingradientOrRecipe === "Recipe") {
      dataToSend.isrecipe = "true";
      dataToSend.isIngredient = false;
    } else if (values.ingradientOrRecipe === "Ingredient") {
      dataToSend.isIngredient = true;
      dataToSend.isrecipe = "false";
    } else if (!values.ingradientOrRecipe) {
      dataToSend.isrecipe = "false";
      dataToSend.isIngredient = false;
    }
    delete dataToSend.ingradientOrRecipe;
    delete dataToSend.subCategory;
    Utils.api.postApiCall(
      Utils.endPoints.createProducts,
      dataToSend,
      (respData) => {
        setOpen(false);
        Utils.showAlert(1, "Product added successfully");
        dispatch(getProducts());
        dispatch(getProductsForDropdown());
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.PRODUCTS,
          payload: { images: [] },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `${Utils.endPoints.createProducts} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        setSubmitting(false);
      }
    );
  };
};

export const deleteProduct = (id) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.deleteApiCall(
      Utils.endPoints.products + `${id}`,
      "",
      (respData) => {
        Utils.showAlert(1, "Brand delete sucesfully");
        dispatch(getProducts());
        dispatch(getProductsForDropdown());
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `delete ${Utils.endPoints.products} DELETE Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const getProducts = () => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const { order, sortField, offset, take, search } =
      getState().productsReducer;
    Utils.api.getApiCall(
      Utils.endPoints.products,
      `?order=${order}${
        sortField ? "&sortField=" + sortField : ""
      }&offset=${offset}&search=${search}&take=${take}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.PRODUCTS,
          payload: {
            products: respData.data?.product,
            count: respData?.data?.count,
          },
        });
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `${Utils.endPoints.products} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};

// export const getProductsWithRecipe = () => {
//   return (dispatch, getState) => {
//     dispatch({
//       type: Utils.ActionName.LOADING,
//       payload: { loading: true },
//     });
//     const { order, sortField, offset, take, search } =
//       getState().productsReducer;
//     axios
//       .get(
//         `${
//           Utils.endPoints.products +
//           `?order=${order}${
//             sortField ? "&sortField=" + sortField : ""
//           }&offset=${offset}&search=${search}&take=${take}`
//         }`,
//         {
//           data: {
//             isRecipe: true,
//           },
//         }
//       )
//       .then((response) => {
//         this.posts = response.data;
//       })
//       .catch((err) => {
//         alert(err);
//         console.log(err);
//       });
//     // Utils.api.getApiCall(

//     //   (respData) => {
//     //     dispatch({
//     //       type: Utils.ActionName.PRODUCTS,
//     //       payload: {
//     //         products: respData.data?.product,
//     //         count: respData?.data?.count,
//     //       },
//     //     });
//     //     dispatch({
//     //       type: Utils.ActionName.LOADING,
//     //       payload: { loading: false },
//     //     });
//     //   },
//     //   (error) => {
//     //     dispatch({
//     //       type: Utils.ActionName.LOADING,
//     //       payload: { loading: false },
//     //     });
//     //     let { data } = error;
//     //     // Utils.showAlert(2, data?.message?.join(",") || data?.error);
//     //     Utils.showAlert(
//     //       2,
//     //       `${Utils.endPoints.products} GET Method error ${
//     //         JSON.stringify(data?.message) || data?.error
//     //       }`
//     //     );
//     //     // setSubmitting(true);
//     //   }
//     // );
//   };
// };

export const getProductsForDropdown = () => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    if (localStorage.getItem("productLength") == "Pending") return;
    localStorage.setItem("productLength", "Pending");

    const { order } = getState().productsReducer;
    Utils.api.getApiCall(
      Utils.endPoints.productsForDropdown,

      `?order=${order}&offset=1&search=`,
      (respData) => {
        console.log(respData.data?.products);
        localStorage.setItem("productLength", respData?.data?.products?.length);
        const data = respData.data?.products.map(({ id, barcode, name }) => {
          return {
            id,
            barcode,
            name,
          };
        });
        dispatch({
          type: Utils.ActionName.COMMON,
          payload: {
            productsDropDownList: data,
          },
        });

        localStorage.setItem("productList", JSON.stringify(data));

        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
      },
      (error) => {
        localStorage.removeItem("productLength");
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `${Utils.endPoints.products} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};

export const fileUpload = (file, saveImageUrl) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const { images } = getState().productsReducer;
    // const { brandName } = values;
    var dataToSend = new FormData();
    dataToSend.append("file", file);
    Utils.api.postApiCall(
      Utils.endPoints.fileUpload,
      dataToSend,
      (respData) => {
        Utils.showAlert(1, "File uploaded successfully");
        dispatch({
          type: Utils.ActionName.PRODUCTS,
          payload: { images: [...images, respData.data.Location] },
        });
        saveImageUrl(respData.data);

        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // setOpen(false);
        // dispatch(getProducts());
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `${Utils.endPoints.fileUpload} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};
