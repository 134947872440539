import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import OkButton from "../button";
import CancelButton from "../button/redOutlineButton";
import Stack from "@mui/material/Stack";

import { Divider } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
  overflow: "auto",
};

export default function TransitionsModal({
  title,
  handleClose,
  open,
  minWidth,
  okButtonClick,
}) {
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} style={minWidth ? { maxWidth: minWidth } : {}}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Delete {title}
              </Typography>
              <CancelIcon onClick={handleClose} />
            </Box>
            <Divider />
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              {`Are you sure you want to delete ${title}?`}
            </Typography>
            <Stack
              direction="row"
              spacing={2}
              sx={{ justifyContent: "center", mt: 2 }}
            >
              <OkButton text="Ok" onPress={okButtonClick} />
              <CancelButton text="Cancel" onPress={handleClose} />
            </Stack>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
