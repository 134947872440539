import React, { useState } from "react";
import Button from "@mui/material/Button";
import Modal from "../../../component/modal";

// import { getStoreList } from "../store/action";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { CSVLink } from "react-csv";
import DatePicker from "../../../component/date";
import AutoComplete from "../../../component/input/autocomplete";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import NormalInput from "../../../component/input";
// import { getSKUTimeline, getSKUTimelineAll } from "./action";
import { Box } from "@mui/material";

import Utils from "../../../utility";
import { getStoreLedger } from "./action";
import { useLocation } from "react-router-dom";
import { getUserTypeFromLs } from "../../../utility/commonFunction";
import Schema from "../../../schema";

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "Id",
    sortDisable: true,
  },
  {
    id: "brandName",
    numeric: false,
    disablePadding: true,
    label: "Date",
    sortDisable: false,
  },
  {
    id: "dateCreated",
    numeric: false,
    disablePadding: true,
    label: "Logs",
    sortDisable: true,
  },

  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Previous Quantity",
    sortDisable: true,
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Currnet Quantity Quantity",
    sortDisable: true,
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={
              orderBy === headCell.id ? order.toLowerCase() : false
            }
          >
            {headCell?.sortDisable ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={
                  orderBy === headCell.id ? order.toLowerCase() : "asc"
                }
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span">
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable() {
  const location = useLocation();
  const [confirmation, setConfirmation] = useState(false);
  // console.log(location, "location");
  const [cvcData, setCvcData] = useState([]);

  const [initialValue, setInitialValue] = useState({});
  const dispatch = useDispatch();
  const initialValues = {
    storeId: null,
    toDate: moment(),
    fromDate: moment(),
    email: "",
  };

  const headers = [
    { label: "Id", key: "id" },
    { label: "Created At", key: "createdAt" },
    { label: "Time", key: "time" },
    { label: "Cash Pending", key: "cashPending" },

    { label: "reason", key: "reason" },
    { label: "doneBy", key: "doneBy" },
    { label: "Store Name", key: "storeName" },
    // { label: "Cash Pending", key: "cashPending" },
  ];
  // const { storeListForDropdown } = useSelector((state) => state.storeReducer);
  // const {  } = useSelector((state) => state.SkuTimelineReducer);
  // const { productsDropDownList } = useSelector((state) => state.productsReducer);
  const { cashPendingReport, cashoutReport } = useSelector(
    (state) => state.reportReducer
  );

  React.useEffect(() => {
    return () => {
      dispatch({
        type: Utils.ActionName.STORE_AND_WAREHOUSE_REPORTS,
        payload: {
          cashoutReport: [],
          // count: respData.data[1],
        },
      });
    };
  }, [location.pathname]);
  const {
    // warehouseListForDropdown,
    storeListForDropdown,
    // brandOwnerListForDropdown,
    productsDropDownList,
    // brandOwnerListForDropdown,
  } = useSelector((state) => state.commonReducer);
  const csvDownload = React.useRef(null);
  React.useEffect(() => {
    if (cashPendingReport?.length) {
      const GRNCVC = cashPendingReport.map((item) => {
        return {
          cashPending: item?.cashPending,
          createdAt: item?.createdAt,
          id: item?.id,
        };
      });
      setCvcData(GRNCVC);
    }
  }, [cashPendingReport]);

  const csvDownloadCashout = React.useRef(null);
  React.useEffect(() => {
    if (cashoutReport?.length) {
      const GRNCVC = cashoutReport?.map((item) => {
        // console.log(moment(item?.addDate).format("HH:mm"),"shashank")
        // then use this code
        var serverTime = item?.addDate?.replace("T", " ");
        let today = serverTime?.split(".")[0].split(" ")[1];
        let date = serverTime?.split(".")[0].split(" ")[0];
        //  console.log(serverTime,today)
        return {
          cashPending: item?.cashOutAmount,
          createdAt: date,
          id: item?.id,
          reason: item?.reason,
          doneBy: item?.doneBy?.name,
          time: today,
          storeName: item?.store?.storename,
        };
      });
      setCvcData(GRNCVC);
    }
  }, [cashoutReport]);

  return (
    <div>
      <h2>
        {location?.pathname.slice(1).split("-").join(" ")?.toLocaleUpperCase()}
      </h2>
      <Box direction="row" spacing={2} sx={{ display: "flex", mb: 2 }}>
        <Formik
          initialValues={initialValues}
          validationSchema={Schema.LedgerSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setInitialValue(values);
            dispatch(getStoreLedger(values, setConfirmation));
            resetForm();
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form onSubmit={handleSubmit}>
              <Box sx={{ display: "flex" }}>
                {/* {console.log(values, errors, "shashank")} */}
                <Stack
                  direction="row"
                  key={initialValue?.email}
                  spacing={2}
                  style={{ width: 200 }}
                >
                  <AutoComplete
                    freeSolo={false}
                    // onBlur={handleBlur}
                    values={values.storeId}
                    onChange={(event, value) => {
                      if (value !== null) {
                        const index = storeListForDropdown.findIndex(
                          (x) => x.id === value.id
                        );
                        setFieldValue("storeId", value?.id);
                      }
                    }}
                    helperText={touched.storeId && errors.storeId}
                    error={Boolean(touched.storeId && errors.storeId)}
                    name={"storeId"}
                    label={"storeId"}
                    touched={touched.storeId}
                    options={storeListForDropdown}
                    getOptionLabel={(option) => option?.storename}
                    // isRequired={true}
                    getOptionSelected={(option, value) => {
                      return `${option.id}` === `${value.id}`;
                    }}
                    defaultValue={() => {
                      const index = storeListForDropdown?.findIndex(
                        (x) => x?.id == values.storeId
                      );
                      return storeListForDropdown[index];
                    }}
                  />
                </Stack>

                <Stack style={{ width: 200 }}>
                  <NormalInput
                    value={values.email}
                    name="email"
                    type="text"
                    error={errors.email}
                    label="Email"
                    onChange={handleChange}
                    handleBlur={handleBlur}
                  />
                </Stack>
                <Stack style={{ width: 200 }}>
                  <DatePicker
                    value={values.fromDate}
                    name="fromDate"
                    handleChange={(newVal) => setFieldValue("fromDate", newVal)}
                    label="From Date"
                    error={errors.fromDate}
                  />
                </Stack>
                <Stack direction="row" spacing={2} style={{ width: 200 }}>
                  <DatePicker
                    value={values.toDate}
                    name="toDate"
                    handleChange={(newVal) => setFieldValue("toDate", newVal)}
                    label="To Date"
                    error={errors.toDate}
                  />
                </Stack>

                <Button
                  variant="outlined"
                  spacing={2}
                  sx={{ mr: 2, height: "39px" }}
                  type="submit"
                >
                  Submit
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
      <Divider />
      <CSVLink
        data={cvcData}
        headers={headers}
        filename={"CashpendingReport"}
        ref={csvDownload}
        target="_blank"
      />
      <CSVLink
        data={cvcData}
        headers={headers}
        filename={"CashoutReport"}
        ref={csvDownloadCashout}
        target="_blank"
      />
      <Modal
        header={`Confirmation`}
        children={
          <div>
            <h2>Ledger report will be emailed to you in 1-3 hours.</h2>
            <Box sx={{ display: "flex", my: 2, justifyContent: "flex-end" }}>
              <Button variant="outlined" onClick={() => setConfirmation(false)}>
                OK
              </Button>

              {/* <h1 onClick={() => }>sda</h1> */}
            </Box>
          </div>
        }
        handleClose={() => {
          setConfirmation(false);
          // setIsEdit(null);
        }}
        open={confirmation}
        // minWidth={"85%"}
      />
    </div>
  );
}
