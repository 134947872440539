import * as React from 'react'
import Box from '@mui/material/Box'
import { Formik, Form } from 'formik'
import DatePicker from '../../../component/date'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

// import BarcodeInput from "../../component/inputs/barcodeInput";
// import ItemCard from "../../component/card/productCard";
// import Drawer from "../../component/drawer";
import TablePagination from '@mui/material/TablePagination'
import AutoComplete from '../../../component/input/autocomplete'

import Paper from '@mui/material/Paper'
import SingleCard from './singleOrder'
// import ADDProduct from './addProduct'
// import navy_alarm from './navy_alarm.mp3'

// import AlarmAudio from '../../../assets/'
// import { Divider, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
// import Modal from "../../component/modal";
import Utils from '../../../utility'
// import Cash from "./onlinePopup";
// import Ofline from "./oflinePopup";
import { useDispatch } from 'react-redux'
import { getAllOrders, getRunnerList, getAllOrdersTimeInterval } from './action'
import { useSelector } from 'react-redux'
// import moment from 'moment'
const useStyles = makeStyles((theme) => ({
  cashCollectMethod: {
    backgroundColor: 'black',
    color: 'white',
    padding: '10px 25px',
    marginRight: '1px !important',
    cursor: 'pointer',
  },
  amount: {
    width: 60,
    '&>input': {
      padding: 10,
      boxSizing: 'border-box',
      width: '100%',
    },
  },
}))
export default function Index() {
  // const classes = useStyles()
  const dispatch = useDispatch()
  // const [barcode, setBarcode] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  // const [isOnline, setIsonline] = React.useState(false)

  // const [song] = React.useState(new Audio(navy_alarm))
  // const [discounPersentage, setDiscountPercentage] = React.useState()
  // const [discounAmount, setDiscountAmount] = React.useState()
  const {
    ordersList,
    filterdOrder,
    offset,
    // count,
    ordersListStatus,
    take,
    fromDate,
    toDate,
  } = useSelector((state) => state.onlineOrdersReducer)

  const {
    // warehouseListForDropdown,
    storeListForDropdown,
    // brandOwnerListForDropdown,
    // productsDropDownList,
    // brandOwnerListForDropdown,
  } = useSelector((state) => state.commonReducer)
  // React.useEffect(() => {
  //   dispatch(getAllOrders())
  //   dispatch(getRunnerList())
  //   return () => {
  //     dispatch({
  //       type: Utils.ActionName.ONLINE_ORDERS,
  //       payload: {
  //         ordersList: [],
  //         // count: respData?.data?.count,
  //       },
  //     })
  //   }
  // }, [])

  // React.useEffect(() => {}, [discounAmount]);
  // React.useEffect(() => {}, [discounPersentage]);
  // console.log(addedProduct, "products");

  React.useEffect(() => {
    dispatch(getAllOrders())
    // dispatch(getRunnerList())
    return () => {
      dispatch({
        type: Utils.ActionName.ONLINE_ORDERS,
        payload: {
          ordersList: [],
          // count: respData?.data?.count,
        },
      })
    }
  }, [])
  // const { ordersList, filterdOrder, offset, count, take } = useSelector(
  //   (state) => state.ordersReducer
  // );
  // React.useEffect(() => {
  //   // song.play()
  //   const interval = setInterval(
  //     () =>
  //       dispatch(
  //         getAllOrdersTimeInterval((res, preCount) => {
  //           if (res !== preCount) {
  //             song.play()
  //             setTimeout(() => {
  //               song.pause()
  //             }, 5000)
  //             dispatch({
  //               type: Utils.ActionName.ONLINE_ORDERS,
  //               payload: {
  //                 // orderList: respData.data?.order,
  //                 count: res,
  //               },
  //             })
  //           }
  //         }),
  //       ),
  //     180 * 1000,
  //   )

  //   return () => {
  //     if (interval) {
  //       clearInterval(interval)
  //     }
  //   }
  // }, [])
  React.useEffect(() => {
    // (res) => {
    if (ordersList.length) {
      const newData = ordersList.map((item, index) => {
        return {
          index,
          id: item.id,
          orderId: item?.orderOutNumber,
          orderDate: item?.orderDate,
          customerName: item.customer?.name,
          orderAmountNet: item.orderAmountNet,
          orderAmountTotal: item.orderAmountTotal,
          phoneNumber: item?.customer?.phoneNumber,
          addressLine1: item?.fromAddress?.addressLine1,
          addressLine2: item?.fromAddress?.addressLine2,
          lat: item?.fromAddress?.lat,
          long: item?.fromAddress?.long,
          token: item?.token,
          totalQuantity: item.totalQuantity,
          orderStatus: item?.orderStatus,
          runnerId: item?.runner?.id,
          stateInvoiceNo: item?.stateInvoiceNo,
          storeName: item?.store?.storename,
          storeId: item?.store?.id,
          pickupMode: item?.deliveryMode,
          internal_order_id: item?.zomato?.internalOrderID,
          zomato: item?.zomato,
          products: item.actualBillingSent.map((prod) => {
            return {
              quantity: prod?.quantity,
              sellingPrice: prod?.sellingPrice,
              name: prod?.product?.name,
              id: prod?.product?.id,
              barcode: prod?.product?.barcode,
              status: '',
            }
          }),
        }
      })

      // if (newData?.length) debugger
      dispatch({
        type: Utils.ActionName.ONLINE_ORDERS,
        payload: {
          filterdOrder: newData,
        },
      })
    } else
      dispatch({
        type: Utils.ActionName.ONLINE_ORDERS,
        payload: {
          filterdOrder: [],
        },
      })
  }, [ordersList, ordersListStatus])
  // const { permissions } = useSelector((state) => state.commonReducer)
  const handleChangePage = (event, newPage) => {
    dispatch({
      type: Utils.ActionName.ONLINE_ORDERS,
      payload: { offset: newPage + 1 },
    })
    dispatch(getAllOrders())
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: Utils.ActionName.ONLINE_ORDERS,
      payload: { take: event.target.value, offset: 1 },
    })
    dispatch(getAllOrders())
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  const initialValues = {
    storeId: null,
    toDate,
    fromDate,
  }

  return (
    <React.Fragment>
      <Box direction="row" spacing={2} sx={{ display: 'flex', mb: 2 }}>
        <Formik
          initialValues={initialValues}
          // validationSchema={Schema.SKUTimelineSchema}
          onSubmit={(values, { setSubmitting }) => {
            // setInitialValue(values);

            dispatch({
              type: Utils.ActionName.ONLINE_ORDERS,
              payload: {
                take: 25,
                offset: 1,
                ...values,

                // count: respData?.data?.count,
              },
            })
            dispatch(getAllOrders(values))
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Box sx={{ display: 'flex' }}>
                {/* <Stack direction="row" spacing={2} style={{ width: 200 }}>
                  <AutoComplete
                    freeSolo={false}
                    // onBlur={handleBlur}
                    values={values.storeId}
                    onChange={(event, value) => {
                      console.log(event.target.value, value)
                      if (value !== null) {
                        const index = storeListForDropdown.findIndex(
                          (x) => x.id === value.id,
                        )

                        setFieldValue('storeId', value?.id)
                        setFieldValue('storeName', value?.storename)
                        dispatch({
                          type: Utils.ActionName.SKU_TIMELINE,
                          payload: { skuData: [] },
                        })
                      }
                    }}
                    helperText={touched.storeId && errors.storeId}
                    error={Boolean(touched.storeId && errors.storeId)}
                    name={'storeId'}
                    label={'store Name'}
                    touched={touched.storeId}
                    options={storeListForDropdown}
                    getOptionLabel={(option) => option?.storename}
                    // isRequired={true}
                    getOptionSelected={(option, value) => {
                      return `${option.id}` === `${value.id}`
                    }}
                    defaultValue={() => {
                      const index = storeListForDropdown?.findIndex(
                        (x) => x?.id == values.storeId,
                      )
                      return storeListForDropdown[index]
                    }}
                  />
                </Stack> */}

                <Stack style={{ width: 200 }}>
                  <DatePicker
                    value={values.fromDate}
                    name="fromDate"
                    handleChange={(newVal) => setFieldValue('fromDate', newVal)}
                    label="From Date"
                    error={errors.fromDate}
                  />
                </Stack>
                <Stack direction="row" spacing={2} style={{ width: 200 }}>
                  <DatePicker
                    value={values.toDate}
                    name="toDate"
                    handleChange={(newVal) => setFieldValue('toDate', newVal)}
                    label="To Date"
                    error={errors.toDate}
                  />
                </Stack>
                <Button
                  variant="outlined"
                  spacing={2}
                  sx={{ mr: 2 }}
                  type="submit"
                >
                  Submit
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
      <Box>
        {filterdOrder?.length &&
          filterdOrder.map((item, i) => (
            <Paper sx={{ my: 2 }} key={i}>
              <SingleCard data={item} open={open} setOpen={setOpen} index={i} />
            </Paper>
          ))}
      </Box>
      <TablePagination
        rowsPerPageOptions={[10, 25]}
        component="div"
        count={
          take === filterdOrder?.length
            ? (offset + 1) * take
            : (offset - 1) * take + filterdOrder?.length
        }
        rowsPerPage={take}
        page={offset - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {/* <Modal
        header={"Add Product"}
        children={<ADDProduct />}
        handleClose={() => {
          setOpen(false);
          // setIsEdit(null);
        }}
        open={open}
        minWidth={1000}
      /> */}
    </React.Fragment>
  )
}
