import React from "react";
import Button from "@mui/material/Button";
// import { getStoreList } from "../../storeRelated/storeId/action";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
// import Paper from "@mui/material/Paper";
// import { CSVLink } from "react-csv";
import DatePicker from "../../../component/date";
import AutoComplete from "../../../component/input/autocomplete";
import { generatReport, getOrderExportList } from "./action";
import Schema from "../../../schema";
import moment from "moment";
// import { useNavigate, useHistory } from "react-router-dom";
// import Uti from "../../utility/utils";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
// import Authlayout from "../../component/layout/authLayout";
// import { getSKUTimeline, getSKUTimelineAll } from "./action";
// import Input from "../../../component/input";
import { Box } from "@mui/material";

// import Select from "../../../component/select";
import Utils from "../../../utility";
// import { getProducts } from "../../productRelated/products/action";
// import DetailGRN from "./detailGRN";
// import Modal from "../../component/modal"
// import AddUser from "./addstore";

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly

export default function EnhancedTable({ csvDownload }) {
  // const [csvData, setCsvData] = useState([]);
  // const [initialValue, setInitialValue] = useState({});
  const dispatch = useDispatch();
  const initialValues = {
    storeId: null,
    toDate: moment(),
    fromDate: moment(),
  };

  // const headers = [
  //   { label: "Product ID", key: "ProductID" },
  //   { label: "Store Name", key: "storeName" },
  //   { label: "Product Name", key: "ProductName" },
  //   { label: "Date", key: "date" },
  //   { label: "status", key: "status" },
  //   { label: "Previouse Quantity", key: "prevQuantity" },
  //   { label: "Current Quantity", key: "currentQuanttity" },
  // ];
  const { storeListForDropdown: storeList } = useSelector(
    (state) => state.commonReducer
  );

  // const {  } = useSelector((state) => state.storeReducer);
  // const { exportData } = useSelector((state) => state.orderReducer);
  // const { skuData } = useSelector((state) => state.SkuTimelineReducer);
  //   const handleChangeRowsPerPage = (event) => {
  //     setRowsPerPage(parseInt(event.target.value, 10));
  //     dispatch({
  //       type: Utils.ActionName.GRN,
  //       payload: { take: event.target.value, offset: 1 },
  //     });
  //     // dispatch(getAllGRNList());
  //   };
  // const { products } = useSelector((state) => state.productsReducer);
  // const { storeId, toDate, fromDate, productId } = useSelector(
  //   (state) => state.SkuTimelineReducer
  // );
  const { permissions } = useSelector((state) => state.commonReducer);

  return (
    <>
      <Box direction="row" spacing={2} sx={{ display: "flex", mb: 2 }}>
        <Formik
          // enableReinitialize = {}
          initialValues={initialValues}
          // validationSchema={Schema.SKUTimelineSchema}
          onSubmit={(values, { setSubmitting }) => {
            dispatch({
              type: Utils.ActionName.ORDER,
              payload: { ...values, exportData: [] },
            });

            if (permissions && !values.storeId) {
              setSubmitting(true);
              Utils.showAlert(2, "Please select Store");
              return;
            }
            dispatch(generatReport(values, setSubmitting));
            // dispatch(getOrderExportList(csvDownload, setSubmitting));
            // setInitialValue(values);
            // dispatch(getSKUTimeline(values));
            // if (isEdit?.length) {
            //   dispatch(updateUser(values, setOpen, isEdit[0].id, setIsEdit));
            // } else {
            //
            // }
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form onSubmit={handleSubmit}>
              <Box sx={{ display: "flex" }}>
                <Stack direction="row" spacing={2} style={{ width: 200 }}>
                  <AutoComplete
                    freeSolo={false}
                    // onBlur={handleBlur}
                    values={values.store}
                    onChange={(event, value) => {
                      if (value !== null) {
                        setFieldValue("storeId", value?.id);
                        setFieldValue("storeName", value?.storename);
                        dispatch({
                          type: Utils.ActionName.SKU_TIMELINE,
                          payload: { skuData: [] },
                        });
                      }
                    }}
                    helperText={touched.storeId && errors.storeId}
                    error={Boolean(touched.storeId && errors.storeId)}
                    name={"storeId"}
                    label={"storeId"}
                    touched={touched.storeId}
                    options={storeList || []}
                    getOptionLabel={(option) => option?.storename}
                    // isRequired={true}
                    getOptionSelected={(option, value) => {
                      return `${option?.id}` === `${value?.id}`;
                    }}
                    // defaultValue={() => {
                    //   if (storeList?.length) {
                    //
                    //     const index = storeList?.findIndex(
                    //       (x) => x?.id == values.storeId
                    //     );
                    //     return storeList[index];
                    //   }
                    // }}
                  />
                </Stack>
                <Stack style={{ width: 200 }}>
                  <DatePicker
                    value={values.fromDate}
                    name="fromDate"
                    handleChange={(newVal) => setFieldValue("fromDate", newVal)}
                    label="From Date"
                    error={errors.fromDate}
                  />
                </Stack>
                <Stack direction="row" spacing={2} style={{ width: 200 }}>
                  <DatePicker
                    value={values.toDate}
                    name="toDate"
                    handleChange={(newVal) => setFieldValue("toDate", newVal)}
                    label="To Date"
                    error={errors.toDate}
                  />
                </Stack>

                <Button
                  variant="outlined"
                  spacing={2}
                  sx={{ mr: 2 }}
                  type="submit"
                  // disabled={isSubmitting}
                >
                  Submit
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
      <Divider />
    </>
  );
}
