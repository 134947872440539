import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

export default function ColorButtons({ text, onPress, disabled = false }) {
  return (
    <Button
      variant="outlined"
      color="error"
      onClick={onPress}
      disabled={disabled}
    >
      {text}
    </Button>
  );
}
