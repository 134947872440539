import React, { useState } from "react";
import Button from "@mui/material/Button";
// import { getStoreList } from "../../storeRelated/storeId/action";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
// import Paper from "@mui/material/Paper";
import { CSVLink } from "react-csv";
import DatePicker from "../../../component/date";
import AutoComplete from "../../../component/input/autocomplete";
// import { generatReport, getOrderExportList } from "./action";
import Schema from "../../../schema";
import moment from "moment";
// import { useNavigate, useHistory } from "react-router-dom";
// import Uti from "../../utility/utils";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
// import Authlayout from "../../component/layout/authLayout";
// import { getSKUTimeline, getSKUTimelineAll } from "./action";
// import Input from "../../../component/input";
import { Box } from "@mui/material";

// import Select from "../../../component/select";
import Utils from "../../../utility";
import { generatReport } from "./action";
// import { getProducts } from "../../productRelated/products/action";
// import DetailGRN from "./detailGRN";
// import Modal from "../../component/modal"
// import AddUser from "./addstore";

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
const headers = [
  { label: "Product ID", key: "ProductID" },
  { label: "Store Name", key: "storeName" },
  { label: "Product Name", key: "ProductName" },
  { label: "Date", key: "date" },
  { label: "Type", key: "type" },
  { label: "Billing ID", key: "billingId" },
  { label: "GRN ID", key: "grnId" },
  { label: "Return ID", key: "returnId" },
  { label: "Discard ID", key: "discardID" },
  { label: "RTV ID", key: "RTVId" },
  {
    label: "STIn ID",
    key: "STNID",
  },
  { label: "Previouse Quantity", key: "prevQuantity" },
  { label: "Current Quantity", key: "currentQuanttity" },
  { label: "Net Quantity", key: "netQuantity" },
  { label: "Edited By", key: "editedBy" },
];
export default function EnhancedTable({ csvDownload }) {
  const [csvData, setCsvData] = useState([]);
  const [initialValue, setInitialValue] = useState({});
  const { userlistForDropdown } = useSelector((state) => state.commonReducer);

  //const [initialValue, setInitialValue] = useState({});
  const dispatch = useDispatch();
  const {
    // warehouseListForDropdown,
    storeListForDropdown,
    // brandOwnerListForDropdown,
    productsDropDownList,
    // brandOwnerListForDropdown,
  } = useSelector((state) => state.commonReducer);
  const { skuExportData } = useSelector((state) => state.SkuTimelineReducer);

  // const headers = [
  //   { label: "Product ID", key: "ProductID" },
  //   { label: "Store Name", key: "storeName" },
  //   { label: "Product Name", key: "ProductName" },
  //   { label: "Date", key: "date" },
  //   { label: "status", key: "status" },
  //   { label: "Previouse Quantity", key: "prevQuantity" },
  //   { label: "Current Quantity", key: "currentQuanttity" },
  // ];

  // const {  } = useSelector((state) => state.storeReducer);
  // const { exportData } = useSelector((state) => state.orderReducer);
  // const { skuExportData } = useSelector((state) => state.SkuTimelineReducer);
  //   const handleChangeRowsPerPage = (event) => {
  //     setRowsPerPage(parseInt(event.target.value, 10));
  //     dispatch({
  //       type: Utils.ActionName.GRN,
  //       payload: { take: event.target.value, offset: 1 },
  //     });
  //     // dispatch(getAllGRNList());
  //   };
  // const { products } = useSelector((state) => state.productsReducer);
  // const { storeId, toDate, fromDate, productId } = useSelector(
  //   (state) => state.SkuTimelineReducer
  // );
  const { permissions } = useSelector((state) => state.commonReducer);
  const initialValues = {
    storeId: null,
    storeName: "",
    productName: "",
    toDate: moment(),
    fromDate: moment(),
    productId: null,
  };
  React.useEffect(() => {
    if (skuExportData && skuExportData?.length) {
      const data = skuExportData.map((item) => {
        return {
          storeName: initialValue.storeName,
          ProductID: initialValue.productId,
          ProductName: initialValue.productName,
          date: moment(item.addDate).format("YYYY-MM-DD HH:mm:ss"),

          type: item?.type,
          billingId: item?.OrderId,
          grnId: item?.GrnId,
          returnId: item?.ReturnOrderId,
          discardID: item?.DiscardID,
          RTVId: item?.RtvId,
          STNID: item?.TransferId,
          prevQuantity: item?.OlqQty,
          currentQuanttity: item?.NewQty,
          netQuantity: item?.NewQty - item?.OlqQty,
          editedBy: userlistForDropdown?.filter(
            (x) => x?.id == item?.EditedBy
          )[0].name,
        };
      });
      setCsvData(data);
      
    }
  }, [skuExportData]);
  return (
    <>
      <Box direction="row" spacing={2} sx={{ display: "flex", mb: 2 }}>
        <Formik
          initialValues={initialValues}
          validationSchema={Schema.SKUTimelineSchema}
          onSubmit={(values, { setSubmitting }) => {
            setInitialValue(values);
            dispatch(generatReport(values, csvDownload));
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Box sx={{ display: "flex" }}>
                <Stack direction="row" spacing={2} style={{ width: 200 }}>
                  <AutoComplete
                    freeSolo={false}
                    // onBlur={handleBlur}
                    values={values.storeId}
                    onChange={(event, value) => {
                      console.log(event.target.value, value);
                      if (value !== null) {
                        const index = storeListForDropdown.findIndex(
                          (x) => x.id === value.id
                        );

                        setFieldValue("storeId", value?.id);
                        setFieldValue("storeName", value?.storename);
                        dispatch({
                          type: Utils.ActionName.SKU_TIMELINE,
                          payload: { skuExportData: [] },
                        });
                      }
                    }}
                    helperText={touched.storeId && errors.storeId}
                    error={Boolean(touched.storeId && errors.storeId)}
                    name={"storeId"}
                    label={"storeId"}
                    touched={touched.storeId}
                    options={storeListForDropdown}
                    getOptionLabel={(option) => option?.storename}
                    // isRequired={true}
                    getOptionSelected={(option, value) => {
                      return `${option.id}` === `${value.id}`;
                    }}
                    defaultValue={() => {
                      const index = storeListForDropdown?.findIndex(
                        (x) => x?.id == values.storeId
                      );
                      return storeListForDropdown[index];
                    }}
                  />
                </Stack>
                <Stack style={{ width: 170 }}>
                  <DatePicker
                    value={values.fromDate}
                    name="fromDate"
                    handleChange={(newVal) => setFieldValue("fromDate", newVal)}
                    label="From Date"
                    error={errors.fromDate}
                  />
                </Stack>
                <Stack direction="row" spacing={2} style={{ width: 170 }}>
                  <DatePicker
                    value={values.toDate}
                    name="toDate"
                    handleChange={(newVal) => setFieldValue("toDate", newVal)}
                    label="To Date"
                    error={errors.toDate}
                  />
                </Stack>
                <Stack direction="row" spacing={2} style={{ width: 170 }}>
                  <AutoComplete
                    freeSolo={false}
                    // onBlur={handleBlur}
                    values={values.productId}
                    onChange={(event, value) => {
                      console.log(event.target.value, value);
                      if (value !== null) {
                        const index = productsDropDownList.findIndex(
                          (x) => x.id === value.id
                        );
                        setFieldValue("productId", value?.id);
                        setFieldValue("productName", value?.name);
                        dispatch({
                          type: Utils.ActionName.SKU_TIMELINE,
                          payload: { skuExportData: [] },
                        });
                      }
                    }}
                    helperText={touched.productId && errors.productId}
                    error={Boolean(touched.productId && errors.productId)}
                    name={"productId"}
                    label={"productId"}
                    touched={touched.productId}
                    options={productsDropDownList}
                    getOptionLabel={(option) =>
                      `${option?.barcode} | ${option.name}`
                    }
                    // isRequired={true}
                    getOptionSelected={(option, value) => {
                      return `${option.id}` === `${value.id}`;
                    }}
                    defaultValue={() => {
                      const index = productsDropDownList?.findIndex(
                        (x) => x?.id == values.productId
                      );
                      return productsDropDownList[index];
                    }}
                  />
                </Stack>
              </Box>
              <Box sx={{ display: "flex", mt: 2, justifyContent: "flex-end" }}>
                <Button
                  variant="outlined"
                  spacing={2}
                  sx={{ mr: 2 }}
                  type="submit"
                >
                  Submit
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
        <CSVLink
          data={csvData}
          headers={headers}
          filename={"order"}
          ref={csvDownload}
          target="_blank"
        />
      </Box>
      <Divider />
    </>
  );
}
