import React from "react";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import CheckBox from "../../../component/checkbox";
import DatePicker from "../../../component/date";
import Schema from "../../../schema";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import { addUser, updateUser } from "./action";
import Input from "../../../component/input";
import { Box } from "@mui/material";

import Select from "../../../component/select";
import Utils from "../../../utility";
const AddUser = ({ setOpen, isEdit, setIsEdit }) => {
  const dispatch = useDispatch();

  const initialValues = {
    userType: isEdit?.length ? isEdit[0].userType : "",
    fullName: isEdit?.length ? isEdit[0].name : "",
    username: isEdit?.length ? isEdit[0].userName : "",
    password: isEdit?.length ? isEdit[0].password : "",
    phone: isEdit?.length ? isEdit[0].contactNumber : "",
    email: isEdit?.length ? isEdit[0].contactEmail : "",
    address: isEdit?.length ? isEdit[0].address : "",
    state: isEdit?.length ? isEdit[0].state : "",
    city: isEdit?.length ? isEdit[0].city : "",
    pincode: isEdit?.length ? isEdit[0].pincode : "",
    PAN: isEdit?.length ? isEdit[0].PAN : "",
    aadhar: isEdit?.length ? isEdit[0].aadhar : "",
    officeLocation: isEdit?.length ? isEdit[0].officeLocation : "",
    post: isEdit?.length ? isEdit[0].post : "",
    isActive: isEdit?.length ? isEdit[0].isActive : false,
    leaveDate: isEdit?.length ? isEdit[0].leaveDate : moment(),
    propertyOwner: isEdit?.length ? isEdit[0].propertyOwnerId : "",
    brandOwner: isEdit?.length ? isEdit[0].brandId : "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Schema.addUserSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (isEdit?.length) {
          dispatch(
            updateUser(values, setOpen, isEdit[0].id, setIsEdit, setSubmitting)
          );
        } else {
          dispatch(addUser(values, setOpen,setSubmitting));
        }
      }}
    >
      {({
        values,
        errors,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit}>
          <Box sx={{ display: "flex", mb: 2 }}>
            <Select
              handleChange={handleChange}
              value={values.userType}
              label={"User Type"}
              option={Utils.constants.UserType.map((item) => (
                <MenuItem key={item.text} value={item.value}>
                  {item.text}
                </MenuItem>
              ))}
              name="userType"
              error={errors.userType}
            />
          </Box>
          <Box sx={{ display: "flex", mb: 2 }}>
            <Input
              value={values.fullName}
              name="fullName"
              type="text"
              error={errors.fullName}
              label="Full Name"
              onChange={handleChange}
              handleBlur={handleBlur}
            />
            {isEdit?.length ? (
              <></>
            ) : (
              <Input
                value={values.password}
                name="password"
                type="text"
                error={errors.password}
                label="password"
                onChange={handleChange}
                handleBlur={handleBlur}
              />
            )}
          </Box>
          <Box sx={{ display: "flex", mb: 2 }}>
            <Input
              value={values.username}
              name="username"
              type="text"
              error={errors.username}
              label="Username"
              onChange={handleChange}
              handleBlur={handleBlur}
            />
          </Box>
          <Box sx={{ display: "flex", mb: 2 }}>
            <Input
              value={values.phone}
              name="phone"
              type="text"
              error={errors.phone}
              label="Phone"
              onChange={handleChange}
              handleBlur={handleBlur}
            />

            <Input
              value={values.email}
              name="email"
              type="text"
              error={errors.email}
              label="Email"
              onChange={handleChange}
              handleBlur={handleBlur}
            />
          </Box>
          <Box sx={{ display: "flex", mb: 2 }}>
            <Select
              handleChange={handleChange}
              value={values.state}
              label={"State"}
              option={Utils.constants.states.map((item) => (
                <MenuItem key={item.name} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
              name="state"
              error={errors.state}
            />
            {values.state && (
              <Select
                handleChange={handleChange}
                value={values.city}
                label={"City"}
                option={Utils.constants.cities[values?.state]?.map((item) => (
                  <MenuItem key={item.city} value={item.city}>
                    {item.city}
                  </MenuItem>
                ))}
                name="city"
                error={errors.city}
              />
            )}
          </Box>
          <Box sx={{ display: "flex", mb: 2 }}>
            <Input
              value={values.address}
              name="address"
              type="text"
              error={errors.address}
              label="Address"
              onChange={handleChange}
              handleBlur={handleBlur}
            />
            <Input
              value={values.pincode}
              name="pincode"
              type="number"
              error={errors.pincode}
              label="Pincode"
              onChange={handleChange}
              handleBlur={handleBlur}
            />
          </Box>

          <Box sx={{ display: "flex", mb: 2 }}>
            <Input
              value={values.PAN}
              name="PAN"
              type="text"
              error={errors.PAN}
              label="PAN"
              onChange={handleChange}
              handleBlur={handleBlur}
            />

            <Input
              value={values.aadhar}
              name="aadhar"
              type="number"
              error={errors.aadhar}
              label="Aadhar"
              onChange={handleChange}
              handleBlur={handleBlur}
            />
          </Box>
          <Box sx={{ display: "flex", mb: 2 }}>
            <Input
              value={values.officeLocation}
              name="officeLocation"
              type="text"
              error={errors.officeLocation}
              label="Office Location"
              onChange={handleChange}
              handleBlur={handleBlur}
            />
            <Input
              value={values.post}
              name="post"
              type="text"
              error={errors.post}
              label="Post"
              onChange={handleChange}
              handleBlur={handleBlur}
            />
          </Box>
          <Box sx={{ display: "flex", mb: 2 }}>
            <DatePicker
              value={values.leaveDate}
              name="leaveDate"
              handleChange={(newVal) => setFieldValue("leaveDate", newVal)}
              label="Leave Date"
              error={errors.leaveDate}
            />
          </Box>
          <CheckBox
            value={Boolean(values.isActive)}
            name="isActive"
            label="Is Active"
            handleChange={handleChange}
          />

          <Button variant="outlined" type="submit" disabled={isSubmitting}>
            {isEdit?.length ? "update User" : "Add User"}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default AddUser;
