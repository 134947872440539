import moment from "moment";
import React from "react";
export default function Index({
  componentRef,
  grandTotalAddedGRN,
  printGrnData,
}) {
  return (
    <div
      ref={componentRef}
      style={{
        margin: "10px auto",
        width: "180.874016px",
        // border: "2px solid black",
      }}
    >
      <img
        src="https://thenewshop.in/header-logo.png"
        alt="The new shop"
        style={{ width: "170px" }}
      />
      <div style={{ margin: "0px 15px" }}>
        <div style={{ fontSize: 14, fontWeight: 500 }}>
          <b>GRN Id:</b> {printGrnData?.grnId || printGrnData?.id}
        </div>
        {grandTotalAddedGRN ? (
          <div style={{ fontSize: 14 }}>
            <b>Total Amount:</b>
            {grandTotalAddedGRN}
          </div>
        ) : (
          <React.Fragment></React.Fragment>
        )}

        <div style={{ fontSize: 14, fontWeight: 500 }}>
          <b>Store :</b>
          {(printGrnData?.grnProducts &&
            printGrnData?.grnProducts[0]?.grn?.store?.storename) ||
            printGrnData?.store?.storename}
        </div>
        <div style={{ fontSize: 14, fontWeight: 500 }}>
          <b> GRN By :</b>{" "}
          {(printGrnData?.grnProducts &&
            printGrnData?.grnProducts[0]?.grn?.createdBy?.name) ||
            printGrnData.createdBy?.name}
        </div>
        <div style={{ fontSize: 14, fontWeight: 500 }}>
          <b> Date :</b>
          {(printGrnData?.grnProducts &&
            moment(printGrnData?.grnProducts[0]?.grn?.createdAt).format(
              "DD-MM-YYYY  HH:mm:ss"
            )) ||
            moment(printGrnData?.createdAt).format("DD-MM-YYYY  HH:mm:ss")}
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: 14,
              marginTop: 15,
            }}
          >
            <div>
              <b>Product</b>
            </div>
            <div>
              <b>Quantity</b>
            </div>
          </div>

          {printGrnData?.grnProducts?.length &&
            printGrnData?.grnProducts?.map((item, i) => (
              <div
                key={i}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontSize: 12,
                  // textAlign:"center",
                }}
              >
                <div style={{ maxWidth: "126px" }}>{item?.product?.name}</div>
                <div>{item?.recievedQuantity}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
