import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
export default function SelectOtherProps({
  handleChange,
  value,
  label,
  option,
  name,
  error,
  minWidth,
  disabled,
}) {
  return (
    <FormControl
      error={error}
      size="small"
      sx={{
        width: "100%",
        pr: minWidth ? 0.5 : 2,
        maxWidth: "100%",
        minWidth: minWidth ? minWidth : "auto",
      }}
    >
      <InputLabel id="demo-simple-select-disabled-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-disabled-label"
        id="demo-simple-select-disabled"
        value={value}
        disabled={disabled}
        label={label}
        onChange={handleChange}
        name={name}
      >
        {/* <MenuItem value="">
            <em>None</em>
          </MenuItem> */}
        {option}
        {/* {option.map()}
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
}
