import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
// import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import StoreTable from "./tables/storeTable";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import Constant from "../../utility/constant";
// import { getStoreList } from "../storeRelated/store/action";
// import Stack from "@mui/material/Stack";
// import Paper from "@mui/material/Paper";
// import { CSVLink } from "react-csv";
import DatePicker from "../../component/date";
import AutoComplete from "../../component/input/autocomplete";
// import SalesData from "../../component/discordStocks";
// import Schema from "../../schema";
import moment from "moment";
// import { useNavigate, useHistory } from "react-router-dom";
// import Uti from "../../utility/utils";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
// import Authlayout from "../../component/layout/authLayout";
import {
  getDashboardDetail,
  // getDashboardData,
  getDashboardEmbedUrl,
  getDashboardTrend,
} from "./action";
// import Input from "../../component/input";
import { Box } from "@mui/material";
// import Select from "../../component/select";
// import Utils from "../../utility";
import {
  getUserNameFromLs,
  getUserTypeFromLs,
} from "../../utility/commonFunction";
// import { getProducts } from "../../productRelated/products";
// import DetailGRN from "./detailGRN";
import CategoryTable from "./tables/category";
import TopBrands from "./tables/topBrand";
import TopProducts from "./tables/topProducts";
import BottomProducts from "./tables/bottomProducts";
const Dashboard = () => {
  const [chartType, setChartType] = useState("daily");
  const [storeId, setStoreId] = useState("*");
  const [monthlyData, setMothlyData] = useState([]);
  const { dashboard, url, dashboardChartData, dashboardDetailData } =
    useSelector((state) => state.dashboardReducer);

  // const dashboardDetailData = [
  //   {
  //     Cards: [
  //       {
  //         total_sale: "329145.28",
  //         number_of_orders: "2725",
  //         aov: "120.7872587155963303",
  //       },
  //     ],
  //   },
  //   {
  //     Stores: [
  //       {
  //         store_name: "Jasola IOCL",
  //         total_sale: "257395.13",
  //         number_of_orders: "2341",
  //       },
  //       {
  //         store_name: "IOCL Mayur Vihar 3",
  //         total_sale: "51327.75",
  //         number_of_orders: "150",
  //       },
  //       {
  //         store_name: "IOCL Mira Road Mumbai",
  //         total_sale: "20422.40",
  //         number_of_orders: "234",
  //       },
  //     ],
  //   },
  //   {
  //     Brands: [
  //       {
  //         brand_name: "Red bull",
  //         total_sale: "274563.50",
  //       },
  //       {
  //         brand_name: "Lays",
  //         total_sale: "142800.62",
  //       },
  //       {
  //         brand_name: "Maggi",
  //         total_sale: "108467.17",
  //       },
  //       {
  //         brand_name: "Sprite",
  //         total_sale: "50948.41",
  //       },
  //       {
  //         brand_name: "Coca Cola",
  //         total_sale: "48171.40",
  //       },
  //       {
  //         brand_name: "Monster",
  //         total_sale: "39334.41",
  //       },
  //       {
  //         brand_name: "Cadbury",
  //         total_sale: "34205.30",
  //       },
  //       {
  //         brand_name: "Hell",
  //         total_sale: "28208.97",
  //       },
  //       {
  //         brand_name: "Kwality Walls",
  //         total_sale: "26237.60",
  //       },
  //       {
  //         brand_name: "Takeout Cafe",
  //         total_sale: "23609.73",
  //       },
  //       {
  //         brand_name: "Bingo",
  //         total_sale: "22263.27",
  //       },
  //       {
  //         brand_name: "Amul",
  //         total_sale: "19273.10",
  //       },
  //       {
  //         brand_name: "Bisleri",
  //         total_sale: "17257.61",
  //       },
  //       {
  //         brand_name: "Real",
  //         total_sale: "13043.96",
  //       },
  //       {
  //         brand_name: "Thums Up",
  //         total_sale: "10975.18",
  //       },
  //       {
  //         brand_name: "Bazana",
  //         total_sale: "9598.23",
  //       },
  //       {
  //         brand_name: "Sting",
  //         total_sale: "9576.33",
  //       },
  //       {
  //         brand_name: "MOGU MOGU",
  //         total_sale: "9515.61",
  //       },
  //       {
  //         brand_name: "Nestle",
  //         total_sale: "9288.36",
  //       },
  //       {
  //         brand_name: "Gianis",
  //         total_sale: "6726.73",
  //       },
  //       {
  //         brand_name: "Haldiram",
  //         total_sale: "6206.25",
  //       },
  //       {
  //         brand_name: "Britannia",
  //         total_sale: "5928.20",
  //       },
  //       {
  //         brand_name: "Kit Kat",
  //         total_sale: "5125.38",
  //       },
  //       {
  //         brand_name: "TNS",
  //         total_sale: "5085.76",
  //       },
  //       {
  //         brand_name: "Parle",
  //         total_sale: "4932.85",
  //       },
  //       {
  //         brand_name: "Fanta",
  //         total_sale: "4735.69",
  //       },
  //       {
  //         brand_name: "Limca",
  //         total_sale: "4687.39",
  //       },
  //       {
  //         brand_name: "Doritos",
  //         total_sale: "4638.99",
  //       },
  //       {
  //         brand_name: "Instant Ice",
  //         total_sale: "4042.09",
  //       },
  //       {
  //         brand_name: "Minute Maid",
  //         total_sale: "3988.61",
  //       },
  //       {
  //         brand_name: "7 Up",
  //         total_sale: "3955.41",
  //       },
  //       {
  //         brand_name: "WANG RAMEN",
  //         total_sale: "3766.46",
  //       },
  //       {
  //         brand_name: "Kinder Joy",
  //         total_sale: "3664.56",
  //       },
  //       {
  //         brand_name: "Kurkure",
  //         total_sale: "3549.04",
  //       },
  //       {
  //         brand_name: "Yippee",
  //         total_sale: "3349.00",
  //       },
  //       {
  //         brand_name: "Frooti",
  //         total_sale: "3249.07",
  //       },
  //       {
  //         brand_name: "Hersheys",
  //         total_sale: "3216.59",
  //       },
  //       {
  //         brand_name: "Pepsi",
  //         total_sale: "3103.72",
  //       },
  //       {
  //         brand_name: "Frutjus",
  //         total_sale: "3035.76",
  //       },
  //       {
  //         brand_name: "Snickers",
  //         total_sale: "2875.54",
  //       },
  //       {
  //         brand_name: "Jacker",
  //         total_sale: "2816.66",
  //       },
  //       {
  //         brand_name: "Maaza",
  //         total_sale: "2750.63",
  //       },
  //       {
  //         brand_name: "Lets Try",
  //         total_sale: "2661.82",
  //       },
  //       {
  //         brand_name: "Uncle Chipps",
  //         total_sale: "2563.34",
  //       },
  //       {
  //         brand_name: "Shunya Go ",
  //         total_sale: "2517.79",
  //       },
  //       {
  //         brand_name: "Nescafe",
  //         total_sale: "2370.58",
  //       },
  //       {
  //         brand_name: "Love at First Bite",
  //         total_sale: "2237.27",
  //       },
  //       {
  //         brand_name: "Pepitos",
  //         total_sale: "2004.83",
  //       },
  //       {
  //         brand_name: "Mr Makhana",
  //         total_sale: "1919.46",
  //       },
  //       {
  //         brand_name: "Meatzza",
  //         total_sale: "1767.89",
  //       },
  //     ],
  //   },
  //   {
  //     Categories: [
  //       {
  //         category: "Energy and Sports Drinks",
  //         total_sale: "344385.26",
  //       },
  //       {
  //         category: "Chips",
  //         total_sale: "179545.93",
  //       },
  //       {
  //         category: "Soft Drinks",
  //         total_sale: "145414.79",
  //       },
  //       {
  //         category: "Noodles and Pasta",
  //         total_sale: "115826.88",
  //       },
  //       {
  //         category: "Chocolates",
  //         total_sale: "55954.28",
  //       },
  //       {
  //         category: "Ice Creams",
  //         total_sale: "32252.45",
  //       },
  //       {
  //         category: "Fruit Juices and Drinks",
  //         total_sale: "30409.43",
  //       },
  //       {
  //         category: "Soda and Water",
  //         total_sale: "20765.61",
  //       },
  //       {
  //         category: "Burgers and Sandwiches",
  //         total_sale: "16396.90",
  //       },
  //       {
  //         category: "Dairy and Milk Shakes",
  //         total_sale: "12830.05",
  //       },
  //       {
  //         category: "Nuts and Seeds",
  //         total_sale: "11026.80",
  //       },
  //       {
  //         category: "Milk",
  //         total_sale: "9584.71",
  //       },
  //       {
  //         category: "Biscuits and Cookies",
  //         total_sale: "9207.74",
  //       },
  //       {
  //         category: "Burger and Sandwich Patties",
  //         total_sale: "8980.72",
  //       },
  //       {
  //         category: "Namkeen",
  //         total_sale: "6305.55",
  //       },
  //       {
  //         category: "Ice Cubes",
  //         total_sale: "5171.86",
  //       },
  //       {
  //         category: "Cakes",
  //         total_sale: "4401.79",
  //       },
  //       {
  //         category: "Popcorn",
  //         total_sale: "3594.35",
  //       },
  //       {
  //         category: "Snacks",
  //         total_sale: "3093.56",
  //       },
  //       {
  //         category: "glass",
  //         total_sale: "2859.60",
  //       },
  //       {
  //         category: "Sexual Wellness",
  //         total_sale: "2272.26",
  //       },
  //       {
  //         category: "Cafe - Pastries",
  //         total_sale: "2237.27",
  //       },
  //       {
  //         category: "Paan Masala",
  //         total_sale: "2178.59",
  //       },
  //       {
  //         category: "Coffee",
  //         total_sale: "1551.05",
  //       },
  //       {
  //         category: "Candies and Toffies",
  //         total_sale: "1442.12",
  //       },
  //       {
  //         category: "Kitchen",
  //         total_sale: "1360.12",
  //       },
  //       {
  //         category: "Cafe- Kulcha",
  //         total_sale: "1032.38",
  //       },
  //       {
  //         category: "Rice and Grains",
  //         total_sale: "968.19",
  //       },
  //       {
  //         category: "Wafers and Cakes",
  //         total_sale: "789.84",
  //       },
  //       {
  //         category: "Paan",
  //         total_sale: "752.73",
  //       },
  //       {
  //         category: "Supplements",
  //         total_sale: "671.20",
  //       },
  //       {
  //         category: "Beverages",
  //         total_sale: "508.95",
  //       },
  //       {
  //         category: "Ice Creams and Desserts",
  //         total_sale: "464.27",
  //       },
  //       {
  //         category: "Hair Care",
  //         total_sale: "441.06",
  //       },
  //       {
  //         category: "Rolling Paper",
  //         total_sale: "433.62",
  //       },
  //       {
  //         category: "Desserts",
  //         total_sale: "423.75",
  //       },
  //       {
  //         category: "Hygiene",
  //         total_sale: "406.68",
  //       },
  //       {
  //         category: "Home Cleaning",
  //         total_sale: "386.70",
  //       },
  //       {
  //         category: "Salt, Sugar and Jaggery",
  //         total_sale: "362.44",
  //       },
  //       {
  //         category: "Oils and Ghee",
  //         total_sale: "326.92",
  //       },
  //       {
  //         category: "Food",
  //         total_sale: "324.75",
  //       },
  //       {
  //         category: "Face Care",
  //         total_sale: "298.43",
  //       },
  //       {
  //         category: "Laundry",
  //         total_sale: "288.14",
  //       },
  //       {
  //         category: "Cafe - Ice cream",
  //         total_sale: "275.42",
  //       },
  //       {
  //         category: "Nut Butters and Spreads",
  //         total_sale: "262.50",
  //       },
  //       {
  //         category: "Cigarettes",
  //         total_sale: "256.19",
  //       },
  //       {
  //         category: "Gums and Mints",
  //         total_sale: "228.81",
  //       },
  //       {
  //         category: "Raw Material",
  //         total_sale: "160.00",
  //       },
  //       {
  //         category: "Churan and Digestives",
  //         total_sale: "133.04",
  //       },
  //       {
  //         category: "Momos, Puffs and Pockets",
  //         total_sale: "120.54",
  //       },
  //     ],
  //   },
  //   {
  //     TopProducts: [
  //       {
  //         name: "Energy Drink",
  //         category_name: "Energy and Sports Drinks",
  //         brand_name: "Red bull",
  //         total_sale: "274563.50",
  //       },
  //       {
  //         name: "2 Minute Noodles",
  //         category_name: "Noodles and Pasta",
  //         brand_name: "Maggi",
  //         total_sale: "108020.74",
  //       },
  //       {
  //         name: "Classic Salted",
  //         category_name: "Chips",
  //         brand_name: "Lays",
  //         total_sale: "75484.82",
  //       },
  //       {
  //         name: "Can",
  //         category_name: "Soft Drinks",
  //         brand_name: "Sprite",
  //         total_sale: "39994.56",
  //       },
  //       {
  //         name: "Energy Drink",
  //         category_name: "Energy and Sports Drinks",
  //         brand_name: "Monster",
  //         total_sale: "39020.13",
  //       },
  //       {
  //         name: "Can",
  //         category_name: "Soft Drinks",
  //         brand_name: "Coca Cola",
  //         total_sale: "37187.43",
  //       },
  //       {
  //         name: "Spanish Tomato Tango",
  //         category_name: "Chips",
  //         brand_name: "Lays",
  //         total_sale: "35344.51",
  //       },
  //       {
  //         name: "Energy Drink",
  //         category_name: "Energy and Sports Drinks",
  //         brand_name: "Hell",
  //         total_sale: "28208.97",
  //       },
  //       {
  //         name: "Magic Masala",
  //         category_name: "Chips",
  //         brand_name: "Lays",
  //         total_sale: "27292.90",
  //       },
  //       {
  //         name: "Water",
  //         category_name: "Soda and Water",
  //         brand_name: "Bisleri",
  //         total_sale: "17176.07",
  //       },
  //       {
  //         name: "Fuse",
  //         category_name: "Chocolates",
  //         brand_name: "Cadbury",
  //         total_sale: "12236.53",
  //       },
  //       {
  //         name: "CreamOnion",
  //         category_name: "Chips",
  //         brand_name: "Bingo",
  //         total_sale: "12107.26",
  //       },
  //       {
  //         name: "Cassatta Wonder Wheel",
  //         category_name: "Ice Creams",
  //         brand_name: "Kwality Walls",
  //         total_sale: "11864.40",
  //       },
  //       {
  //         name: "Pet Bottle",
  //         category_name: "Soft Drinks",
  //         brand_name: "Sprite",
  //         total_sale: "10953.85",
  //       },
  //       {
  //         name: "Energy Drink",
  //         category_name: "Soft Drinks",
  //         brand_name: "Sting",
  //         total_sale: "9576.33",
  //       },
  //       {
  //         name: "Roasted Salted Almonds",
  //         category_name: "Nuts and Seeds",
  //         brand_name: "Bazana",
  //         total_sale: "8107.16",
  //       },
  //       {
  //         name: "Tomato Potato Chips",
  //         category_name: "Chips",
  //         brand_name: "Bingo",
  //         total_sale: "7966.44",
  //       },
  //       {
  //         name: "Kitkat",
  //         category_name: "Chocolates",
  //         brand_name: "Nestle",
  //         total_sale: "7669.45",
  //       },
  //       {
  //         name: "Cold Sandwich",
  //         category_name: "Burger and Sandwich Patties",
  //         brand_name: "Takeout Cafe",
  //         total_sale: "7212.83",
  //       },
  //       {
  //         name: "Pet Bottle",
  //         category_name: "Soft Drinks",
  //         brand_name: "Coca Cola",
  //         total_sale: "7039.37",
  //       },
  //       {
  //         name: "Paneer Burger",
  //         category_name: "Burgers and Sandwiches",
  //         brand_name: "Takeout Cafe",
  //         total_sale: "6435.74",
  //       },
  //       {
  //         name: "Pet Bottle",
  //         category_name: "Soft Drinks",
  //         brand_name: "Thums Up",
  //         total_sale: "6235.14",
  //       },
  //       {
  //         name: "Magnum Almond ",
  //         category_name: "Ice Creams",
  //         brand_name: "Kwality Walls",
  //         total_sale: "6040.68",
  //       },
  //       {
  //         name: "Dairy Milk",
  //         category_name: "Chocolates",
  //         brand_name: "Cadbury",
  //         total_sale: "5277.57",
  //       },
  //       {
  //         name: "Dairy Milk Chocolate",
  //         category_name: "Chocolates",
  //         brand_name: "Cadbury",
  //         total_sale: "5219.60",
  //       },
  //       {
  //         name: "Kool Badam",
  //         category_name: "Dairy and Milk Shakes",
  //         brand_name: "Amul",
  //         total_sale: "5129.91",
  //       },
  //       {
  //         name: "4 Finger",
  //         category_name: "Chocolates",
  //         brand_name: "Kit Kat",
  //         total_sale: "5125.38",
  //       },
  //       {
  //         name: "Five Star",
  //         category_name: "Chocolates",
  //         brand_name: "Cadbury",
  //         total_sale: "5063.22",
  //       },
  //       {
  //         name: " Kool Kesar",
  //         category_name: "Milk",
  //         brand_name: "Amul",
  //         total_sale: "4828.92",
  //       },
  //       {
  //         name: "thumbs up can",
  //         category_name: "Soft Drinks",
  //         brand_name: "Thums Up",
  //         total_sale: "4740.04",
  //       },
  //       {
  //         name: "Chicken Hot Dog",
  //         category_name: "Burgers and Sandwiches",
  //         brand_name: "Takeout Cafe",
  //         total_sale: "4419.02",
  //       },
  //       {
  //         name: "Hide Seek Chocolate chip cookies",
  //         category_name: "Biscuits and Cookies",
  //         brand_name: "Parle",
  //         total_sale: "4267.56",
  //       },
  //       {
  //         name: "Paneer Hot Dog",
  //         category_name: "Burgers and Sandwiches",
  //         brand_name: "Takeout Cafe",
  //         total_sale: "4256.41",
  //       },
  //       {
  //         name: "Cubes",
  //         category_name: "Ice Cubes",
  //         brand_name: "Instant Ice",
  //         total_sale: "4042.09",
  //       },
  //       {
  //         name: "Pulpy Orange",
  //         category_name: "Fruit Juices and Drinks",
  //         brand_name: "Minute Maid",
  //         total_sale: "3988.61",
  //       },
  //       {
  //         name: "American Style Cream and Onion",
  //         category_name: "Chips",
  //         brand_name: "Lays",
  //         total_sale: "3958.71",
  //       },
  //       {
  //         name: "Nimbooz",
  //         category_name: "Soft Drinks",
  //         brand_name: "7 Up",
  //         total_sale: "3955.41",
  //       },
  //       {
  //         name: "Diet Coke Can",
  //         category_name: "Soft Drinks",
  //         brand_name: "Coca Cola",
  //         total_sale: "3917.26",
  //       },
  //       {
  //         name: "Strawberry Juice",
  //         category_name: "Soft Drinks",
  //         brand_name: "MOGU MOGU",
  //         total_sale: "3743.21",
  //       },
  //       {
  //         name: "Tender Coconut Water",
  //         category_name: "Fruit Juices and Drinks",
  //         brand_name: "Real",
  //         total_sale: "3714.32",
  //       },
  //       {
  //         name: "Lactose Free Milk",
  //         category_name: "Milk",
  //         brand_name: "Amul",
  //         total_sale: "3645.22",
  //       },
  //       {
  //         name: "Lychee Juice",
  //         category_name: "Soft Drinks",
  //         brand_name: "MOGU MOGU",
  //         total_sale: "3635.94",
  //       },
  //       {
  //         name: "Magic Masala",
  //         category_name: "Noodles and Pasta",
  //         brand_name: "Yippee",
  //         total_sale: "3349.00",
  //       },
  //       {
  //         name: "Can",
  //         category_name: "Soft Drinks",
  //         brand_name: "Fanta",
  //         total_sale: "3337.94",
  //       },
  //       {
  //         name: "Can",
  //         category_name: "Soft Drinks",
  //         brand_name: "Limca",
  //         total_sale: "3289.88",
  //       },
  //       {
  //         name: "Magnum Chocolate Truffle",
  //         category_name: "Ice Creams",
  //         brand_name: "Kwality Walls",
  //         total_sale: "3104.24",
  //       },
  //       {
  //         name: "pomegranate juice",
  //         category_name: "Fruit Juices and Drinks",
  //         brand_name: "Real",
  //         total_sale: "3068.48",
  //       },
  //       {
  //         name: "Masala Munch",
  //         category_name: "Chips",
  //         brand_name: "Kurkure",
  //         total_sale: "3047.55",
  //       },
  //       {
  //         name: "Kulfi",
  //         category_name: "Ice Creams",
  //         brand_name: "Gianis",
  //         total_sale: "2949.15",
  //       },
  //       {
  //         name: "Lassi",
  //         category_name: "Dairy and Milk Shakes",
  //         brand_name: "Amul",
  //         total_sale: "2913.71",
  //       },
  //       {
  //         name: "Spicy noodle",
  //         category_name: "Noodles and Pasta",
  //         brand_name: "WANG RAMEN",
  //         total_sale: "2899.64",
  //       },
  //       {
  //         name: "Snicker",
  //         category_name: "Chocolates",
  //         brand_name: "Snickers",
  //         total_sale: "2875.54",
  //       },
  //       {
  //         name: "Mango",
  //         category_name: "Fruit Juices and Drinks",
  //         brand_name: "Frooti",
  //         total_sale: "2740.12",
  //       },
  //       {
  //         name: "Uncle chips Spicy Treat",
  //         category_name: "Chips",
  //         brand_name: "Uncle Chipps",
  //         total_sale: "2563.34",
  //       },
  //       {
  //         name: "Gobbles Fruity Fun",
  //         category_name: "Cakes",
  //         brand_name: "Britannia",
  //         total_sale: "2470.44",
  //       },
  //       {
  //         name: "Chocolate - Rich & Creamy",
  //         category_name: "Chocolates",
  //         brand_name: "Kinder Joy",
  //         total_sale: "2440.64",
  //       },
  //       {
  //         name: "Cup",
  //         category_name: "glass",
  //         brand_name: "TNS",
  //         total_sale: "2429.60",
  //       },
  //       {
  //         name: "Exotic Dark Chocolate",
  //         category_name: "Chocolates",
  //         brand_name: "Hersheys",
  //         total_sale: "2386.57",
  //       },
  //       {
  //         name: "Hazelnut",
  //         category_name: "Fruit Juices and Drinks",
  //         brand_name: "Nescafe",
  //         total_sale: "2370.58",
  //       },
  //       {
  //         name: "Pet Bottle",
  //         category_name: "Fruit Juices and Drinks",
  //         brand_name: "Maaza",
  //         total_sale: "2357.14",
  //       },
  //       {
  //         name: "Mixed Fruit",
  //         category_name: "Fruit Juices and Drinks",
  //         brand_name: "Real",
  //         total_sale: "2340.38",
  //       },
  //       {
  //         name: "Pet Bottle",
  //         category_name: "Soft Drinks",
  //         brand_name: "Pepsi",
  //         total_sale: "2253.72",
  //       },
  //       {
  //         name: "Nacho Cheese",
  //         category_name: "Chips",
  //         brand_name: "Doritos",
  //         total_sale: "2251.35",
  //       },
  //       {
  //         name: "Nutties",
  //         category_name: "Chocolates",
  //         brand_name: "Cadbury",
  //         total_sale: "2207.15",
  //       },
  //       {
  //         name: "Mad Angles Pizza",
  //         category_name: "Paan Masala",
  //         brand_name: "Bingo",
  //         total_sale: "2178.59",
  //       },
  //       {
  //         name: "Cranberry juice",
  //         category_name: "Fruit Juices and Drinks",
  //         brand_name: "Real",
  //         total_sale: "2142.43",
  //       },
  //       {
  //         name: "Gobbles Choce Chill",
  //         category_name: "Cakes",
  //         brand_name: "Britannia",
  //         total_sale: "1921.35",
  //       },
  //       {
  //         name: "Black Forest Pastry",
  //         category_name: "Cafe - Pastries",
  //         brand_name: "Love at First Bite",
  //         total_sale: "1898.27",
  //       },
  //       {
  //         name: "Pomegranate ",
  //         category_name: "Fruit Juices and Drinks",
  //         brand_name: "Frutjus",
  //         total_sale: "1875.01",
  //       },
  //       {
  //         name: "Chicken Burger",
  //         category_name: "Burger and Sandwich Patties",
  //         brand_name: "Meatzza",
  //         total_sale: "1767.89",
  //       },
  //       {
  //         name: "Mad Angles Achaari Masti",
  //         category_name: "Chips",
  //         brand_name: "Bingo",
  //         total_sale: "1642.35",
  //       },
  //       {
  //         name: "Chilled Latte",
  //         category_name: "Coffee",
  //         brand_name: "Nestle",
  //         total_sale: "1551.05",
  //       },
  //       {
  //         name: "Masti Spiced Buttermilk",
  //         category_name: "Dairy and Milk Shakes",
  //         brand_name: "Amul",
  //         total_sale: "1465.64",
  //       },
  //       {
  //         name: "Pet Bottle",
  //         category_name: "Soft Drinks",
  //         brand_name: "Fanta",
  //         total_sale: "1397.75",
  //       },
  //       {
  //         name: "Pet Bottle",
  //         category_name: "Soft Drinks",
  //         brand_name: "Limca",
  //         total_sale: "1397.51",
  //       },
  //       {
  //         name: "Shahi Kulfi",
  //         category_name: "Ice Creams",
  //         brand_name: "Kwality Walls",
  //         total_sale: "1386.49",
  //       },
  //       {
  //         name: "Plastic",
  //         category_name: "Kitchen",
  //         brand_name: "Cups",
  //         total_sale: "1357.03",
  //       },
  //       {
  //         name: "Oreo Vanilla",
  //         category_name: "Biscuits and Cookies",
  //         brand_name: "Cadbury",
  //         total_sale: "1349.53",
  //       },
  //       {
  //         name: "Litchi Fruit juice 180 ml",
  //         category_name: "Fruit Juices and Drinks",
  //         brand_name: "Real",
  //         total_sale: "1293.54",
  //       },
  //       {
  //         name: "Salted Peanut",
  //         category_name: "Namkeen",
  //         brand_name: "Haldiram",
  //         total_sale: "1268.25",
  //       },
  //       {
  //         name: "Sweet Chilli",
  //         category_name: "Chips",
  //         brand_name: "Doritos",
  //         total_sale: "1243.15",
  //       },
  //       {
  //         name: "Feast Chocolate",
  //         category_name: "Ice Creams",
  //         brand_name: "Kwality Walls",
  //         total_sale: "1186.46",
  //       },
  //       {
  //         name: "Sweet Chilli ",
  //         category_name: "Chips",
  //         brand_name: "Doritos",
  //         total_sale: "1117.70",
  //       },
  //       {
  //         name: "Mad Angles Tomato Madness",
  //         category_name: "Chips",
  //         brand_name: "Bingo",
  //         total_sale: "1097.18",
  //       },
  //       {
  //         name: "Orange Juice",
  //         category_name: "Soft Drinks",
  //         brand_name: "MOGU MOGU",
  //         total_sale: "1086.28",
  //       },
  //       {
  //         name: "Paneer Kulcha",
  //         category_name: "Cafe- Kulcha",
  //         brand_name: "TNS",
  //         total_sale: "1032.38",
  //       },
  //       {
  //         name: "All in One",
  //         category_name: "Namkeen",
  //         brand_name: "Haldiram",
  //         total_sale: "1026.21",
  //       },
  //       {
  //         name: "Strawberry Shake",
  //         category_name: "Dairy and Milk Shakes",
  //         brand_name: "HARVEYS",
  //         total_sale: "1023.64",
  //       },
  //       {
  //         name: "Classic Cola Fizz 300 ml",
  //         category_name: "Soft Drinks",
  //         brand_name: "Shunya Go ",
  //         total_sale: "1017.83",
  //       },
  //       {
  //         name: "Cappuccino",
  //         category_name: "Energy and Sports Drinks",
  //         brand_name: "Beast",
  //         total_sale: "1008.76",
  //       },
  //       {
  //         name: "Cubes",
  //         category_name: "Ice Cubes",
  //         brand_name: "Icelings",
  //         total_sale: "952.38",
  //       },
  //       {
  //         name: "Potato Crisps Original",
  //         category_name: "Chips",
  //         brand_name: "Jacker",
  //         total_sale: "911.92",
  //       },
  //       {
  //         name: "Lime and Lemon Fizz Can 300 ml",
  //         category_name: "Soft Drinks",
  //         brand_name: "Shunya Go ",
  //         total_sale: "910.69",
  //       },
  //       {
  //         name: "Exotic Flavoured Condoms",
  //         category_name: "Sexual Wellness",
  //         brand_name: "Manforce",
  //         total_sale: "900.00",
  //       },
  //       {
  //         name: "Fizz",
  //         category_name: "Fruit Juices and Drinks",
  //         brand_name: "Appy",
  //         total_sale: "875.03",
  //       },
  //       {
  //         name: "Potato Crisps Barbecue",
  //         category_name: "Chips",
  //         brand_name: "Jacker",
  //         total_sale: "866.64",
  //       },
  //       {
  //         name: "Orange Juice",
  //         category_name: "Fruit Juices and Drinks",
  //         brand_name: "Real",
  //         total_sale: "857.19",
  //       },
  //       {
  //         name: "For Boys With Surprise",
  //         category_name: "Chocolates",
  //         brand_name: "Kinder Joy",
  //         total_sale: "850.16",
  //       },
  //       {
  //         name: "Can",
  //         category_name: "Soft Drinks",
  //         brand_name: "Pepsi",
  //         total_sale: "850.00",
  //       },
  //       {
  //         name: "Sev Bhujia",
  //         category_name: "Snacks",
  //         brand_name: "Lets Try",
  //         total_sale: "797.18",
  //       },
  //       {
  //         name: "Strawberry Non Alcoholic Beer",
  //         category_name: "Energy and Sports Drinks",
  //         brand_name: "Coolberg",
  //         total_sale: "783.06",
  //       },
  //       {
  //         name: "Biryani Basmati Rice",
  //         category_name: "Rice and Grains",
  //         brand_name: "Daawat",
  //         total_sale: "768.19",
  //       },
  //       {
  //         name: "Bongchie Lighter",
  //         category_name: "Paan",
  //         brand_name: "Bongchie",
  //         total_sale: "752.73",
  //       },
  //       {
  //         name: "Gup Shup Peanuts",
  //         category_name: "Namkeen",
  //         brand_name: "Haldiram",
  //         total_sale: "735.82",
  //       },
  //       {
  //         name: "Dairy Milk Fruit and Nut",
  //         category_name: "Chocolates",
  //         brand_name: "Cadbury",
  //         total_sale: "728.42",
  //       },
  //       {
  //         name: "Creamfills",
  //         category_name: "Candies and Toffies",
  //         brand_name: "Alpenliebe",
  //         total_sale: "708.97",
  //       },
  //       {
  //         name: "Cornetto Butterscotch",
  //         category_name: "Ice Creams",
  //         brand_name: "Kwality Walls",
  //         total_sale: "686.44",
  //       },
  //       {
  //         name: "Seasoned Chicken Burger",
  //         category_name: "Burgers and Sandwiches",
  //         brand_name: "Takeout Cafe",
  //         total_sale: "685.72",
  //       },
  //       {
  //         name: "Tonic Water Can",
  //         category_name: "Soda and Water",
  //         brand_name: "Schweppes",
  //         total_sale: "676.57",
  //       },
  //       {
  //         name: "Special",
  //         category_name: "Ice Creams",
  //         brand_name: "Gianis",
  //         total_sale: "669.47",
  //       },
  //       {
  //         name: "Hot Spicy Chicken",
  //         category_name: "Noodles and Pasta",
  //         brand_name: "WANG RAMEN",
  //         total_sale: "643.60",
  //       },
  //       {
  //         name: "Ginger Ale Can",
  //         category_name: "Fruit Juices and Drinks",
  //         brand_name: "Schweppes",
  //         total_sale: "641.77",
  //       },
  //       {
  //         name: "Aloo Bhujia",
  //         category_name: "Namkeen",
  //         brand_name: "Haldiram",
  //         total_sale: "639.23",
  //       },
  //       {
  //         name: "Club Soda",
  //         category_name: "Soda and Water",
  //         brand_name: "Catch",
  //         total_sale: "631.73",
  //       },
  //       {
  //         name: "Maxx Macho Chilli",
  //         category_name: "Chips",
  //         brand_name: "Lays",
  //         total_sale: "630.38",
  //       },
  //       {
  //         name: "Potato Crisps Moroccan Minty Pudina",
  //         category_name: "Chips",
  //         brand_name: "Pepitos",
  //         total_sale: "622.77",
  //       },
  //       {
  //         name: "Nut Cracker",
  //         category_name: "Namkeen",
  //         brand_name: "Haldiram",
  //         total_sale: "619.14",
  //       },
  //       {
  //         name: "Belgian Choclate",
  //         category_name: "Ice Creams",
  //         brand_name: "Gianis",
  //         total_sale: "593.23",
  //       },
  //       {
  //         name: "Dark Chocolate Cone",
  //         category_name: "Ice Creams",
  //         brand_name: "Gianis",
  //         total_sale: "593.20",
  //       },
  //       {
  //         name: "Zappy Apple",
  //         category_name: "Soft Drinks",
  //         brand_name: "Shunya Go ",
  //         total_sale: "589.27",
  //       },
  //       {
  //         name: "Potato Crisps Sour Cream And Onion",
  //         category_name: "Chips",
  //         brand_name: "Jacker",
  //         total_sale: "583.25",
  //       },
  //       {
  //         name: "Guava Juice 180 ml",
  //         category_name: "Fruit Juices and Drinks",
  //         brand_name: "Real",
  //         total_sale: "571.26",
  //       },
  //       {
  //         name: "Grape Juice",
  //         category_name: "Soft Drinks",
  //         brand_name: "MOGU MOGU",
  //         total_sale: "562.50",
  //       },
  //       {
  //         name: "Kool Elaichi",
  //         category_name: "Dairy and Milk Shakes",
  //         brand_name: "Amul",
  //         total_sale: "559.13",
  //       },
  //       {
  //         name: "Dairy Milk Oreo ",
  //         category_name: "Chocolates",
  //         brand_name: "Cadbury",
  //         total_sale: "552.19",
  //       },
  //       {
  //         name: "Just Original Cold Coffee",
  //         category_name: "Dairy and Milk Shakes",
  //         brand_name: "AMRIT FOOD",
  //         total_sale: "542.84",
  //       },
  //       {
  //         name: "Serene Aqua Dream",
  //         category_name: "Home Cleaning",
  //         brand_name: "Lia",
  //         total_sale: "541.53",
  //       },
  //       {
  //         name: "Piri Piri Paradise",
  //         category_name: "Popcorn",
  //         brand_name: "Mr Makhana",
  //         total_sale: "535.72",
  //       },
  //       {
  //         name: "Premium Cherry Berry",
  //         category_name: "Fruit Juices and Drinks",
  //         brand_name: "Frutjus",
  //         total_sale: "535.72",
  //       },
  //       {
  //         name: "Roasted Salted Peanut ",
  //         category_name: "Chips",
  //         brand_name: "Cornitos",
  //         total_sale: "535.72",
  //       },
  //       {
  //         name: "Lemon N Lime Water",
  //         category_name: "Soda and Water",
  //         brand_name: "Catch",
  //         total_sale: "535.06",
  //       },
  //       {
  //         name: "Cream and Onion",
  //         category_name: "Popcorn",
  //         brand_name: "Mr Makhana",
  //         total_sale: "528.07",
  //       },
  //       {
  //         name: "Navrattan",
  //         category_name: "Namkeen",
  //         brand_name: "Haldiram",
  //         total_sale: "522.29",
  //       },
  //       {
  //         name: "Potato Spicy Wafers",
  //         category_name: "Snacks",
  //         brand_name: "Lets Try",
  //         total_sale: "521.37",
  //       },
  //       {
  //         name: "Water",
  //         category_name: "Soda and Water",
  //         brand_name: "Aquafina",
  //         total_sale: "520.58",
  //       },
  //       {
  //         name: "Roasted Salted Pistachios",
  //         category_name: "Nuts and Seeds",
  //         brand_name: "Bazana",
  //         total_sale: "508.98",
  //       },
  //       {
  //         name: "Frooti Pet",
  //         category_name: "Beverages",
  //         brand_name: "Frooti",
  //         total_sale: "508.95",
  //       },
  //       {
  //         name: "Vanilla",
  //         category_name: "Ice Creams",
  //         brand_name: "Gianis",
  //         total_sale: "508.48",
  //       },
  //       {
  //         name: "High Protein Muesli Dark Chocolate And Cranberry",
  //         category_name: "Supplements",
  //         brand_name: "Muscle Blaze",
  //         total_sale: "503.40",
  //       },
  //       {
  //         name: "Puffcorn Yummy Cheese",
  //         category_name: "Chips",
  //         brand_name: "Kurkure",
  //         total_sale: "501.49",
  //       },
  //       {
  //         name: "Pineapple Juice",
  //         category_name: "Soft Drinks",
  //         brand_name: "MOGU MOGU",
  //         total_sale: "487.68",
  //       },
  //       {
  //         name: "Basil Seed Apple",
  //         category_name: "Fruit Juices and Drinks",
  //         brand_name: "Coco Royal",
  //         total_sale: "482.16",
  //       },
  //       {
  //         name: "Milk Classic",
  //         category_name: "Chocolates",
  //         brand_name: "EWedel",
  //         total_sale: "476.57",
  //       },
  //       {
  //         name: "Chaat Masti",
  //         category_name: "Chips",
  //         brand_name: "Bingo",
  //         total_sale: "474.04",
  //       },
  //       {
  //         name: "Caffe Mocha",
  //         category_name: "Ice Creams",
  //         brand_name: "Gianis",
  //         total_sale: "457.65",
  //       },
  //       {
  //         name: "Potato Crisps Hot and Spicy",
  //         category_name: "Chips",
  //         brand_name: "Jacker",
  //         total_sale: "454.85",
  //       },
  //       {
  //         name: "Pineapple Juice",
  //         category_name: "Fruit Juices and Drinks",
  //         brand_name: "Frutjus",
  //         total_sale: "446.45",
  //       },
  //       {
  //         name: "2 minutes noodles",
  //         category_name: "Noodles and Pasta",
  //         brand_name: "Maggi",
  //         total_sale: "446.43",
  //       },
  //       {
  //         name: "Popcorn Tub ",
  //         category_name: "Popcorn",
  //         brand_name: "Act ll Instant",
  //         total_sale: "446.42",
  //       },
  //       {
  //         name: "Sesame Oil",
  //         category_name: "Oils and Ghee",
  //         brand_name: "Tilsona",
  //         total_sale: "444.77",
  //       },
  //       {
  //         name: "Crunchy Party Mix",
  //         category_name: "Snacks",
  //         brand_name: "Lets Try",
  //         total_sale: "444.10",
  //       },
  //       {
  //         name: "Black Pepper Roasted Peanuts ",
  //         category_name: "Nuts and Seeds",
  //         brand_name: "Jabsons",
  //         total_sale: "437.50",
  //       },
  //       {
  //         name: "Jower Puffs Masala",
  //         category_name: "Popcorn",
  //         brand_name: "HULLY GULLY",
  //         total_sale: "434.80",
  //       },
  //       {
  //         name: "Blunt Cone White 109 MM",
  //         category_name: "Rolling Paper",
  //         brand_name: "Hash",
  //         total_sale: "433.62",
  //       },
  //       {
  //         name: "Paper Cup",
  //         category_name: "glass",
  //         brand_name: "TNS",
  //         total_sale: "430.00",
  //       },
  //       {
  //         name: "JUST Original Cold Coffee Hazelnut",
  //         category_name: "Dairy and Milk Shakes",
  //         brand_name: "AMRIT FOOD",
  //         total_sale: "428.56",
  //       },
  //       {
  //         name: "Silk Hazelnut",
  //         category_name: "Chocolates",
  //         brand_name: "Cadbury",
  //         total_sale: "427.95",
  //       },
  //       {
  //         name: "Rainbow Cassata",
  //         category_name: "Desserts",
  //         brand_name: "Gianis",
  //         total_sale: "423.75",
  //       },
  //       {
  //         name: "Aloo Tikki Burger ",
  //         category_name: "Burgers and Sandwiches",
  //         brand_name: "Takeout Cafe",
  //         total_sale: "419.05",
  //       },
  //       {
  //         name: "Good Day Butter Cookies",
  //         category_name: "Biscuits and Cookies",
  //         brand_name: "Britannia",
  //         total_sale: "411.02",
  //       },
  //       {
  //         name: "Moong daal ",
  //         category_name: "Namkeen",
  //         brand_name: "Haldiram",
  //         total_sale: "410.45",
  //       },
  //       {
  //         name: "Oreo Chocolate",
  //         category_name: "Biscuits and Cookies",
  //         brand_name: "Cadbury",
  //         total_sale: "407.34",
  //       },
  //       {
  //         name: "GN CONE BUTTER SCOTCH",
  //         category_name: "Ice Creams and Desserts",
  //         brand_name: "TNS",
  //         total_sale: "406.78",
  //       },
  //       {
  //         name: "Naturals Brown 4",
  //         category_name: "Hair Care",
  //         brand_name: "Garnier",
  //         total_sale: "403.16",
  //       },
  //       {
  //         name: "Veg Fruit Cake",
  //         category_name: "Wafers and Cakes",
  //         brand_name: "Britannia",
  //         total_sale: "402.56",
  //       },
  //       {
  //         name: "Haldirams gup chana",
  //         category_name: "Snacks",
  //         brand_name: "TNS",
  //         total_sale: "400.00",
  //       },
  //       {
  //         name: "maza aam panna ",
  //         category_name: "Soft Drinks",
  //         brand_name: "Maaza",
  //         total_sale: "393.49",
  //       },
  //       {
  //         name: "Patato Pudina Wafers",
  //         category_name: "Snacks",
  //         brand_name: "Lets Try",
  //         total_sale: "390.32",
  //       },
  //       {
  //         name: "Peri Peri Makhana",
  //         category_name: "Snacks",
  //         brand_name: "Lets Try",
  //         total_sale: "389.14",
  //       },
  //       {
  //         name: "Strawberry Flavoured Condoms",
  //         category_name: "Sexual Wellness",
  //         brand_name: "Manforce",
  //         total_sale: "386.40",
  //       },
  //       {
  //         name: "For Girls with Surprise",
  //         category_name: "Chocolates",
  //         brand_name: "Kinder Joy",
  //         total_sale: "373.76",
  //       },
  //       {
  //         name: "Kisses Moments",
  //         category_name: "Chocolates",
  //         brand_name: "Hersheys",
  //         total_sale: "370.77",
  //       },
  //       {
  //         name: "Hide And Seek Choco Rolls",
  //         category_name: "Biscuits and Cookies",
  //         brand_name: "Parle",
  //         total_sale: "363.58",
  //       },
  //       {
  //         name: "Strawberry milk",
  //         category_name: "Milk",
  //         brand_name: "Just",
  //         total_sale: "360.00",
  //       },
  //       {
  //         name: "Khaatta Meetha",
  //         category_name: "Namkeen",
  //         brand_name: "Haldiram",
  //         total_sale: "357.14",
  //       },
  //       {
  //         name: "Lime and Mint Roasted Cashews",
  //         category_name: "Nuts and Seeds",
  //         brand_name: "Bazana",
  //         total_sale: "357.12",
  //       },
  //       {
  //         name: "Corn Puffs Classic Salted",
  //         category_name: "Chips",
  //         brand_name: "Pepitos",
  //         total_sale: "352.63",
  //       },
  //       {
  //         name: "Sugar Free Chocolate Cream Biscuits",
  //         category_name: "Biscuits and Cookies",
  //         brand_name: "Tiffany",
  //         total_sale: "346.04",
  //       },
  //       {
  //         name: "Khatta Meetha ",
  //         category_name: "Popcorn",
  //         brand_name: "HULLY GULLY",
  //         total_sale: "341.21",
  //       },
  //       {
  //         name: "Pineapple Pastry",
  //         category_name: "Cafe - Pastries",
  //         brand_name: "Love at First Bite",
  //         total_sale: "339.00",
  //       },
  //       {
  //         name: "Cookies and Cream Cone",
  //         category_name: "Ice Creams",
  //         brand_name: "Gianis",
  //         total_sale: "336.88",
  //       },
  //       {
  //         name: "Sparkling Protein Water Black Grape",
  //         category_name: "Soda and Water",
  //         brand_name: "Muscle Blaze",
  //         total_sale: "321.91",
  //       },
  //       {
  //         name: "Pickle Tickle ",
  //         category_name: "Popcorn",
  //         brand_name: "Mr Makhana",
  //         total_sale: "321.39",
  //       },
  //       {
  //         name: "Ultra Energy Drink",
  //         category_name: "Energy and Sports Drinks",
  //         brand_name: "Monster",
  //         total_sale: "314.28",
  //       },
  //       {
  //         name: "Silk Mousse",
  //         category_name: "Chocolates",
  //         brand_name: "Cadbury",
  //         total_sale: "313.56",
  //       },
  //       {
  //         name: "Magic Cheese",
  //         category_name: "Popcorn",
  //         brand_name: "HULLY GULLY",
  //         total_sale: "312.50",
  //       },
  //       {
  //         name: "Gold Milk",
  //         category_name: "Milk",
  //         brand_name: "Amul",
  //         total_sale: "312.00",
  //       },
  //       {
  //         name: "Black Currant Soda",
  //         category_name: "Soda and Water",
  //         brand_name: "Catch",
  //         total_sale: "302.16",
  //       },
  //       {
  //         name: "Multi Textured Condoms",
  //         category_name: "Sexual Wellness",
  //         brand_name: "Bold Care",
  //         total_sale: "300.00",
  //       },
  //       {
  //         name: "Tiramisu Dark Almond",
  //         category_name: "Chocolates",
  //         brand_name: "Bon Amour",
  //         total_sale: "296.60",
  //       },
  //       {
  //         name: "Pralines Classic Dark",
  //         category_name: "Chocolates",
  //         brand_name: "Cadbury",
  //         total_sale: "294.92",
  //       },
  //       {
  //         name: "Extend Delay Spray For Men",
  //         category_name: "Sexual Wellness",
  //         brand_name: "Bold Care",
  //         total_sale: "294.64",
  //       },
  //       {
  //         name: "Cola Vanilla Cocktails",
  //         category_name: "Fruit Juices and Drinks",
  //         brand_name: "SIPP",
  //         total_sale: "293.75",
  //       },
  //       {
  //         name: "Sparkling Protein Water Mixed Berry",
  //         category_name: "Soda and Water",
  //         brand_name: "Muscle Blaze",
  //         total_sale: "291.17",
  //       },
  //       {
  //         name: "Milk Flavoured Coffee",
  //         category_name: "Dairy and Milk Shakes",
  //         brand_name: "Just",
  //         total_sale: "285.74",
  //       },
  //       {
  //         name: "Tandoori Roasted Peanuts ",
  //         category_name: "Nuts and Seeds",
  //         brand_name: "Jabsons",
  //         total_sale: "285.72",
  //       },
  //       {
  //         name: "Potato Crisps South African Peri Peri",
  //         category_name: "Chips",
  //         brand_name: "Pepitos",
  //         total_sale: "281.23",
  //       },
  //       {
  //         name: "Grape Escape Cocktails",
  //         category_name: "Fruit Juices and Drinks",
  //         brand_name: "SIPP",
  //         total_sale: "278.13",
  //       },
  //       {
  //         name: "Hazelnut Tub ",
  //         category_name: "Cafe - Ice cream",
  //         brand_name: "Kwality Walls",
  //         total_sale: "275.42",
  //       },
  //       {
  //         name: "Majestic Kesar Pista ",
  //         category_name: "Ice Creams",
  //         brand_name: "Kwality Walls",
  //         total_sale: "273.39",
  //       },
  //     ],
  //   },
  //   {
  //     BottomProducts: [
  //       {
  //         name: "MMMMM Masala",
  //         category_name: "Chips",
  //         brand_name: "Bingo",
  //         total_sale: "-3345.45",
  //       },
  //       {
  //         name: "No sugar can",
  //         category_name: "Soft Drinks",
  //         brand_name: "coca cola",
  //         total_sale: "-3248.46",
  //       },
  //       {
  //         name: "Mango",
  //         category_name: "Fruit Juices and Drinks",
  //         brand_name: "Real",
  //         total_sale: "-1103.23",
  //       },
  //       {
  //         name: "Gentle Baby Soap",
  //         category_name: "Bath and Body",
  //         brand_name: "Himalaya",
  //         total_sale: "-961.86",
  //       },
  //       {
  //         name: "Baby Powder",
  //         category_name: "Bath and Body",
  //         brand_name: "Himalaya",
  //         total_sale: "-772.73",
  //       },
  //       {
  //         name: "Food Wrapping Paper",
  //         category_name: "Paper and Tissues",
  //         brand_name: "Fiora",
  //         total_sale: "-689.11",
  //       },
  //       {
  //         name: "Roll On",
  //         category_name: "Pain Relief",
  //         brand_name: "Amrutanjan",
  //         total_sale: "-667.68",
  //       },
  //       {
  //         name: "Cough Cold Relief Rub",
  //         category_name: "Pain Relief",
  //         brand_name: "Amrutanjan",
  //         total_sale: "-596.20",
  //       },
  //       {
  //         name: "Sensitive",
  //         category_name: "Oral Care",
  //         brand_name: "Colgate",
  //         total_sale: "-562.29",
  //       },
  //       {
  //         name: "Kids Toothpaste",
  //         category_name: "Oral Care",
  //         brand_name: "Colgate",
  //         total_sale: "-540.76",
  //       },
  //       {
  //         name: "Heart Blush",
  //         category_name: "Chocolates",
  //         brand_name: "Cadbury",
  //         total_sale: "-508.26",
  //       },
  //       {
  //         name: "Total Whole Mouth",
  //         category_name: "Oral Care",
  //         brand_name: "Colgate",
  //         total_sale: "-458.98",
  //       },
  //       {
  //         name: "Stop O Bric ",
  //         category_name: "Home Cleaning",
  //         brand_name: "Lia",
  //         total_sale: "-403.64",
  //       },
  //       {
  //         name: "Gentle Baby Shampoo",
  //         category_name: "Hair Care",
  //         brand_name: "Himalaya",
  //         total_sale: "-347.88",
  //       },
  //       {
  //         name: "Baby Massage Oil",
  //         category_name: "Skin Care",
  //         brand_name: "Himalaya",
  //         total_sale: "-286.02",
  //       },
  //       {
  //         name: "Mango Juice",
  //         category_name: "Fruit Juices and Drinks",
  //         brand_name: "Real",
  //         total_sale: "-277.90",
  //       },
  //       {
  //         name: "Choco Cookies",
  //         category_name: "Cookies",
  //         brand_name: "Sapphire",
  //         total_sale: "-146.18",
  //       },
  //       {
  //         name: "Steel Foam Pad ",
  //         category_name: "Kitchen",
  //         brand_name: "SIR PRIZE",
  //         total_sale: "-133.78",
  //       },
  //       {
  //         name: "Groundnut Oil",
  //         category_name: "Oils and Ghee",
  //         brand_name: "Oreal",
  //         total_sale: "-117.85",
  //       },
  //       {
  //         name: "Fresh Flower",
  //         category_name: "Fragnances",
  //         brand_name: "Nivea",
  //         total_sale: "-104.80",
  //       },
  //       {
  //         name: "Stainless Steel Scrubber",
  //         category_name: "Kitchen",
  //         brand_name: "SIR PRIZE",
  //         total_sale: "-102.54",
  //       },
  //       {
  //         name: "Sweet Chili ",
  //         category_name: "Chips",
  //         brand_name: "Cornitos",
  //         total_sale: "-66.96",
  //       },
  //       {
  //         name: "Yellow Duster",
  //         category_name: "Home Cleaning",
  //         brand_name: "Ashad Handloom",
  //         total_sale: "-66.06",
  //       },
  //       {
  //         name: "Chocolate Biscuits",
  //         category_name: "Biscuits and Cookies",
  //         brand_name: "Zess",
  //         total_sale: "-65.24",
  //       },
  //       {
  //         name: "Tangerine ",
  //         category_name: "Home Cleaning",
  //         brand_name: "Lia",
  //         total_sale: "-61.02",
  //       },
  //       {
  //         name: "Krack Jack",
  //         category_name: "Cookies",
  //         brand_name: "Parle",
  //         total_sale: "-45.76",
  //       },
  //       {
  //         name: "Popular Vermicelli",
  //         category_name: "Dessert Mixes",
  //         brand_name: "Bambino",
  //         total_sale: "-44.48",
  //       },
  //       {
  //         name: "Litchi Amla Squash",
  //         category_name: "Fruit Juices and Drinks",
  //         brand_name: "Normal Life",
  //         total_sale: "-35.18",
  //       },
  //       {
  //         name: "Mystic Rose",
  //         category_name: "Toilet",
  //         brand_name: "Odonil",
  //         total_sale: "-34.87",
  //       },
  //       {
  //         name: "Jowar Puffs Butter Masala",
  //         category_name: "Snacks",
  //         brand_name: "HULLY GULLY",
  //         total_sale: "-33.67",
  //       },
  //       {
  //         name: "Whitening Smooth Skin",
  //         category_name: "Skin Care",
  //         brand_name: "Nivea",
  //         total_sale: "-31.78",
  //       },
  //       {
  //         name: "Strawberry ",
  //         category_name: "Candies and Toffies",
  //         brand_name: "Hartbeat",
  //         total_sale: "-21.30",
  //       },
  //       {
  //         name: "Extra Power Pain Balm",
  //         category_name: "Pain Relief",
  //         brand_name: "Amrutanjan",
  //         total_sale: "-15.06",
  //       },
  //       {
  //         name: "Non Woven Kitchen Towels ",
  //         category_name: "Kitchen",
  //         brand_name: "SIR PRIZE",
  //         total_sale: "0.00",
  //       },
  //       {
  //         name: "Good day",
  //         category_name: "Cookies",
  //         brand_name: "Britannia",
  //         total_sale: "5.00",
  //       },
  //       {
  //         name: "Monaco",
  //         category_name: "Biscuits and Cookies",
  //         brand_name: "Parle",
  //         total_sale: "8.47",
  //       },
  //       {
  //         name: "MOM'S MAGIC",
  //         category_name: "Biscuits and Cookies",
  //         brand_name: "Sunfeast",
  //         total_sale: "8.47",
  //       },
  //       {
  //         name: "Treat Creme Coconut",
  //         category_name: "Wafers and Cakes",
  //         brand_name: "Britannia",
  //         total_sale: "9.32",
  //       },
  //       {
  //         name: "SUNFEAST CAKER CHOCO",
  //         category_name: "Cakes",
  //         brand_name: "TNS",
  //         total_sale: "10.00",
  //       },
  //       {
  //         name: "Roasted Vermicelli",
  //         category_name: "Dessert Mixes",
  //         brand_name: "Bambino",
  //         total_sale: "12.05",
  //       },
  //       {
  //         name: "Pineapple Cake",
  //         category_name: "Wafers and Cakes",
  //         brand_name: "Britannia",
  //         total_sale: "12.71",
  //       },
  //       {
  //         name: "Butter Scotch",
  //         category_name: "Ice Creams",
  //         brand_name: "Creambell",
  //         total_sale: "16.95",
  //       },
  //       {
  //         name: "Milkybar",
  //         category_name: "Chocolates",
  //         brand_name: "Nestle",
  //         total_sale: "17.86",
  //       },
  //       {
  //         name: "Mature Cheddar and Red Onions",
  //         category_name: "Chips",
  //         brand_name: "Kettle",
  //         total_sale: "20.42",
  //       },
  //       {
  //         name: "Pudina Tread Chips",
  //         category_name: "Chips",
  //         brand_name: "Haldiram",
  //         total_sale: "22.32",
  //       },
  //       {
  //         name: "Nupur Henna ",
  //         category_name: "Hair Care",
  //         brand_name: "Godrej",
  //         total_sale: "23.52",
  //       },
  //       {
  //         name: "G-butter66gm",
  //         category_name: "Snacks",
  //         brand_name: "Britannia",
  //         total_sale: "25.41",
  //       },
  //       {
  //         name: "Mint Hole",
  //         category_name: "Gums and Mints",
  //         brand_name: "Polo",
  //         total_sale: "25.41",
  //       },
  //       {
  //         name: "Fruit And Nut Cookies",
  //         category_name: "Biscuits and Cookies",
  //         brand_name: "Unibic",
  //         total_sale: "25.42",
  //       },
  //       {
  //         name: "Sizzlin Hot Nacho",
  //         category_name: "Chips",
  //         brand_name: "Doritos",
  //         total_sale: "26.79",
  //       },
  //       {
  //         name: "SUNFEAST DARK FANTASY BOURBON 60GMS",
  //         category_name: "Cookies",
  //         brand_name: "TNS",
  //         total_sale: "27.00",
  //       },
  //       {
  //         name: "Diet coke Pet Bottle",
  //         category_name: "Fruit Juices and Drinks",
  //         brand_name: "Coca Cola",
  //         total_sale: "27.34",
  //       },
  //       {
  //         name: "SANDWICH CHOCO VANILLA",
  //         category_name: "Ice Creams and Desserts",
  //         brand_name: "Icelings",
  //         total_sale: "27.83",
  //       },
  //       {
  //         name: "Tomato Discs",
  //         category_name: "Chips",
  //         brand_name: "Peppy",
  //         total_sale: "28.57",
  //       },
  //       {
  //         name: "Choco Vanilla Cone",
  //         category_name: "Ice Creams and Desserts",
  //         brand_name: "Kwality Walls",
  //         total_sale: "29.66",
  //       },
  //       {
  //         name: "Soya Milk Chocolate",
  //         category_name: "Dairy and Milk Shakes",
  //         brand_name: "Sofit ",
  //         total_sale: "31.25",
  //       },
  //       {
  //         name: "Barbeque ",
  //         category_name: "Chips",
  //         brand_name: "Cornitos",
  //         total_sale: "31.25",
  //       },
  //       {
  //         name: "Soya Milk Vanilla",
  //         category_name: "Fruit Juices and Drinks",
  //         brand_name: "Sofit ",
  //         total_sale: "31.25",
  //       },
  //       {
  //         name: "Nacho Crisps Sweet Chilli",
  //         category_name: "Chips",
  //         brand_name: "Cornitos",
  //         total_sale: "31.25",
  //       },
  //       {
  //         name: "Spyci",
  //         category_name: "Soft Drinks",
  //         brand_name: "Bisleri",
  //         total_sale: "33.90",
  //       },
  //       {
  //         name: "Instant Hand Sanitizer",
  //         category_name: "General Medicine",
  //         brand_name: "Sefo",
  //         total_sale: "35.52",
  //       },
  //       {
  //         name: "Strawberry Milk shake",
  //         category_name: "Energy and Sports Drinks",
  //         brand_name: "Hersheys",
  //         total_sale: "35.71",
  //       },
  //       {
  //         name: "Cookies n Cream Flavour Milk Shake",
  //         category_name: "Energy and Sports Drinks",
  //         brand_name: "Hersheys",
  //         total_sale: "35.71",
  //       },
  //       {
  //         name: "Strong Pain Balm",
  //         category_name: "Pain Relief",
  //         brand_name: "Amrutanjan",
  //         total_sale: "35.71",
  //       },
  //       {
  //         name: "Chocolate Milk Shake",
  //         category_name: "Energy and Sports Drinks",
  //         brand_name: "Hersheys",
  //         total_sale: "35.71",
  //       },
  //       {
  //         name: "Tedhe Medhe Masala Tadka",
  //         category_name: "Chips",
  //         brand_name: "Bingo",
  //         total_sale: "35.72",
  //       },
  //       {
  //         name: "Tangy Pudina",
  //         category_name: "Popcorn",
  //         brand_name: "HULLY GULLY",
  //         total_sale: "37.95",
  //       },
  //       {
  //         name: "Charged",
  //         category_name: "Soft Drinks",
  //         brand_name: "Thumps Up",
  //         total_sale: "38.13",
  //       },
  //       {
  //         name: "Green Apple Non Alcoholic Beer",
  //         category_name: "Soft Drinks",
  //         brand_name: "Budweiser",
  //         total_sale: "38.14",
  //       },
  //       {
  //         name: "CRAX CHESE BALL",
  //         category_name: "Snacks",
  //         brand_name: "Crax",
  //         total_sale: "40.00",
  //       },
  //       {
  //         name: "Fruit Juice Pineapple",
  //         category_name: "Fruit Juices and Drinks",
  //         brand_name: "Dole",
  //         total_sale: "40.18",
  //       },
  //       {
  //         name: "Magic Masala",
  //         category_name: "Popcorn",
  //         brand_name: "HULLY GULLY",
  //         total_sale: "41.29",
  //       },
  //       {
  //         name: "bour bon",
  //         category_name: "Biscuits and Cookies",
  //         brand_name: "Britannia",
  //         total_sale: "42.37",
  //       },
  //       {
  //         name: "Himalayan Pink Salt ",
  //         category_name: "Chips",
  //         brand_name: "Kettle",
  //         total_sale: "44.20",
  //       },
  //       {
  //         name: "Diced Mango In Fruit Juice",
  //         category_name: "Fruit Juices and Drinks",
  //         brand_name: "Dole",
  //         total_sale: "44.64",
  //       },
  //       {
  //         name: "Just Jelly Strawberry",
  //         category_name: "Candies and Toffies",
  //         brand_name: "Alpenliebe",
  //         total_sale: "44.64",
  //       },
  //       {
  //         name: "Corn Puffs Magic Masala",
  //         category_name: "Chips",
  //         brand_name: "Pepitos",
  //         total_sale: "44.64",
  //       },
  //       {
  //         name: "Corn Bite Schezwan",
  //         category_name: "Pizza",
  //         brand_name: "HULLY GULLY",
  //         total_sale: "44.64",
  //       },
  //       {
  //         name: "Creamy Milk",
  //         category_name: "Chocolates",
  //         brand_name: "Hersheys",
  //         total_sale: "46.61",
  //       },
  //       {
  //         name: "Amber Rose ",
  //         category_name: "Home Cleaning",
  //         brand_name: "Lia",
  //         total_sale: "46.61",
  //       },
  //       {
  //         name: "Club Soda ",
  //         category_name: "Soda and Water",
  //         brand_name: "Bisleri",
  //         total_sale: "47.64",
  //       },
  //       {
  //         name: "Classic Masala Peanuts",
  //         category_name: "Namkeen",
  //         brand_name: "Haldiram",
  //         total_sale: "48.21",
  //       },
  //       {
  //         name: "Pazzta Mushroom Penne",
  //         category_name: "Noodles and Pasta",
  //         brand_name: "Nestle",
  //         total_sale: "50.00",
  //       },
  //       {
  //         name: "fruit &nut cookies",
  //         category_name: "Cookies",
  //         brand_name: "Unibic",
  //         total_sale: "50.00",
  //       },
  //       {
  //         name: "Premium Badam Sticks",
  //         category_name: "Wafers and Cakes",
  //         brand_name: "Gone Mad",
  //         total_sale: "50.85",
  //       },
  //       {
  //         name: "Mixed Fruit Jam",
  //         category_name: "Jams",
  //         brand_name: "Kissan",
  //         total_sale: "53.57",
  //       },
  //       {
  //         name: "Farmlite Oats With Raisins Biscuits",
  //         category_name: "Biscuits and Cookies",
  //         brand_name: "Sunfeast",
  //         total_sale: "55.08",
  //       },
  //       {
  //         name: " Farmlite Biscuits Oats With Chocolate ",
  //         category_name: "Biscuits and Cookies",
  //         brand_name: "Sunfeast",
  //         total_sale: "55.08",
  //       },
  //       {
  //         name: "Daily Pasta Spirali",
  //         category_name: "Noodles and Pasta",
  //         brand_name: "Bambino",
  //         total_sale: "56.25",
  //       },
  //       {
  //         name: "Ginger Hops Cocktails",
  //         category_name: "Fruit Juices and Drinks",
  //         brand_name: "SIPP",
  //         total_sale: "58.60",
  //       },
  //       {
  //         name: "Ultra Thin Chocolate Condoms",
  //         category_name: "Sexual Wellness",
  //         brand_name: "Bold Care",
  //         total_sale: "59.00",
  //       },
  //       {
  //         name: "Cornetto Disc Oreo ",
  //         category_name: "Ice Creams",
  //         brand_name: "Kwality Walls",
  //         total_sale: "59.32",
  //       },
  //       {
  //         name: "Lily Fresh",
  //         category_name: "Laundry",
  //         brand_name: "Comfort",
  //         total_sale: "59.75",
  //       },
  //       {
  //         name: " Pudina Party",
  //         category_name: "Popcorn",
  //         brand_name: "Mr Makhana",
  //         total_sale: "61.09",
  //       },
  //       {
  //         name: "Comfy Snug Fit ",
  //         category_name: "Sexual Wellness",
  //         brand_name: "Amrutanjan",
  //         total_sale: "62.22",
  //       },
  //       {
  //         name: "Tranquil Alpine Valley",
  //         category_name: "Home Cleaning",
  //         brand_name: "Lia",
  //         total_sale: "63.45",
  //       },
  //       {
  //         name: "50 50 Sweet And Salty",
  //         category_name: "Cookies",
  //         brand_name: "Britannia",
  //         total_sale: "63.55",
  //       },
  //       {
  //         name: "Dark Fantasy Choco Fills",
  //         category_name: "Biscuits and Cookies",
  //         brand_name: "Sunfeast",
  //         total_sale: "63.56",
  //       },
  //       {
  //         name: "Treat Creme Orange",
  //         category_name: "Wafers and Cakes",
  //         brand_name: "Britannia",
  //         total_sale: "64.40",
  //       },
  //       {
  //         name: "Pummkin Seeds",
  //         category_name: "Nuts and Seeds",
  //         brand_name: "Bazana",
  //         total_sale: "66.97",
  //       },
  //       {
  //         name: "Dark Milk 50",
  //         category_name: "Chocolates",
  //         brand_name: "EWedel",
  //         total_sale: "67.05",
  //       },
  //       {
  //         name: "Taaza Fresh Toned Milk",
  //         category_name: "Milk",
  //         brand_name: "Amul",
  //         total_sale: "68.57",
  //       },
  //       {
  //         name: "Kesar Badam Milk",
  //         category_name: "Dairy and Milk Shakes",
  //         brand_name: "Just",
  //         total_sale: "72.53",
  //       },
  //       {
  //         name: "Golden Glow Peel off Mask",
  //         category_name: "Face Care",
  //         brand_name: "Everyuth",
  //         total_sale: "73.78",
  //       },
  //       {
  //         name: "Little Hearts",
  //         category_name: "Biscuits and Cookies",
  //         brand_name: "Britannia",
  //         total_sale: "76.26",
  //       },
  //       {
  //         name: "Dazzli Tutti Frutti",
  //         category_name: "Ice Creams",
  //         brand_name: "Kwality Walls",
  //         total_sale: "78.05",
  //       },
  //       {
  //         name: "Potato Crisps American Cream N Onion",
  //         category_name: "Chips",
  //         brand_name: "Pepitos",
  //         total_sale: "80.36",
  //       },
  //       {
  //         name: "Strawberry Syrup",
  //         category_name: "Mixes and Syrups",
  //         brand_name: "Hersheys",
  //         total_sale: "80.51",
  //       },
  //       {
  //         name: "PANCHRATTAN NAMKEEN ",
  //         category_name: "Namkeen",
  //         brand_name: "Bikano",
  //         total_sale: "81.36",
  //       },
  //       {
  //         name: "Loofah",
  //         category_name: "Hygiene",
  //         brand_name: "Palmolive",
  //         total_sale: "82.23",
  //       },
  //       {
  //         name: "Cheese Spread Punchy Pepper",
  //         category_name: "Nut Butters and Spreads",
  //         brand_name: "Amul",
  //         total_sale: "83.93",
  //       },
  //       {
  //         name: "Vege Crackers",
  //         category_name: "Biscuits and Cookies",
  //         brand_name: "Munchys",
  //         total_sale: "84.32",
  //       },
  //       {
  //         name: "Cheezi Balls",
  //         category_name: "Chips",
  //         brand_name: "Haldiram",
  //         total_sale: "84.74",
  //       },
  //       {
  //         name: "Bourbon ",
  //         category_name: "Biscuits and Cookies",
  //         brand_name: "Britannia",
  //         total_sale: "85.35",
  //       },
  //       {
  //         name: "Butter Cookies",
  //         category_name: "Biscuits and Cookies",
  //         brand_name: "McVities",
  //         total_sale: "86.56",
  //       },
  //       {
  //         name: "Cheese Spread Yummy Cream",
  //         category_name: "Nut Butters and Spreads",
  //         brand_name: "Amul",
  //         total_sale: "87.50",
  //       },
  //       {
  //         name: "Cheese Spread Spicy Garlic",
  //         category_name: "Dairy",
  //         brand_name: "Amul",
  //         total_sale: "87.50",
  //       },
  //       {
  //         name: "Potato Crisps Himalayan Pink Salt",
  //         category_name: "Chips",
  //         brand_name: "Pepitos",
  //         total_sale: "87.52",
  //       },
  //       {
  //         name: "Adv Joint Muscle Spray",
  //         category_name: "Hygiene",
  //         brand_name: "Amrutanjan",
  //         total_sale: "88.13",
  //       },
  //       {
  //         name: "Rock Sea Salt and English Vinegar",
  //         category_name: "Chips",
  //         brand_name: "Kettle",
  //         total_sale: "88.40",
  //       },
  //       {
  //         name: "Corn Puffs Zingy Pizza",
  //         category_name: "Chips",
  //         brand_name: "Pepitos",
  //         total_sale: "89.28",
  //       },
  //       {
  //         name: "Mosambi Juice",
  //         category_name: "Fruit Juices and Drinks",
  //         brand_name: "Frutjus",
  //         total_sale: "89.29",
  //       },
  //       {
  //         name: "Premium Apple",
  //         category_name: "Fruit Juices and Drinks",
  //         brand_name: "Frutjus",
  //         total_sale: "89.29",
  //       },
  //       {
  //         name: "Maxx Sizzling Bbq",
  //         category_name: "Chips",
  //         brand_name: "Lays",
  //         total_sale: "89.30",
  //       },
  //       {
  //         name: "Chicken Burger",
  //         category_name: "Food",
  //         brand_name: "TNS",
  //         total_sale: "90.00",
  //       },
  //       {
  //         name: "Pasteurised Butter",
  //         category_name: "Nut Butters and Spreads",
  //         brand_name: "Amul",
  //         total_sale: "91.07",
  //       },
  //       {
  //         name: "Original Vanilla",
  //         category_name: "Biscuits and Cookies",
  //         brand_name: "Oreo",
  //         total_sale: "94.06",
  //       },
  //       {
  //         name: "Bhujia",
  //         category_name: "Namkeen",
  //         brand_name: "Haldiram",
  //         total_sale: "95.67",
  //       },
  //       {
  //         name: "DARK FANTASY CHOCOLATE SHAKE 180ml",
  //         category_name: "Cafe Section",
  //         brand_name: "Sunfeast",
  //         total_sale: "96.42",
  //       },
  //       {
  //         name: "Kisses Moments Gift Pack",
  //         category_name: "Chocolates",
  //         brand_name: "Hersheys",
  //         total_sale: "97.88",
  //       },
  //       {
  //         name: "Aluminum Foil ",
  //         category_name: "Kitchen",
  //         brand_name: "Wholesome",
  //         total_sale: "99.58",
  //       },
  //       {
  //         name: "pizza",
  //         category_name: "Food",
  //         brand_name: "TNS",
  //         total_sale: "100.00",
  //       },
  //       {
  //         name: "Vita Marie Gold ",
  //         category_name: "Biscuits and Cookies",
  //         brand_name: "Britannia",
  //         total_sale: "101.68",
  //       },
  //       {
  //         name: "Fab Bourbon",
  //         category_name: "Biscuits and Cookies",
  //         brand_name: "Parle",
  //         total_sale: "101.70",
  //       },
  //       {
  //         name: "5050 Maska Chaska",
  //         category_name: "Biscuits and Cookies",
  //         brand_name: "Britannia",
  //         total_sale: "103.39",
  //       },
  //       {
  //         name: "Pistachio Flavour Milk",
  //         category_name: "Dairy and Milk Shakes",
  //         brand_name: "Coco High",
  //         total_sale: "105.78",
  //       },
  //       {
  //         name: "Premium",
  //         category_name: "Cigarettes",
  //         brand_name: "Gold Flake",
  //         total_sale: "105.82",
  //       },
  //       {
  //         name: "Blackcurrant",
  //         category_name: "Candies and Toffies",
  //         brand_name: "Hartbeat",
  //         total_sale: "106.93",
  //       },
  //       {
  //         name: "chilli sprinkled ",
  //         category_name: "Chips",
  //         brand_name: "Bingo",
  //         total_sale: "107.14",
  //       },
  //       {
  //         name: "Cream And Onion",
  //         category_name: "Nuts and Seeds",
  //         brand_name: "Mr Makhana",
  //         total_sale: "107.14",
  //       },
  //       {
  //         name: "Lime And Chilli",
  //         category_name: "Nuts and Seeds",
  //         brand_name: "Mr Makhana",
  //         total_sale: "107.14",
  //       },
  //       {
  //         name: "Jasmine",
  //         category_name: "Home Cleaning",
  //         brand_name: "Lia",
  //         total_sale: "108.21",
  //       },
  //       {
  //         name: "Mango",
  //         category_name: "Candies and Toffies",
  //         brand_name: "Hartbeat",
  //         total_sale: "110.71",
  //       },
  //       {
  //         name: "Purifying Neem Face Wash",
  //         category_name: "Face Care",
  //         brand_name: "Everyuth",
  //         total_sale: "111.09",
  //       },
  //       {
  //         name: "Roasted Salted Cashews",
  //         category_name: "Nuts and Seeds",
  //         brand_name: "Bazana",
  //         total_sale: "111.60",
  //       },
  //       {
  //         name: "Strawberry Shine Lip Care",
  //         category_name: "Face Care",
  //         brand_name: "Himalaya",
  //         total_sale: "113.56",
  //       },
  //       {
  //         name: "Comfy Snug Fit",
  //         category_name: "Hygiene",
  //         brand_name: "Amrutanjan",
  //         total_sale: "114.29",
  //       },
  //       {
  //         name: "Natural Black 1",
  //         category_name: "Hair Care",
  //         brand_name: "Garnier",
  //         total_sale: "114.69",
  //       },
  //       {
  //         name: "Sea Salt And Vinegar",
  //         category_name: "Nuts and Seeds",
  //         brand_name: "Mr Makhana",
  //         total_sale: "116.07",
  //       },
  //       {
  //         name: "Tiramisu Dark Hazelnut",
  //         category_name: "Chocolates",
  //         brand_name: "Bon Amour",
  //         total_sale: "118.64",
  //       },
  //       {
  //         name: "Pudina Makhana",
  //         category_name: "Snacks",
  //         brand_name: "Lets Try",
  //         total_sale: "119.71",
  //       },
  //       {
  //         name: "Elaichi milk",
  //         category_name: "Milk",
  //         brand_name: "Just",
  //         total_sale: "120.00",
  //       },
  //       {
  //         name: "Mini Samosa Cheese Corn ",
  //         category_name: "Momos, Puffs and Pockets",
  //         brand_name: "Mc Cain",
  //         total_sale: "120.54",
  //       },
  //       {
  //         name: "Natura Zero Calories",
  //         category_name: "Salt, Sugar and Jaggery",
  //         brand_name: "Sugar Free",
  //         total_sale: "120.54",
  //       },
  //       {
  //         name: "Naturals Darkest Brown 3",
  //         category_name: "Hair Care",
  //         brand_name: "Garnier",
  //         total_sale: "120.96",
  //       },
  //       {
  //         name: "Nourishing Baby Loition",
  //         category_name: "Hygiene",
  //         brand_name: "Dove",
  //         total_sale: "122.03",
  //       },
  //       {
  //         name: "Roasted peanuts Spicy Masala",
  //         category_name: "Nuts and Seeds",
  //         brand_name: "Jabsons",
  //         total_sale: "125.00",
  //       },
  //       {
  //         name: "Wasabi Coated Green Peas",
  //         category_name: "Namkeen",
  //         brand_name: "Tong Garden",
  //         total_sale: "125.00",
  //       },
  //       {
  //         name: "Red Onion Black Seed Oil Shampoo",
  //         category_name: "Hair Care",
  //         brand_name: "Wow",
  //         total_sale: "126.61",
  //       },
  //       {
  //         name: "Exotic Dark Chocolate Pomegranate",
  //         category_name: "Chocolates",
  //         brand_name: "Hersheys",
  //         total_sale: "127.12",
  //       },
  //       {
  //         name: "Mixed Fruit Sugar Free Chewing Gum",
  //         category_name: "Candies and Toffies",
  //         brand_name: "Orbit",
  //         total_sale: "131.36",
  //       },
  //       {
  //         name: "Cold Cream",
  //         category_name: "Skin Care",
  //         brand_name: "Ponds",
  //         total_sale: "132.62",
  //       },
  //       {
  //         name: "Party Special",
  //         category_name: "Churan and Digestives",
  //         brand_name: "Delight Nuts",
  //         total_sale: "133.04",
  //       },
  //       {
  //         name: "Marie Gold",
  //         category_name: "Biscuits and Cookies",
  //         brand_name: "Britannia",
  //         total_sale: "133.39",
  //       },
  //       {
  //         name: "Smokey Barbeque",
  //         category_name: "Popcorn",
  //         brand_name: "HULLY GULLY",
  //         total_sale: "133.92",
  //       },
  //       {
  //         name: "Absolute Calcium Bone",
  //         category_name: "Food",
  //         brand_name: "Drools",
  //         total_sale: "134.75",
  //       },
  //       {
  //         name: "Sharbat Rooh Afza",
  //         category_name: "Fruit Juices and Drinks",
  //         brand_name: "Hamdard",
  //         total_sale: "135.59",
  //       },
  //       {
  //         name: "Konnect B Micro Grey",
  //         category_name: "Noodles and Pasta",
  //         brand_name: "Portronics",
  //         total_sale: "138.00",
  //       },
  //       {
  //         name: "Aluminium Foils",
  //         category_name: "Kitchen",
  //         brand_name: "Wholesome",
  //         total_sale: "139.83",
  //       },
  //       {
  //         name: "Lime and Chilli",
  //         category_name: "Popcorn",
  //         brand_name: "Mr Makhana",
  //         total_sale: "142.84",
  //       },
  //       {
  //         name: "Silk Fruit And Nut",
  //         category_name: "Chocolates",
  //         brand_name: "Cadbury",
  //         total_sale: "144.06",
  //       },
  //       {
  //         name: "Oreo",
  //         category_name: "Chocolates",
  //         brand_name: "Cadbury",
  //         total_sale: "144.06",
  //       },
  //       {
  //         name: "Yumfills Pie Chocolate Chip",
  //         category_name: "Biscuits and Cookies",
  //         brand_name: "Sunfeast",
  //         total_sale: "144.07",
  //       },
  //       {
  //         name: "Oreo and Cream",
  //         category_name: "Ice Creams",
  //         brand_name: "Kwality Walls",
  //         total_sale: "144.15",
  //       },
  //       {
  //         name: "Gold Advance ",
  //         category_name: "Cigarettes",
  //         brand_name: "Marlboro",
  //         total_sale: "150.37",
  //       },
  //       {
  //         name: "Black Forest Feast ",
  //         category_name: "Ice Creams",
  //         brand_name: "Kwality Walls",
  //         total_sale: "152.54",
  //       },
  //       {
  //         name: "Kaju Mixture",
  //         category_name: "Namkeen",
  //         brand_name: "Haldiram",
  //         total_sale: "153.58",
  //       },
  //       {
  //         name: "Slik Oreo",
  //         category_name: "Chocolates",
  //         brand_name: "Cadbury",
  //         total_sale: "156.78",
  //       },
  //       {
  //         name: "Stop Buy 3 Get 1",
  //         category_name: "Home Cleaning",
  //         brand_name: "Lia",
  //         total_sale: "157.62",
  //       },
  //       {
  //         name: "Apple Lighter",
  //         category_name: "Raw Material",
  //         brand_name: "TNS",
  //         total_sale: "160.00",
  //       },
  //       {
  //         name: "Original Oreo",
  //         category_name: "Cookies",
  //         brand_name: "Oreo",
  //         total_sale: "160.84",
  //       },
  //       {
  //         name: "Extra Dark 80",
  //         category_name: "Chocolates",
  //         brand_name: "EWedel",
  //         total_sale: "165.25",
  //       },
  //       {
  //         name: "High Protein Muesli Fruits And Nuts",
  //         category_name: "Supplements",
  //         brand_name: "Muscle Blaze",
  //         total_sale: "167.80",
  //       },
  //       {
  //         name: "Orange",
  //         category_name: "Candies and Toffies",
  //         brand_name: "Hartbeat",
  //         total_sale: "172.31",
  //       },
  //       {
  //         name: "Cubes",
  //         category_name: "Ice Cubes",
  //         brand_name: "Crystal ice cube",
  //         total_sale: "177.39",
  //       },
  //       {
  //         name: "Apple juice 180 ml",
  //         category_name: "Fruit Juices and Drinks",
  //         brand_name: "Real",
  //         total_sale: "178.57",
  //       },
  //       {
  //         name: "Cheese Sandwich Cracker",
  //         category_name: "Biscuits and Cookies",
  //         brand_name: "Zess",
  //         total_sale: "178.58",
  //       },
  //       {
  //         name: "Anti Hangover Drink Blackberry",
  //         category_name: "Energy and Sports Drinks",
  //         brand_name: "ARMR",
  //         total_sale: "178.58",
  //       },
  //       {
  //         name: "Chicken Tikki Burger",
  //         category_name: "Burgers and Sandwiches",
  //         brand_name: "Takeout Cafe",
  //         total_sale: "180.96",
  //       },
  //       {
  //         name: "Spearmint Sugar Free Chewing Gum",
  //         category_name: "Candies and Toffies",
  //         brand_name: "Orbit",
  //         total_sale: "188.50",
  //       },
  //       {
  //         name: "Dairy Milk Silk",
  //         category_name: "Chocolates",
  //         brand_name: "Cadbury",
  //         total_sale: "190.68",
  //       },
  //       {
  //         name: "Lemon Sandwich",
  //         category_name: "Biscuits and Cookies",
  //         brand_name: "Julies",
  //         total_sale: "192.80",
  //       },
  //       {
  //         name: "Red Velvet",
  //         category_name: "Ice Creams",
  //         brand_name: "Gianis",
  //         total_sale: "194.92",
  //       },
  //       {
  //         name: "Traditional Basmati Rice",
  //         category_name: "Rice and Grains",
  //         brand_name: "Daawat",
  //         total_sale: "200.00",
  //       },
  //       {
  //         name: "Malt Non Alcoholic Beer",
  //         category_name: "Energy and Sports Drinks",
  //         brand_name: "Coolberg",
  //         total_sale: "200.85",
  //       },
  //       {
  //         name: "Cranberry Fusion Trail Mix",
  //         category_name: "Nuts and Seeds",
  //         brand_name: "Bazana",
  //         total_sale: "200.88",
  //       },
  //       {
  //         name: "Honey and Lemon Flavour",
  //         category_name: "Gums and Mints",
  //         brand_name: "Halls",
  //         total_sale: "203.40",
  //       },
  //       {
  //         name: "Darkly Divine Chocolate Sensation",
  //         category_name: "Ice Creams",
  //         brand_name: "Kwality Walls",
  //         total_sale: "219.49",
  //       },
  //       {
  //         name: "Bhelpuri",
  //         category_name: "Namkeen",
  //         brand_name: "Haldiram",
  //         total_sale: "223.20",
  //       },
  //       {
  //         name: "Corn Puffs Minty Pudina",
  //         category_name: "Chips",
  //         brand_name: "Pepitos",
  //         total_sale: "223.20",
  //       },
  //     ],
  //   },
  // ];
  // console.log(
  //   dashboardChartData.filter((item) => item && item["monthly"])[0]?.monthly,
  //   "shashank",
  //   chartType,
  //   dashboardChartData
  // );
  const initialValues = {
    storeId: null,
    toDate: moment(),
    fromDate: moment(),
  };

  console.log(monthlyData,"shahsank")

  // Output the sorted array starting from the current month
  // console.log(rotatedArray, "shank", currentIndex);

  const options = {
    chart: {
      backgroundColor: "white",
      type: "area",
      height: "500px",
    },
    title: {
      text: ``,
      align: "left",
    },
    plotOptions: {
      series: {
        fillColor: {
          linearGradient: ["blue"],
          stops: [
            [0, Highcharts.getOptions().colors[0]],
            // [
            //   1,
            //   Highcharts.color(Highcharts.getOptions().colors[0])
            //     .setOpacity(0)
            //     .get("blue"),
            // ],
          ],
        },
      },
    },
    xAxis:
      chartType == "monthly"
        ? {
            categories: monthlyData?.map(
              ({ month }) => month
            ),
          }
        : {
            type: "datetime",
            labels: {
              format: "{value:%Y-%b-%e}",
            },
          },

    // xAxis: {

    // },
    series: [
      {
        data:
          typeof dashboardChartData == "object" &&
          dashboardChartData &&
          dashboardChartData?.length
            ? chartType === "monthly"
              ? monthlyData.map((item) =>
                  item?.month
                    ? +item?.sales
                    : [Date.parse(item?.day || item?.week), +item?.sales]
                )
              : [
                  ...dashboardChartData
                    .filter((item) => item && item[chartType])?.[0]
                    [chartType].map((item) =>
                      item?.month
                        ? +item?.sales
                        : [Date.parse(item?.day || item?.week), +item?.sales]
                    ),
                  // []
                  // [1266883200000, 12623.1],
                  // ["1266883200000", 11723.1],
                  // [Date.parse("2022-05-14"), 10823.1],
                  // [Date.parse("2021-05-15"), 14123.1],
                  // [Date.parse("2023-05-16"), 18223.1],
                  // [Date.parse("2023-05-01"), 7693.1],
                  // [Date.parse("2023-05-18"), 20625.1],
                ]
            : [],
        tooltip: {
          valueDecimals: 2,
        },
      },
    ],
  };

  const { storeListForDropdown } = useSelector((state) => state.commonReducer);

  const dispatch = useDispatch();
  // console.log(
  //   [
  //     ...newData
  //       .filter((item) => item[chartType])?.[0]
  //       [chartType]?.map((item) =>
  //         item?.month
  //           ? +item?.sales
  //           : [Date.parse(item?.day || item?.week), +item?.sales]
  //       ),
  //   ],
  //   "shashank"
  //   //   newData?.filter((item) => item[chartType])
  // );
  useEffect(() => {
    // debugger;
    if (getUserTypeFromLs() == Constant?.UserTypeObj?.PO)
      dispatch(getDashboardTrend("*"));
    // dispatch(getDashboardData({}));
    if (getUserNameFromLs() === "mani")
      // dispatch(getDashboardEmbedUrl("cbd6b15c-eea9-4288-82c5-e5cd5bcd7e69"));
      dispatch(getDashboardEmbedUrl("92aff066-ae85-45fe-93b8-86c4e1414057"));
  }, []);
  useEffect(() => {
    if (dashboardChartData?.length) {
      let MonthlyData = dashboardChartData.filter(
        (item) => item && item["monthly"]
      )[0]?.monthly;
      // Get the current month
      const currentMonth = new Date().toLocaleString("default", {
        month: "long",
      });

      // Sort the array in ascending order based on the current month
      MonthlyData.sort((a, b) => {
        const monthOrder = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        return (
          monthOrder.indexOf(a.month?.trim()) -
          monthOrder.indexOf(b.month?.trim())
        );
      });

      // Find the index of the current month in the sorted array
      const currentIndex = MonthlyData.findIndex(
        (item) => item.month.trim() === currentMonth
      );

      // Rotate the array to start from the current month
      const rotatedArray = MonthlyData.slice(currentIndex + 1).concat(
        MonthlyData.slice(0, currentIndex + 1)
      );
      setMothlyData(rotatedArray);
    }
  }, [dashboardChartData]);
  return (
    <React.Fragment>
      {getUserTypeFromLs() == "PO" ? (
        <Stack>
          {dashboardChartData?.length ? (
            <Stack direction="row" sx={{ width: "100%", mt: 2 }}>
              <Stack spacing={1}>
                <Chip
                  label="Daily"
                  component="div"
                  // href="#basic-chip"
                  variant="outlined"
                  sx={chartType == "daily" ? { background: "lightblue" } : {}}
                  clickable
                  onClick={() => setChartType("daily")}
                />
                <Chip
                  label="Weekly"
                  component="div"
                  // href="#basic-chip"
                  sx={chartType == "weekly" ? { background: "lightblue" } : {}}
                  variant="outlined"
                  clickable
                  onClick={() => setChartType("weekly")}
                />
                <Chip
                  label="Monthly"
                  component="div"
                  // href="#basic-chip"
                  sx={chartType == "monthly" ? { background: "lightblue" } : {}}
                  variant="outlined"
                  clickable
                  onClick={() => setChartType("monthly")}
                />
              </Stack>
              <Stack sx={{ width: "100%" }}>
                <div
                  style={{ display: "flex", margin: "0 auto", width: "450px" }}
                >
                  <div
                    style={{
                      width: "45%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Sales trends for
                  </div>
                  <AutoComplete
                    freeSolo={false}
                    // onBlur={handleBlur}
                    values={storeId}
                    onChange={(event, value) => {
                      if (value !== null) {
                        setStoreId(value?.id);
                        dispatch(getDashboardTrend(value?.id));
                        // setFieldValue("storeName", value?.storename);
                      }
                    }}
                    name={"storeId"}
                    label={"All my store"}
                    options={[
                      { id: "*", storename: "All My store" },
                      ...storeListForDropdown,
                    ]}
                    getOptionLabel={(option) => option?.storename}
                    // isRequired={true}
                    getOptionSelected={(option, value) => {
                      return `${option?.id}` === `${value?.id}`;
                    }}
                  />
                </div>

                <React.Fragment key={chartType}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    ctorType={"stockChart"}
                    options={options}
                  />
                </React.Fragment>
              </Stack>
              <Stack sx={{ height: 400 }}>
                <Box
                  sx={{
                    border: "1px solid #dae8fc",
                    padding: 2,

                    // flex: 1,
                    background: "#dae8fc",
                    borderRadius: 1,
                    textAlign: "center",
                    m: 1,
                  }}
                >
                  <Box>
                    <b>Today</b>{" "}
                  </Box>
                  <Box>
                    Rs.{" "}
                    {dashboardChartData?.length &&
                      dashboardChartData[0]?.["daily"][
                        dashboardChartData[0]?.["daily"]?.length - 1
                      ]?.sales}
                  </Box>
                </Box>
                <Box
                  sx={{
                    border: "1px solid #dae8fc",
                    padding: 2,
                    // flex: 1,
                    background: "#dae8fc",
                    borderRadius: 1,
                    textAlign: "center",
                    m: 1,
                  }}
                >
                  <Box>
                    <Box>
                      <b>This week</b>
                    </Box>
                    Rs.{" "}
                    {dashboardChartData?.length &&
                      dashboardChartData[1]?.["weekly"][
                        dashboardChartData[1]?.["weekly"]?.length - 1
                      ]?.sales}
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: 250,
                    // height:70,
                    border: "1px solid #dae8fc",
                    padding: 2,
                    // pt:2,
                    // flex: 1,
                    background: "#dae8fc",
                    borderRadius: 1,
                    textAlign: "center",
                    m: 1,
                  }}
                >
                  <Box>
                    <Box>
                      <b>This month</b>
                    </Box>
                    Rs.{" "}
                    {dashboardChartData?.length &&
                      dashboardChartData[2]?.["monthly"].filter(
                        (item) => item?.month.trim() == moment().format("MMMM")
                      )[0]?.sales}
                  </Box>
                </Box>
              </Stack>
            </Stack>
          ) : (
            <React.Fragment></React.Fragment>
          )}
          <h3> Advance analytics </h3>
          <Formik
            // enableReinitialize
            initialValues={initialValues}
            // validationSchema={Schema.storeStockSchema}
            onSubmit={(values) => {
              // if (!values?.storeId) {
              //   Utils.showAlert(2, "Please select store");
              //   return;
              // }
              dispatch(getDashboardDetail(values));
            }}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              // handleChange,
              // handleBlur,
              handleSubmit,
              // isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Box sx={{ display: "flex", mb: 2, alignItems: "center" }}>
                  <AutoComplete
                    freeSolo={false}
                    // onBlur={handleBlur}
                    values={values.store}
                    onChange={(event, value) => {
                      if (value !== null) {
                        setFieldValue("storeId", value?.id);
                      }
                    }}
                    helperText={touched.storeId && errors.storeId}
                    error={Boolean(touched.storeId && errors.storeId)}
                    name={"storeId"}
                    label={"storeId"}
                    touched={touched.storeId}
                    options={
                      [
                        { storename: "All My store", id: "*" },
                        ...storeListForDropdown,
                      ] || []
                    }
                    getOptionLabel={(option) => option?.storename}
                    // isRequired={true}
                    getOptionSelected={(option, value) => {
                      return `${option?.id}` === `${value?.id}`;
                    }}
                  />
                  <DatePicker
                    value={values.fromDate}
                    name="fromDate"
                    handleChange={(newVal) => setFieldValue("fromDate", newVal)}
                    label="From date"
                    // error={errors.fromDate}
                    // maxDate={new Date(moment(new Date()).subtract(1, "days"))}
                  />
                  <DatePicker
                    value={values.toDate}
                    name="toDate"
                    handleChange={(newVal) => setFieldValue("toDate", newVal)}
                    label="To date"
                    // error={errors.toDate}
                    // maxDate={new Date(moment(new Date()).subtract(1, "days"))}
                  />
                </Box>

                <Box
                  sx={{ display: "flex", mb: 2, justifyContent: "flex-end" }}
                >
                  <Button variant="outlined" type="submit">
                    Submit
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{
                border: "1px solid #dae8fc",
                padding: 2,
                flex: 1,
                background: "#dae8fc",
                borderRadius: 1,
                textAlign: "center",
                m: 2,
              }}
            >
              <Box>
                {dashboardDetailData?.length &&
                  dashboardDetailData[0]?.Cards[0]?.total_sale}
              </Box>
              <Box>Total Sale</Box>
            </Box>
            <Box
              sx={{
                border: "1px solid #e1d5e7",
                padding: 2,
                flex: 1,
                background: "#e1d5e7",
                borderRadius: 1,
                textAlign: "center",
                m: 2,
              }}
            >
              <Box>
                {dashboardDetailData?.length &&
                  dashboardDetailData[0]?.Cards[0]?.number_of_orders}
              </Box>
              <Box>Number of orders</Box>
            </Box>
            <Box
              sx={{
                border: "1px solid #dae8fc",
                padding: 2,
                flex: 1,
                background: "#dae8fc",
                borderRadius: 1,
                textAlign: "center",
                m: 2,
              }}
            >
              <Box>
                {dashboardDetailData?.length &&
                dashboardDetailData[0]?.Cards[0]?.aov
                  ? Number(dashboardDetailData[0]?.Cards[0]?.aov).toFixed(2)
                  : 0}
              </Box>
              <Box>Avarage Bill Value</Box>
            </Box>
          </Box>
          <Box>
            <StoreTable
              rows={
                dashboardDetailData?.length > 1 &&
                dashboardDetailData[1]?.Stores
              }
            />
          </Box>
          <Box sx={{ display: "flex", width: "100%" }}>
            <div style={{ margin: "5px", flex: 1 }}>
              <h3 style={{ textAlign: "center" }}>Categories analysis</h3>
              <CategoryTable
                rows={
                  dashboardDetailData?.length > 2 &&
                  dashboardDetailData[3]?.Categories
                }
              />
            </div>
            <div style={{ margin: "5px", flex: 1 }}>
              <h3 style={{ textAlign: "center" }}>Top Brands</h3>
              <TopBrands
                rows={
                  dashboardDetailData?.length > 2 &&
                  dashboardDetailData[2]?.Brands
                }
              />
            </div>
          </Box>
          <Box sx={{ display: "flex" }}>
            <div style={{ margin: "5px", flex: 1 }}>
              <h3 style={{ textAlign: "center" }}>Top 100 products</h3>
              <TopProducts
                rows={
                  dashboardDetailData?.length > 4 &&
                  dashboardDetailData[4]?.TopProducts
                }
              />
            </div>
            <div style={{ margin: "5px", flex: 1 }}>
              <h3 style={{ textAlign: "center" }}>Bottom 100 products</h3>
              <BottomProducts
                rows={
                  dashboardDetailData?.length > 5 &&
                  dashboardDetailData[5]?.BottomProducts
                }
              />
            </div>
          </Box>
        </Stack>
      ) : (
        <Box>
          {getUserNameFromLs() === "mani" ? (
            <Box sx={{ height: "100%" }}>
              <iframe
                width="100%"
                height="700"
                src={url}
                // src="https://ap-south-1.quicksight.aws.amazon.com/sn/embed/share/accounts/423040184443/dashboards/92aff066-ae85-45fe-93b8-86c4e1414057?directory_alias=tns-analytics"
              ></iframe>
            </Box>
          ) : (
            <React.Fragment></React.Fragment>
          )}

          {/* <Box sx={{ display: "flex", mt: 3 }}>
            <Card sx={{ minWidth: 250, m: 1 }}>
              <CardContent>
                <Typography
                  sx={{ fontSize: 28, fontWaight: 700, textAlign: "center" }}
                  gutterBottom
                >
                  <b> Total sale till date</b>
                </Typography>
                <Typography
                  sx={{
                    fontSize: 22,
                    textAlign: "center",
                    // color: dashboard?.last30Days > 0 ? "green" : "red",
                  }}
                  gutterBottom
                >
                  <b> {dashboard?.allTimeSales}</b>
                </Typography>
              </CardContent>
            </Card>
            <Card sx={{ minWidth: 250, m: 1, textAlign: "center" }}>
              <CardContent>
                <Typography sx={{ fontSize: 28, fontWaight: 700 }} gutterBottom>
                  <b> 1 month sale</b>
                </Typography>
                <Typography
                  sx={{
                    fontSize: 22,
                    textAlign: "center",
                    // color: dashboard?.last30Days > 0 ? "green" : "red",
                  }}
                  gutterBottom
                >
                  <b> {dashboard?.last30Days}</b>
                </Typography>
              </CardContent>
            </Card>
            <Card sx={{ minWidth: 250, m: 1 }}>
              <CardContent>
                <Typography
                  sx={{ fontSize: 28, fontWaight: 700, textAlign: "center" }}
                  gutterBottom
                >
                  <b>Month on month growth</b>
                </Typography>
                <Typography
                  sx={{
                    fontSize: 22,
                    textAlign: "center",
                    color:
                      dashboard?.differenceBetween6030 > 0 ? "green" : "red",
                  }}
                  gutterBottom
                >
                  <b> {dashboard?.differenceBetween6030 || 0}%</b>
                </Typography>
              </CardContent>
            </Card>
            <Card sx={{ minWidth: 250, m: 1 }}>
              <CardContent>
                <Typography
                  sx={{ fontSize: 28, fontWaight: 700, textAlign: "center" }}
                  gutterBottom
                >
                  <b> Week on week growth</b>
                </Typography>
                <Typography
                  sx={{
                    fontSize: 22,
                    textAlign: "center",
                    color:
                      dashboard?.differenceBetween0714 > 0 ? "green" : "red",
                  }}
                  gutterBottom
                >
                  <b> {dashboard?.differenceBetween0714 || 0}%</b>
                </Typography>
              </CardContent>
            </Card>
          </Box>
          <Divider sx={{ border: "1px dashed rgba(0,0,0,0.5)", my: 2 }} /> */}
        </Box>
      )}
    </React.Fragment>
  );
};
export default Dashboard;
