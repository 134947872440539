import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Box from "@mui/material/Box";
import { permission } from "../../utility/userPermissionData";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import navy_alarm from "./navy_alarm.mp3";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuOpenTwoToneIcon from "@mui/icons-material/MenuOpenTwoTone";
// import Backdrop from "@mui/material/Backdrop";
// import CircularProgress from "@mui/material/CircularProgress";
import Modal from "../modal";
import TransitionsModal from "../modal";

import { useDispatch, useSelector } from "react-redux";
import StorefrontIcon from "@mui/icons-material/Storefront";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import InventoryIcon from "@mui/icons-material/Inventory";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ArticleIcon from "@mui/icons-material/Article";
import Utils from "../../utility";
import LogoutMenu from "./menu";
import { getOM, getRunner, getBiller, getWM } from "./action";
import { getProductsForDropdown } from "../../pages/productRelated/products/action";
import { getWarehouseListForDropdown } from "../../pages/warehouseRelated/warehouses/action";
import { getCategoryListForDropdown } from "../../pages/productRelated/productCategory/action";
import {
  creatOrderForPayment,
  getBillerById,
  getStoreListForDropdown,
  getStoreRoyalty,
} from "../../pages/storeRelated/store/action";
import { getAllUserForDropdown } from "../../pages/userAccount/users/action";
import {
  getPropertyOwnerDetail,
  getPropertyOwnerForDropdown,
} from "../../pages/userAccount/propertyOwner/action";
import { getBrandOwnerListForDropdown } from "../../pages/userAccount/brandOwner.js/action";
import { getBrandListForDropdown } from "../../pages/productRelated/brands/action";
import { getAllOrdersTimeInterval } from "../../pages/superAdmin/onlineOrder/action";
import { Button } from "@mui/material";
import { getUserTypeFromLs } from "../../utility/commonFunction";
const menu = [
  {
    icon: <SupervisorAccountIcon />,
    title: "Super Admin",
    key: "superAdmin",
    items: [
      {
        title: "App Version",
        to: "/app-version",
      },
      {
        title: "Banner",
        to: "/banner",
      },
      {
        title: "Online Order",
        to: "/online-order",
      },
    ],
  },
  // {
  //   icon: <ReceiptIcon />,
  //   title: "Invoices/Billings",
  //   key: "Invoices/Billings",
  //   id: "invoiceBilling",

  //   items: [
  //     {
  //       id: "order",
  //       title: "Order",
  //       to: "/order",
  //     },
  //     {
  //       id: "return",
  //       title: "Return",
  //       to: "/order-return",
  //     },
  //   ],
  // },
  {
    icon: <DashboardIcon />,
    title: "DashBoard",
    key: "dashboard",
    id: "dashboard",
    items: [
      {
        id: "dashboard",
        title: "Dashboard",
        to: "/dashboard",
      },
      // {
      //   title: "Return",
      //   to: "/return",
      // },
    ],
  },
  {
    icon: <StorefrontIcon />,
    title: "Store Related",
    key: "storeRelated",
    id: "storesRelated",
    items: [
      {
        id: "store",
        title: "Stores",
        to: "/store",
      },
      {
        id: "royalty",
        title: "Store Royalty",
        to: "/store-royalty",
      },
      {
        id: "royaltyHistory",
        title: "Royalty History",
        to: "/royalty-history",
      },

      {
        id: "skutimeline",
        title: "SKU Timeline",
        to: "/SKU-Timeline",
      },
      // {
      //   title: "Store RTV stocks",
      //   to: "/rtvstocks",
      // },
      // {
      //   title: "Discarded store Stocks",
      //   to: "/discard-store-stocks",
      // },

      // {
      //   title: "Edit Cost & Selling  Price",
      //   to: "/edit-cost-selling-price",
      // },
    ],
  },
  {
    icon: <SummarizeIcon />,
    title: "Reports",
    key: "reportsRelated",
    id: "reportsRelated",
    items: [
      {
        id: "billingReport",
        title: "Billing Report",
        to: "/billing-report",
      },
      {
        id: "return",
        title: "Return Report",
        to: "/return-report",
      },
      {
        id: "discardRtvReport",
        title: "Store Discard Report",
        to: "/discard-rtv-report",
      },
      {
        id: "storeStock",
        title: "Current Stock Report",
        to: "/current-stock-report",
      },

      {
        id: "cashoutReport",
        title: "Cash Out Report",
        to: "/cash-out-report",
      },
      {
        title: "Pending Cash Report",
        to: "/pending-cash-report",
      },
      {
        id: "storeRtvReport",
        title: "Store RTV Report",
        to: "/Store-rtv-report",
      },
      {
        id: "storeGrnReport",
        title: "Store GRN Report",
        to: "/store-grn-report",
      },
      {
        title: "Credit",
        to: "/credit",
      },
      // {
      //   title: "Warehouse grn report",
      //   to: "/warehouse-grn-report",
      // },
      // {
      //   id: "stockLedger",
      //   title: "Ledger Reports (Beta)",
      //   to: "/stock-ledger",
      // },
    ],
  },
  // {
  //   icon: <WarehouseIcon />,
  //   title: "Warehouse Related",
  //   key: "warehouseRelated",
  //   id: "warehouseRelated",
  //   items: [
  //     {
  //       title: "Warehouses",
  //       to: "/warehouse",
  //       id: "warehouse",
  //     },
  //     {
  //       title: "Warehouse Stocks",
  //       to: "/warehouse-stock",
  //       id: "warehouseStock",
  //     },

  //     {
  //       title: "SKU Timeline warehouse",
  //       to: "/SKU-Timeline-warehouse",
  //     },
  //     {
  //       title: "RTV'd Warehouse Stocks",
  //       to: "/warehouse-rtvstocks",
  //       id: "warehouseRTV",
  //     },
  //     {
  //       title: "Discarded Warehouse Stocks",
  //       to: "/discard-warehouse-stocks",
  //       id: "warehouseDiscardRTV",
  //     },
  //   ],
  // },
  {
    icon: <ArticleIcon />,
    title: "GRN",
    id: "grnPo",
    key: "GRN",
    items: [
      {
        title: "GRN",
        to: "/grn",
        id: "grn",
      },

      // {
      //   title: "Store PO's",
      //   to: "/store-po",
      //   id: "storePo",
      // },

      // {
      //   id: "warehousePo",
      //   title: "Warehouse PO",
      //   to: "/warehouse-po",
      // },
    ],
  },
  {
    icon: <LocalShippingIcon />,
    title: "Stock Transfers",
    id: "stockTransfer",
    key: "stockTransfers",
    items: [
      // {
      //   title: "Warehouse to store ",
      //   to: "/warehouse-to-store",
      //   id: "stockTransferW2S",
      // },
      // {
      //   title: "Warehouse to warehouse",
      //   to: "/warehouse-to-warehouse",
      //   id: "stockTransferW2W",
      // },
      // {
      //   title: "Store to warehouse",
      //   to: "/store-to-warehouse",
      //   id: "stockTransferS2W",
      // },
      {
        title: "Store To Store",
        to: "/store-to-store",
        id: "stockTransferS2S",
      },
    ],
  },
  {
    icon: <SupervisedUserCircleIcon />,
    title: "User Accounts",
    key: "UserAccounts",
    id: "usersRelated",
    items: [
      {
        title: "Users",
        to: "/users",
        id: "user",
      },
      {
        title: "Customer",
        to: "/customer",
        id: "customer",
      },

      {
        title: "Brand Owner/Vendor",
        to: "/brand-owner",
        id: "brandOwner",
      },

      {
        title: "Property Owners",
        to: "/property-owner",
        id: "propertyOwner",
      },
    ],
  },
  {
    icon: <InventoryIcon />,
    title: "Product Related",
    id: "productRelated",
    key: "productRelated",
    items: [
      {
        id: "product",
        title: "Products",
        to: "/products",
      },
      {
        title: "Product Categories",
        to: "/category",
        id: "productCategory",
      },
      {
        title: "Brands",
        to: "/brands",
        id: "brand",
      },
      {
        id: "recipes",
        title: "Recipes",
        to: "/recipes",
      },
    ],
  },
];

const drawerWidth = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    // marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SingleLevel = ({ item }) => {
  const navigate = useNavigate();
  const { permissions } = useSelector((state) => state.commonReducer);

  return (
    <ListItem
      button
      onClick={() => navigate(item.to)}
      style={
        localStorage.getItem("userType") === "SA"
          ? {}
          : permissions[item?.id]?.view
          ? {}
          : { display: "none" }
      }
    >
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText primary={item.title} />
    </ListItem>
  );
};
const MultiLevel = ({ item, open1, setOpen1 }) => {
  const { items: children } = item;
  const [open, setOpen] = useState(false);
  const { permissions } = useSelector((state) => state.commonReducer);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  return (
    <React.Fragment>
      <div
        onMouseLeave={() => setOpen(false)}
        style={
          localStorage.getItem("userType") &&
          localStorage.getItem("userType") === "SA"
            ? {}
            : permissions && permissions[item?.id]?.view
            ? {}
            : { display: "none" }
        }
      >
        <ListItem key={item.title} disablePadding sx={{ display: "block" }}>
          <ListItemButton
            onClick={handleClick}
            sx={{
              minHeight: 48,
              justifyContent: open1 ? "initial" : "center",
              px: 1.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open1 ? 2 : "",
                justifyContent: "center",
              }}
            >
              {item.icon}
            </ListItemIcon>
            {open1 && (
              <ListItemText
                primary={item.title}
                sx={{ opacity: open1 ? 1 : 0 }}
              />
            )}
            {open1 && <>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</>}
          </ListItemButton>
        </ListItem>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((child, key) => (
              <MenuItem key={key} item={child} />
            ))}
          </List>
        </Collapse>
      </div>
    </React.Fragment>
  );
};

function hasChildren(item) {
  const { items: children } = item;

  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
}

const MenuItem = ({ item, open1, setOpen1 }) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return <Component item={item} open1={open1} setOpen1={setOpen1} />;
};

export default function MiniDrawer({ children }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openRoyaltyNotification, setOpenRoyaltyNotification] = useState(true);
  const { royaltyPending } = useSelector((state) => state.commonReducer);

  const dispatch = useDispatch();
  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const { pathname } = useLocation();
  const [song] = React.useState(new Audio(navy_alarm));

  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.loaderReducer);
  const { productsDropDownList } = useSelector(
    (state) => state.productsReducer
  );
  const {
    monthWiseRoyalty,

    storeId,
  } = useSelector((state) => state.storeReducer);

  const initializeRazorpay = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";

      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };
  const makePayment = async (amount) => {
    const res = await initializeRazorpay();

    if (!res) {
      alert("Razorpay SDK Failed to load");
      return;
    }

    // Make API call to the serverless API
    dispatch(
      creatOrderForPayment(
        {
          invoiceNumber: "inoice_20",
          amount: amount,
          storeId: `${storeId}`,
          notes: "string",
        },
        (res) => {
          const options = {
            key:"rzp_live_Z6X3ZRkz5MdEGL",
            // key: "rzp_test_LA8PGs4UYsEfeb", // Enter the Key ID generated from the Dashboard
            // key: "rzp_test_hNVoPt48fZga5Q",
            amount: res?.amount,
            currency: "INR",
            name: "NEW SHOP CSTORE NETWORK PRIVATE LIMITED",
            description: "Test Transaction",
            image: "https://thenewshop.in/images/favicon.png",
            order_id: res?.id,
            handler: async function (response) {
              localStorage.removeItem("royaltyPending");
              if (response.razorpay_order_id && pathname === "/payment-royalty")
                dispatch(getStoreRoyalty(navigate));
              else if (response.razorpay_order_id) dispatch(getStoreRoyalty());
              const data = {
                orderCreationId: res?.id,
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
              };
            },
            prefill: {
              // name: "Charak Almast",
              email: "productxventures@gmail.com",
              contact: "9650857683",
            },
            notes: {
              address: "",
            },
            theme: {
              color: "#0000",
            },
            method: {
              netbanking: true,
              card: true,
              upi: true,
              wallet: false,

              paylater: false,
              emi: false,
            },
            config: {
              display: {
                hide: [
                  {
                    method: "cardless_emi",
                  },
                  {
                    method: "paylater",
                  },
                  {
                    method: "wallet",
                  },
                  // {
                  //   netbanking: "wallet",
                  // },
                ],
                // sequence: ["block.utib", "block.other"],
                // preferences: {
                //   show_default_blocks: false, // Should Checkout show its default blocks?
                // },
              },
            },
          };

          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
          // var options = {
          //   key: "rzp_test_lid9spcoI6ovbJ", // Enter the Key ID generated from the Dashboard
          //   name: "TNS Pvt Ltd",
          //   currency: "INR",
          //   amount: res?.amount,
          //   order_id: res.id,
          //   description: "Thankyou for your test donation",
          //   image: "https://manuarora.in/logo.png",
          //   handler: function (response) {
          //     // Validate payment at server - using webhooks is a better idea.
          //     alert(response.razorpay_payment_id);
          //     alert(response.razorpay_order_id);
          //     alert(response.razorpay_signature);
          //   },
          //   // prefill: {
          //   //   name: "Manu Arora",
          //   //   email: "manuarorawork@gmail.com",
          //   //   contact: "9999999999",
          //   // },
          // };

          // const paymentObject = new window.Razorpay(options);
          // paymentObject.open();
        }
      )
    );
    // const data = {
    //   currency: "INR",
    //   amount: 50000,
    //   id: "order_NHgk9wJ6J3EJL3",
    // };

    // console.log(data);
  };

  useEffect(() => {
    if (Utils.constants.getUserIdFromLS() == "953") {
      dispatch(getWarehouseListForDropdown());
    }
    if (
      Utils.constants.getUsertypeFromLS() === Utils.constants.UserTypeObj.SA
    ) {
      dispatch(getOM());
      dispatch(getRunner());
      dispatch(getBiller());
      dispatch(getWM());
      dispatch(getWarehouseListForDropdown());
      dispatch(getAllUserForDropdown());
      dispatch(getPropertyOwnerForDropdown());
    }
    // if (Utils.constants.getUsertypeFromLS() !== Utils.constants.UserTypeObj.BO)

    dispatch(getBrandOwnerListForDropdown());
    dispatch(getStoreListForDropdown(navigate));
    dispatch(getCategoryListForDropdown());
    dispatch(getBrandListForDropdown());
    if (
      localStorage.getItem("productLength") != null &&
      !productsDropDownList?.length &&
      localStorage.getItem("productList") != null
    )
      dispatch({
        type: Utils.ActionName.COMMON,
        payload: {
          productsDropDownList: JSON.parse(localStorage.getItem("productList")),
        },
      });
    dispatch({
      type: Utils.ActionName.COMMON,
      payload: {
        permissions:
          localStorage.getItem("id") == "953"
            ? permission["Nine_Five_Three_Permission"]
            : permission[localStorage.getItem("userType")],
      },
    });
    // if (getUserTypeFromLs == "PO") {
    //   dispatch({
    //     type: Utils.ActionName.COMMON,
    //     payload: {
    //       royaltyPending:
    //         localStorage.getItem(royaltyPending) == 1 ? true : false,
    //     },
    //   });
    // }
    if (["PO"].includes(getUserTypeFromLs()))
      dispatch(
        getPropertyOwnerDetail(localStorage.getItem("id"), (res) => {
          dispatch({
            type: Utils.ActionName.STORE,
            payload: { storeId: res?.stores[0], status: "pending" },
          });
          dispatch(getStoreRoyalty());
        })
      );
    if (["B"].includes(getUserTypeFromLs()))
      dispatch(
        getBillerById(localStorage.getItem("id"), (res) => {
          dispatch({
            type: Utils.ActionName.STORE,
            payload: { storeId: res?.stores[0], status: "pending" },
          });
          dispatch(getStoreRoyalty());
        })
      );
  }, []);

  // React.useEffect(() => {
  //   // song.play()
  //   const interval = setInterval(
  //     () =>
  //       dispatch(
  //         getAllOrdersTimeInterval((res, preCount) => {
  //           if (res !== preCount) {
  //             song.play();
  //             setTimeout(() => {
  //               song.pause();
  //             }, 5000);
  //             dispatch({
  //               type: Utils.ActionName.ONLINE_ORDERS,
  //               payload: {
  //                 // orderList: respData.data?.order,
  //                 count: res,
  //               },
  //             });
  //           }
  //         })
  //       ),
  //     180 * 1000
  //   );

  //   return () => {
  //     if (interval) {
  //       clearInterval(interval);
  //     }
  //   };
  // }, []);
  useEffect(() => {
    // console.log(, "shashank");
    if (
      !productsDropDownList?.length &&
      localStorage.getItem("productLength") == null
    )
      dispatch(getProductsForDropdown());
  }, [pathname]);

  return (
    <Box sx={{ display: "flex" }}>
      {/* {monthWiseRoyalty.length > 0 && (
        <TransitionsModal header="Royalty Pending" open={true}>
          {" "}
         
        </TransitionsModal>
      )} */}
      <Modal
        header={`Royalty Payment`}
        children={
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Store Name</TableCell>
                  <TableCell>Royalty Pending</TableCell>
                  <TableCell>Month / Year</TableCell>
                  <TableCell>Invoice</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {monthWiseRoyalty.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.storename}</TableCell>
                    <TableCell>{row.royaltyPayable}</TableCell>
                    <TableCell>
                      {row.month_mmm} / {row.year}
                    </TableCell>
                    <TableCell>
                      <a
                        href={row.invoice}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Invoice
                      </a>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        onClick={() => makePayment(row?.royaltyPayable)}
                      >
                        Pay now
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        }
        handleClose={() => {
          setOpenRoyaltyNotification(false);
          // setIsEdit(null);
        }}
        open={openRoyaltyNotification && royaltyPending}
        minWidth={"1200px"}
      />
      <CssBaseline />
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 200 }}
        open={loading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <AppBar position="fixed" open={open} sx={{ background: "white" }}>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              display: "block",
              // ...(open && { display: 'none' }),
            }}
          >
            {open ? (
              <MenuOpenTwoToneIcon sx={{ color: "black" }} />
            ) : (
              <MenuIcon sx={{ color: "black" }} />
            )}
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            style={{ height: 60, display: "block" }}
          >
            <img
              src="https://thenewshop.in/header-logo.png"
              alt="the new shop"
              width="100%"
              height="100%"
            />
          </Typography>

          <LogoutMenu />
        </Toolbar>
      </AppBar>
      {pathname === "/payment-royalty" ? (
        <></>
      ) : (
        <Drawer
          variant="permanent"
          open={open}
          onMouseOver={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
        >
          <DrawerHeader>
            {/* <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronLeftIcon />}
          </IconButton> */}
            {/* The New shop */}
          </DrawerHeader>
          <Divider />
          {menu.map((item, key) => {
            // if (
            //   !Utils.constants.userRelated.includes(
            //     localStorage.getItem("userType")
            //   ) &&
            //   item.key === "UserAccounts"
            // )
            //   return false;
            // else if (
            //   !Utils.constants.storeRelated.includes(
            //     localStorage.getItem("userType")
            //   ) &&
            //   item.key === "storeRelated"
            // ) {
            //   return false;
            // } else if (
            //   !Utils.constants.storeStockRelated.includes(
            //     localStorage.getItem("userType")
            //   ) &&
            //   item.key === "storeRelated"
            // ) {
            //   return false;
            // } else if (
            //   !Utils.constants.stockTransferRelated.includes(
            //     localStorage.getItem("userType")
            //   ) &&
            //   item.key === "stockTransfers"
            // ) {
            //   return false;
            // } else if (
            //   !Utils.constants.warehouseRelated.includes(
            //     localStorage.getItem("userType")
            //   ) &&
            //   item.key === "warehouseRelated"
            // ) {
            //   return false;
            // } else if (
            //   !Utils.constants.warehouseStockRelated.includes(
            //     localStorage.getItem("userType")
            //   ) &&
            //   item.key === "stockTransfers"
            // ) {
            //   return false;
            // } else if (
            //   !Utils.constants.warehouseTransferRelated.includes(
            //     localStorage.getItem("userType")
            //   ) &&
            //   item.key === "warehouseRelated"
            // ) {
            //   return false;
            // } else if (
            //   !Utils.constants.productRelated.includes(
            //     localStorage.getItem("userType")
            //   ) &&
            //   item.key === "productRelated"
            // ) {
            //   return false;
            // }

            return (
              <MenuItem key={key} item={item} open1={open} setOpen1={setOpen} />
            );
          })}
          {/* <List>
          {['Dashboard', 'User', 'Dashboard', 'Send'].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
          <Divider />
          {/* <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
        </Drawer>
      )}
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: "100%", overflow: "auto" }}
      >
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
}
