import React, { useRef, useState } from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { fileUpload } from "../../productRelated/products/action";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import MenuItem from "@mui/material/MenuItem";
// import DatePicker from "../../../component/date";
import Schema from "../../../schema";
import AutoComplete from "../../../component/input/autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, FieldArray } from "formik";
import { updateStoreStock } from "./action";
import Input from "../../../component/input";
import { Box } from "@mui/material";
import { getPOById } from "../po/action";
import Select from "../../../component/select";
import Utils from "../../../utility";
import UploadImage from "../../../component/uploadImage";
import moment from "moment";

const AddUser = ({ setOpen, openEditSS, storeId }) => {
  const dispatch = useDispatch();
  // const { productsDropDownList } = useSelector((state) => state.productsReducer);

  const initialValues = {
    quantity: +openEditSS?.quantity,
    printedMrp: +openEditSS?.printedMrp,
    sellingPrice: +openEditSS?.sellingPrice,
    productCostPrice: +openEditSS?.productCostPrice,
  };
  // console.log(openEditSS, "openEditSS");
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        // validationSchema={Schema.storeStockSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          dispatch(
            updateStoreStock(
              values,
              openEditSS?.id,
              setOpen,
              storeId,
              setSubmitting
            )
          );
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            {/* {console.log(values, "valuesvalues")} */}
            <Box sx={{ display: "flex", mb: 2, alignItems: "center" }}>
              <Input
                value={openEditSS?.product?.name}
                name="productName"
                type="text"
                disabled={true}
                // error={errors.quantity}
                label="Product Name"
                // onChange={handleChange}
                // handleBlur={handleBlur}
              />
              <Input
                value={values.quantity}
                name="quantity"
                type="number"
                disabled={
                  localStorage.getItem("username") == "mani" ? false : true
                }
                error={errors.quantity}
                label="quantity"
                onChange={handleChange}
                handleBlur={handleBlur}
              />
              <Input
                value={values.printedMrp}
                name="printedMrp"
                type="number"
                error={errors.printedMrp}
                label="printedMrp"
                onChange={handleChange}
                handleBlur={handleBlur}
              />
              <Input
                value={values.sellingPrice}
                name="sellingPrice"
                type="number"
                error={errors.sellingPrice}
                label="sellingPrice"
                onChange={handleChange}
                handleBlur={handleBlur}
              />
              <Input
                value={values.productCostPrice}
                name="productCostPrice"
                type="number"
                error={errors.productCostPrice}
                label="productCostPrice"
                onChange={handleChange}
                handleBlur={handleBlur}
              />
            </Box>

            <Box sx={{ display: "flex", mb: 2, justifyContent: "flex-end" }}>
              <Button variant="outlined" type="submit" disabled={isSubmitting}>
                Update store stock
              </Button>
              <Button
                variant="outlined"
                onClick={() => setOpen(false)}
                style={{
                  border: "1px solid #e13030",
                  color: "#e13030",
                  marginLeft: 10,
                }}
              >
                Cancel
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
    //  </Authlayout>
  );
};

export default AddUser;
