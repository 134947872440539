import React, { useRef, useState } from "react";
import Button from "@mui/material/Button";
// import { getStoreList } from "../store/action";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { CSVLink } from "react-csv";
import DatePicker from "../../../component/date";
// import { TableHead, TableRow, Paper } from "@mui/material";
import Modal from "../../../component/modal";
import AutoComplete from "../../../component/input/autocomplete";
import Table from "./table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import Schema from "../../../schema";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import {
  getSKUTTypeWithquantity,
  getSKUTimeline,
  getSKUTimelineAll,
} from "./action";
import { Box } from "@mui/material";
import GeneratReport from "./generateReport";
import Utils from "../../../utility";
import { getAllUserForDropdown } from "../../userAccount/users/action";
// import { getProducts } from "../../productRelated/products/action";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GRNDetail from "../GRN/detailGRN";
import { useReactToPrint } from "react-to-print";
import { getStoreStockList } from "../storeStocks/action";

const headers = [
  { label: "Product ID", key: "ProductID" },
  { label: "Store Name", key: "storeName" },
  { label: "Product Name", key: "ProductName" },
  { label: "Date", key: "date" },
  { label: "Type", key: "type" },
  { label: "Billing ID", key: "billingId" },
  { label: "GRN ID", key: "grnId" },
  { label: "Return ID", key: "returnId" },
  { label: "Discard ID", key: "discardID" },
  { label: "RTV ID", key: "RTVId" },
  { label: "Previouse Quantity", key: "prevQuantity" },
  { label: "Current Quantity", key: "currentQuanttity" },
  { label: "Net Quantity", key: "netQuantity" },
  { label: "Edited By", key: "editedBy" },
];
const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "Id",
    sortDisable: true,
  },
  {
    id: "brandName",
    numeric: false,
    disablePadding: true,
    label: "Date",
    sortDisable: false,
  },
  {
    id: "type",
    numeric: false,
    disablePadding: true,
    label: "Type",
    sortDisable: false,
  },
  {
    id: "BILLINGID",
    numeric: false,
    disablePadding: true,
    label: "Billing ID",
    sortDisable: true,
  },
  {
    id: "GRNID",
    numeric: false,
    disablePadding: true,
    label: "GRN ID",
    sortDisable: true,
  },
  {
    id: "returnId",
    numeric: false,
    disablePadding: true,
    label: "Return ID",
    sortDisable: true,
  },
  {
    id: "dateCreated",
    numeric: false,
    disablePadding: true,
    label: "Discard ID",
    sortDisable: true,
  },
  {
    id: "RTVID",
    numeric: false,
    disablePadding: true,
    label: "RTV ID",
    sortDisable: true,
  },
  {
    id: "TransferId",
    numeric: false,
    disablePadding: true,
    label: "STN ID",
    sortDisable: true,
  },
  {
    id: "previousQunatity",
    numeric: true,
    disablePadding: false,
    label: "Previous Quantity",
    sortDisable: true,
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Currnet Quantity ",
    sortDisable: true,
  },
  {
    id: "netQuantity",
    numeric: true,
    disablePadding: false,
    label: "Net Quantity ",
    sortDisable: true,
  },
  {
    id: "editedBy",
    numeric: false,
    disablePadding: true,
    label: "Edited By",
    sortDisable: true,
  },
  // {
  //   id: "action",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Done by",
  //   sortDisable: true,
  // },
  // {
  //   id: "action",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Net",
  //   sortDisable: true,
  // },
];

export default function EnhancedTable() {
  const [csvData, setCsvData] = useState([]);
  const [initialValue, setInitialValue] = useState({});
  const [open, setOpen] = useState(false);
  const [openGRN, setOpenOpenGRN] = useState(false);
  const csvDownload = React.useRef(null);
  const [expanded, setExpanded] = React.useState(false);
  // const [openAddGrn, setOpenAddGrn] = useState(false);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const handleChange = (type) => (event, isExpanded) => {
    setExpanded(isExpanded ? type : false);
    if (isExpanded && initialValue?.productId) {
      dispatch({
        type: Utils.ActionName.SKU_TIMELINE,
        payload: { offset: 1, skuData: [] },
      });
      dispatch(getSKUTimeline(initialValue, type));
    }
  };

  // const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [isEdit, setIsEdit] = useState(null);
  // const [cvcData, gets2wList] = useState([]);
  const dispatch = useDispatch();
  const initialValues = {
    storeId: null,
    storeName: "",
    productName: "",
    toDate: moment(),
    fromDate: moment(),
    productId: null,
  };

  // const { storeListForDropdown } = useSelector((state) => state.storeReducer);
  // const {  } = useSelector((state) => state.SkuTimelineReducer);
  // const { productsDropDownList } = useSelector((state) => state.productsReducer);
  const { skuData, offset, take, count, skuTypeWithQuantity } = useSelector(
    (state) => state.SkuTimelineReducer
  );
  // console.log(skuTypeWithQuantity, "shashank");
  const { userlistForDropdown } = useSelector((state) => state.commonReducer);
  React.useEffect(() => {
    // dispatch(getProducts());
    // dispatch(getStoreList());
    // dispatch(getSKUTimelineAll());
    return () => {
      dispatch({
        type: Utils.ActionName.SKU_TIMELINE,
        payload: { skuData: [] },
      });
    };
  }, []);
  const {
    // warehouseListForDropdown,
    storeListForDropdown,
    // brandOwnerListForDropdown,
    productsDropDownList,
    // brandOwnerListForDropdown,
  } = useSelector((state) => state.commonReducer);
  React.useEffect(() => {
    if (skuData && skuData?.length) {
      const data = skuData.map((item) => {
        return {
          storeName: initialValue.storeName,
          ProductID: initialValue.productId,
          ProductName: initialValue.productName,
          date: moment(item.addDate).format("YYYY-MM-DD HH:mm:ss"),

          type: item?.type,
          billingId: item?.OrderId,
          grnId: item?.GrnId,
          returnId: item?.ReturnOrderId,
          discardID: item?.DiscardID,
          RTVId: item?.RtvId,
          prevQuantity: item?.OlqQty,
          currentQuanttity: item?.NewQty,
          netQuantity: item?.NewQty - item?.OlqQty,
          // editedBy: userlistForDropdown?.filter(
          //   (x) => x?.id == item?.EditedBy
          // )[0].name,
        };
      });
      setCsvData(data);
    }
  }, []);
  // console.log(userlistForDropdown, "userlistForDropdown");
  const handleChangePage = (event, newPage) => {
    dispatch({
      type: Utils.ActionName.SKU_TIMELINE,
      payload: { offset: newPage + 1 },
    });
    dispatch(getSKUTimeline(initialValue));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: Utils.ActionName.SKU_TIMELINE,
      payload: { take: event.target.value, offset: 1 },
    });
    dispatch(getSKUTimeline(initialValue));
  };
  const { storeStockList } = useSelector((state) => state.storeStockReducer);

  return (
    <div>
      <Box direction="row" spacing={2} sx={{ display: "flex", mb: 2 }}>
        <Formik
          initialValues={initialValues}
          validationSchema={Schema.SKUTimelineSchema}
          onSubmit={(values, { setSubmitting }) => {
            setInitialValue(values);
            dispatch(getSKUTTypeWithquantity(values));

            dispatch(getStoreStockList(values?.storeId));
            // dispatch(getSKUTimeline(values));
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Box sx={{ display: "flex" }}>
                <Stack direction="row" spacing={2} style={{ width: 200 }}>
                  <AutoComplete
                    freeSolo={false}
                    // onBlur={handleBlur}
                    values={values.storeId}
                    onChange={(event, value) => {
                      console.log(event.target.value, value);
                      if (value !== null) {
                        const index = storeListForDropdown.findIndex(
                          (x) => x.id === value.id
                        );

                        setFieldValue("storeId", value?.id);
                        setFieldValue("storeName", value?.storename);
                        dispatch({
                          type: Utils.ActionName.SKU_TIMELINE,
                          payload: { skuData: [] },
                        });
                      }
                    }}
                    helperText={touched.storeId && errors.storeId}
                    error={Boolean(touched.storeId && errors.storeId)}
                    name={"storeId"}
                    label={"store Name"}
                    touched={touched.storeId}
                    options={storeListForDropdown}
                    getOptionLabel={(option) => option?.storename}
                    // isRequired={true}
                    getOptionSelected={(option, value) => {
                      return `${option.id}` === `${value.id}`;
                    }}
                    defaultValue={() => {
                      const index = storeListForDropdown?.findIndex(
                        (x) => x?.id == values.storeId
                      );
                      return storeListForDropdown[index];
                    }}
                  />
                </Stack>

                <Stack direction="row" spacing={2} style={{ width: 200 }}>
                  <AutoComplete
                    freeSolo={false}
                    // onBlur={handleBlur}
                    values={values.productId}
                    onChange={(event, value) => {
                      console.log(event.target.value, value);
                      if (value !== null) {
                        const index = productsDropDownList.findIndex(
                          (x) => x.id === value.id
                        );

                        dispatch({
                          type: Utils.ActionName.STORE_STOCK,
                          payload: { search: value?.barcode },
                        });
                        setFieldValue("productId", value?.id);
                        setFieldValue("productName", value?.name);
                        dispatch({
                          type: Utils.ActionName.SKU_TIMELINE,
                          payload: { skuData: [] },
                        });
                      }
                    }}
                    helperText={touched.productId && errors.productId}
                    error={Boolean(touched.productId && errors.productId)}
                    name={"productId"}
                    label={"product Name"}
                    touched={touched.productId}
                    options={productsDropDownList}
                    getOptionLabel={(option) =>
                      `${option?.barcode} | ${option.name}`
                    }
                    // isRequired={true}
                    getOptionSelected={(option, value) => {
                      return `${option.id}` === `${value.id}`;
                    }}
                    defaultValue={() => {
                      const index = productsDropDownList?.findIndex(
                        (x) => x?.id == values.productId
                      );
                      return productsDropDownList[index];
                    }}
                  />
                </Stack>
                <Stack style={{ width: 200 }}>
                  <DatePicker
                    value={values.fromDate}
                    name="fromDate"
                    handleChange={(newVal) => setFieldValue("fromDate", newVal)}
                    label="From Date"
                    error={errors.fromDate}
                    minDate={new Date(moment(new Date()).subtract(90, "days"))}
                  />
                </Stack>
                {/* <Stack direction="row" spacing={2} style={{ width: 200 }}>
                  <DatePicker
                    value={values.toDate}
                    name="toDate"
                    handleChange={(newVal) => setFieldValue("toDate", newVal)}
                    label="To Date"
                    error={errors.toDate}
                  />
                </Stack> */}
                <Button
                  variant="outlined"
                  spacing={2}
                  sx={{ mr: 2 }}
                  type="submit"
                >
                  Submit
                </Button>
                {/* {skuData && skuData?.length ? (
                  <CSVLink
                    data={csvData}
                    headers={headers}
                    filename={"store-sku-timeline"}
                  >
                    <Button variant="outlined" spacing={2}>
                      Export
                    </Button>
                  </CSVLink>
                ) : ( */}
                {/* <Button
                  variant="outlined"
                  spacing={2}
                  onClick={() => setOpen(true)}
                  // disabled={!skuData?.length}
                >
                  Generate Report
                </Button> */}
                {/* )} */}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
      <Divider />

      <Paper sx={{ width: "100%", mb: 2 }}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <Box sx={{ display: "flex", bgcolor: "black", color: "white", p: 2 }}>
          <Box sx={{ flex: 1, fontWeight: 500, fontSize: 18 }}>Type</Box>
          <Box sx={{ flex: 2, fontWeight: 500, fontSize: 18 }}>Quantity</Box>
        </Box>
        <div>
          <Accordion
            expanded={expanded === "GRN"}
            onChange={handleChange("GRN")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography
                sx={
                  expanded === "GRN"
                    ? { width: "33%", flexShrink: 0, fontWeight: 700 }
                    : { width: "33%", flexShrink: 0 }
                }
              >
                GRN
              </Typography>
              <Typography
                sx={
                  // skuTypeWithQuantity["grn"] >= 0
                  //   ? { color: "green" }
                  //   : { color: "red" }
                  { color: "green" }
                }
              >
                {skuTypeWithQuantity["grn"]}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* <Typography>
            Nulla facilisi. Phasellus sollicitudin nulla et quam mattis feugiat.
            Aliquam eget maximus est, id dignissim quam.
          </Typography> */}
              <Table
                value={initialValue}
                type={"GRN"}
                setOpen={setOpenOpenGRN}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "ORDERS"}
            onChange={handleChange("ORDERS")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography
                sx={
                  expanded === "ORDERS"
                    ? { width: "33%", flexShrink: 0, fontWeight: 700 }
                    : { width: "33%", flexShrink: 0 }
                }
              >
                ORDERS
              </Typography>
              <Typography
                sx={
                  // skuTypeWithQuantity["order"] >= 0
                  //   ? { color: "green" }
                  //   :
                  { color: "red" }
                }
              >
                {skuTypeWithQuantity["order"]}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Table value={initialValue} type={"ORDERS"} />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "RETURNS"}
            onChange={handleChange("RETURNS")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography
                sx={
                  expanded === "RETURNS"
                    ? { width: "33%", flexShrink: 0, fontWeight: 700 }
                    : { width: "33%", flexShrink: 0 }
                }
              >
                RETURNS
              </Typography>
              <Typography
                sx={
                  // skuTypeWithQuantity["return"] >= 0
                  //   ? { color: "green" }
                  //   : { color: "red" }
                  { color: "green" }
                }
              >
                {skuTypeWithQuantity["return"]}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Table value={initialValue} type={"RETURNS"} />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "RTV"}
            onChange={handleChange("RTV")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>RTV</Typography>
              <Typography
                sx={
                  // skuTypeWithQuantity["rtv"] >= 0
                  //   ? { color: "green" }
                  //   :
                  { color: "red" }
                }
              >
                {skuTypeWithQuantity["rtv"]}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Table value={initialValue} type={"RTV"} />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "DISCARD"}
            onChange={handleChange("DISCARD")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography
                sx={
                  expanded === "DISCARD"
                    ? { width: "33%", flexShrink: 0, fontWeight: 700 }
                    : { width: "33%", flexShrink: 0 }
                }
              >
                DISCARD
              </Typography>
              <Typography
                sx={
                  // skuTypeWithQuantity["discard"] >= 0
                  //   ? { color: "green" }
                  //   :
                  { color: "red" }
                }
              >
                {skuTypeWithQuantity["discard"]}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Table value={initialValue} type={"DISCARD"} />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "TRANSFERIN"}
            onChange={handleChange("TRANSFERIN")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography
                sx={
                  expanded === "TRANSFERIN"
                    ? { width: "33%", flexShrink: 0, fontWeight: 700 }
                    : { width: "33%", flexShrink: 0 }
                }
              >
                TRANSFERIN
              </Typography>
              <Typography
                sx={
                  // skuTypeWithQuantity["transfer_in"] >= 0
                  //   ? { color: "green" }
                  //   : { color: "red" }
                  { color: "green" }
                }
              >
                {skuTypeWithQuantity["transfer_in"]}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Table value={initialValue} type={"TRANSFERIN"} />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "TRANSFEROUT"}
            onChange={handleChange("TRANSFEROUT")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography
                sx={
                  expanded === "TRANSFROUT"
                    ? { width: "33%", flexShrink: 0, fontWeight: 700 }
                    : { width: "33%", flexShrink: 0 }
                }
              >
                TRANSFEROUT
              </Typography>
              <Typography
                sx={
                  // skuTypeWithQuantity["transfer_out"] >= 0
                  //   ? { color: "green" }
                  //   :
                  { color: "red" }
                }
              >
                {skuTypeWithQuantity["transfer_out"]}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Table value={initialValue} type={"TRANSFEROUT"} />
            </AccordionDetails>
          </Accordion>
        </div>
        <Divider />
        <Box>
          <Typography sx={{ fontSize: "1.2rem", px: 2, py: 1 }}>
            <b style={{ paddingRight: "27.5%" }}>Total In:</b>
            {(+skuTypeWithQuantity["grn"] || 0) +
              (+skuTypeWithQuantity["return"] || 0) +
              (+skuTypeWithQuantity["transfer_in"] || 0)}
          </Typography>
          <Divider />
          {console.log(Object.keys(skuTypeWithQuantity).length, "skushashank")}
          <Typography sx={{ fontSize: "1.2rem", px: 2, py: 1 }}>
            <b style={{ paddingRight: "26.5%" }}>Total Out</b>{" "}
            {Object.keys(skuTypeWithQuantity).length !== 0
              ? (+skuTypeWithQuantity["order"] || 0) +
                (+skuTypeWithQuantity["transfer_out"] || 0) +
                (+skuTypeWithQuantity["rtv"] || 0) +
                (+skuTypeWithQuantity["discard"] || 0)
              : "0"}
          </Typography>
          <Divider />
          <Typography sx={{ fontSize: "1.2rem", px: 2, py: 1 }}>
            <b style={{ paddingRight: "23%" }}>Opening Stock</b>{" "}
            {storeStockList?.length &&
              +storeStockList[0]?.quantity +
                ((+skuTypeWithQuantity["order"] || 0) +
                  (+skuTypeWithQuantity["transfer_out"] || 0) +
                  (+skuTypeWithQuantity["rtv"] || 0) +
                  (+skuTypeWithQuantity["discard"] || 0)) -
                ((+skuTypeWithQuantity["grn"] || 0) +
                  (+skuTypeWithQuantity["return"] || 0) +
                  (+skuTypeWithQuantity["transfer_in"] || 0))}
          </Typography>
          <Divider />
          <Typography sx={{ fontSize: "1.2rem", px: 2, py: 1 }}>
            <b style={{ paddingRight: "23%" }}>Current Stock:</b>{" "}
            {+storeStockList[0]?.quantity || 0}
          </Typography>
        </Box>
        <Divider />

        {/* <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead />
            <TableBody>
              {skuData && skuData?.length ? (
                skuData.map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell
                        component="th"
                        id={index}
                        scope="row"
                        // padding="none"
                      >
                        {row.id}
                      </TableCell>
                      <TableCell align="left">
                        {moment(row.createdAt).format("YYYY-MM-DD") +
                          " " +
                          row.createdTime}
                      </TableCell>
                      <TableCell align="left">{row?.type || "N/A"}</TableCell>
                      <TableCell align="left">
                        {row?.OrderId || "N/A"}
                      </TableCell>
                      <TableCell align="left">{row?.GrnId || "N/A"}</TableCell>
                      <TableCell align="left">
                        {row?.ReturnOrderId || "N/A"}
                      </TableCell>
                      <TableCell align="left">
                        {row?.DiscardID || "N/A"}
                      </TableCell>
                      <TableCell align="left">{row?.RtvId || "N/A"}</TableCell>
                      <TableCell align="left" sx={{ width: 50 }}>
                        {row?.TransferId || "N/A"}
                      </TableCell>

                      <TableCell align="left">{row?.OlqQty}</TableCell>
                      <TableCell>{row?.NewQty}</TableCell>
                      <TableCell>{row?.NewQty - row?.OlqQty}</TableCell>
                      <TableCell align="left">
                        {userlistForDropdown?.filter(
                          (x) => x?.id == row?.EditedBy
                        )[0].name || "N/A"}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow hover tabIndex={-1}>
                  <TableCell
                    component="th"
                    id={"nodatafound"}
                    scope="row"
                    // padding="none"
                  >
                    No data found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer> */}
        {/* <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={count}
          rowsPerPage={take}
          page={offset - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>

      <Modal
        header={`Generate SKU Report`}
        children={
          <GeneratReport
            csvDownload={csvDownload}
            setOpen={setOpen}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
          />
        }
        handleClose={() => {
          setOpen(false);
          setIsEdit(null);
        }}
        open={open}
      />
      <Modal
        header={"GRN Detail"}
        children={
          <GRNDetail
            setOpen={setOpenOpenGRN}
            // isEdit={isEdit}
            // setIsEdit={setIsEdit}
            handlePrint={handlePrint}
          />
        }
        handleClose={() => {
          setOpenOpenGRN(false);
          // setIsEdit(null);
        }}
        open={openGRN}
        minWidth={1250}
      />
      {/* {open && (
        <PrintGrn
          printGrnData={GRNDetail}
          componentRef={componentRef}
          grandTotalAddedGRN={grandTotalAddedGRN}
        />
      )} */}
    </div>
  );
}
