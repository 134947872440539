import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";

// import IconButton from "@mui/material/IconButton";
// import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
// import DatePicker from "../../../component/date";
import Schema from "../../../schema";
import AutoComplete from "../../../component/input/autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, FieldArray } from "formik";
import { addRecipes, createStoreGRN } from "./action";
import Input from "../../../component/input";
import { Box } from "@mui/material";
// import { getPOById } from "../po/action";

// import Select from "../../../component/select";
// import Utils from "../../../utility";
const AddUser = ({ setOpen, isEdit, setIsEdit }) => {
  const dispatch = useDispatch();
  // let navigate = useNavigate();

  // CATALOG_MANAGER =  'CAM',
  // ACCOUNTS_MANAGER = 'AM',
  // OPERATIONS_MANAGER = 'OM',
  // RUNNER = 'R',
  // CUSTOMER = 'C'
  // }

  // const validateFields = (event) => {
  //   if (event.target.name === "email") {
  //     setUsernameValid(emailValidator(emailId));
  //   }

  //   if (event.target.name === "password") {
  //     setPasswordValid(passwordValidator(password));
  //   }
  // };

  const initialValues = {
    recipeName: "",
    product: null,
  };
  const {
    // warehouseListForDropdown,
    // storeListForDropdown,
    brandOwnerListForDropdown,
    productsDropDownList,
    // brandOwnerListForDropdown,
  } = useSelector((state) => state.commonReducer);
  const { recipesProducts } = useSelector((state) => state.recipesReducer);
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Schema.RecipeNameSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        dispatch(addRecipes(values, setOpen));
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Box sx={{ display: "flex", mb: 2, alignItems: "center" }}>
            <Input
              value={values.recipeName}
              name={`recipeName`}
              type="text"
              error={errors.recipeName}
              label="Recipe Name"
              onChange={handleChange}
              handleBlur={handleBlur}
            />
            <AutoComplete
              freeSolo={false}
              values={values.product}
              onChange={(event, value) => {
                if (value !== null) {
                  const pIndex = recipesProducts.findIndex(
                    (x) => x.id === value.id
                  );
                  setFieldValue(`product`, value?.id);
                }
              }}
              helperText={touched?.product && errors?.product}
              error={Boolean(touched?.product && errors?.product)}
              name={`product`}
              label={"Chose Product"}
              // touched={touched.product}
              options={recipesProducts}
              getOptionLabel={(option) => `${option?.barcode} | ${option.name}`}
              // isRequired={true}
              getOptionSelected={(option, value) => {
                return `${option.id}` === `${value?.id}`;
              }}
              defaultValue={() => {
                const pIndex = recipesProducts.findIndex(
                  (x) => x?.id == values.product
                );
                return recipesProducts[pIndex];
              }}
              // isDisabled={isDisabled}
            />
          </Box>

          <Box sx={{ display: "flex", mb: 2, justifyContent: "flex-end" }}>
            <Button variant="outlined" type="submit">
              {isEdit?.length ? "update Recipe" : "Add Recipe"}
            </Button>
            <Button
              variant="outlined"
              onClick={() => setOpen(false)}
              style={{
                border: "1px solid #e13030",
                color: "#e13030",
                marginLeft: 10,
              }}
            >
              Cancel
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
    //  </Authlayout>
  );
};

export default AddUser;
