import Utils from ".";

export const checkImageDimension = (file, callImageUpload) => {
  if (file.size / 1024 > 200) {
    Utils.showAlert(2, "Please Upload a image less than 200kb");
    return;
  }
  if (file && file) {
    var img = document.createElement("img");
    img.onload = function () {
      callImageUpload(this.width, this.height);
    };
    var reader = new FileReader();
    reader.onloadend = function (ended) {
      img.src = ended.target.result;
    };
    reader.readAsDataURL(file);
  }
};

export const getUserTypeFromLs = () => {
  return localStorage.getItem("userType");
};
export const getUserNameFromLs = () => {
  return localStorage.getItem("username");
};
export const getUserIdFromLs = () => {
  return localStorage.getItem("id");
};
const commonFunction = {
  checkImageDimension,
  getUserTypeFromLs,
};

export default commonFunction;

export const removeEmptyValues = (data) => {
  for (var propName in data) {
    if (!data[propName] || data[propName].length === 0) {
      delete data[propName];
    } else if (typeof data[propName] === "object") {
      removeEmptyValues(data[propName]);
    }
  }
  return data;
};