import React, { useEffect, useState } from "react";
// import {  } from "react-bootstrap";
import Button from "@mui/material/Button";
// import MenuItem from "@mui/material/MenuItem";
// import CheckBox from "../../../component/checkbox";
// import DatePicker from "../../../component/date";
import Schema from "../../../schema";
// import moment from "moment";
// import AutoComplete from "../../../component/input/autocomplete";
// import { useNavigate } from "react-router-dom";
// import Uti from "../../utility/utils";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";

import { updatePropertyOwner, addBrand } from "./action";
import Input from "../../../component/input";
import { Box } from "@mui/material";

// import Select from "../../../component/select";
// import Utils from "../../../utility";
const AddUser = ({ setOpen, isEdit, setIsEdit }) => {
  const dispatch = useDispatch();
  // let navigate = useNavigate();

  // CATALOG_MANAGER =  'CAM',
  // ACCOUNTS_MANAGER = 'AM',
  // OPERATIONS_MANAGER = 'OM',
  // RUNNER = 'R',
  // CUSTOMER = 'C'
  // }

  // const validateFields = (event) => {
  //   if (event.target.name === "email") {
  //     setUsernameValid(emailValidator(emailId));
  //   }

  //   if (event.target.name === "password") {
  //     setPasswordValid(passwordValidator(password));
  //   }
  // };

  const initialValues = {
    brandName: isEdit?.length ? isEdit[0].brandName : "",
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Schema.brandSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        if (isEdit?.length) {
          // resetForm({})
          dispatch(
            updatePropertyOwner(
              values,
              setOpen,
              isEdit[0].id,
              resetForm,
              setIsEdit
            )
          );
        } else {
          dispatch(addBrand(values, setOpen));
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit}>
          <Box sx={{ display: "flex", mb: 2 }}>
            <Input
              value={values.brandName}
              name="brandName"
              type="text"
              error={errors.brandName}
              label="Brand Name"
              onChange={handleChange}
              handleBlur={handleBlur}
            />
          </Box>

          <Button variant="outlined" type="submit">
            {isEdit?.length ? "update Brand" : "Add Brand"}
          </Button>
        </Form>
      )}
    </Formik>
    //  </Authlayout>
  );
};

export default AddUser;
