// import React, { useRef, useState } from "react";
// import Button from "@mui/material/Button";
// import DeleteIcon from "@mui/icons-material/Delete";
// import IconButton from "@mui/material/IconButton";
// import { fileUpload } from "../../productRelated/products/action";
// import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
// import MenuItem from "@mui/material/MenuItem";
// // import DatePicker from "../../../component/date";
// import Schema from "../../../schema";
// import AutoComplete from "../../../component/input/autocomplete";
// import { useDispatch, useSelector } from "react-redux";
// import { Formik, Form, FieldArray } from "formik";
// import { createStoreGRN } from "./action";
// import Input from "../../../component/input";
// import { Box } from "@mui/material";
// import { getPOById } from "../po/action";
// import Select from "../../../component/select";
// import Utils from "../../../utility";
// import UploadImage from "../../../component/uploadImage";
// import moment from "moment";

// const AddUser = ({
//   setOpen,
//   isEdit,
//   storeId,
//   handlePrint,
//   grandTotal,
//   setGrandTotal,
// }) => {
//   const dispatch = useDispatch();
//   // const { productsDropDownList } = useSelector((state) => state.productsReducer);
//   const [isDisabled, setDisabled] = useState(false);
//   const initialValues = {
//     product: [],
//     invoice: isEdit?.length ? isEdit[0]?.invoice : "",
//     PONumber: null,
//     vehicle: null,
//     vendor: isEdit?.length ? isEdit[0]?.warehouseState : "",
//   };
//   const calculateGrandTotal = (values, index, currentValue, currentField) => {
//     const grandTotal = values.reduce((total, item, i) => {
//       if (i === index) {
//         if (currentField === "costPrice")
//           total = +currentValue * values[index].recievedQuantity + total;
//         else total = +currentValue * values[index].costPrice + total;
//       } else {
//         total = item.total + total;
//       }
//       console.log(total, "total");
//       return total;
//     }, 0);
//     setGrandTotal(grandTotal);
//   };
//   const {
//     // warehouseListForDropdown,
//     // storeListForDropdown,
//     brandOwnerListForDropdown,
//     productsDropDownList,
//     // brandOwnerListForDropdown,
//   } = useSelector((state) => state.commonReducer);
//   const { printGrnData } = useSelector((state) => state.storeStockReducer);
//   // const { brandOwnerListForDropdown } = useSelector((state) => state.brandOwnerReducer);

//   return (
//     <>
//       <Formik
//         enableReinitialize
//         initialValues={initialValues}
//         validationSchema={Schema.storeStockSchema}
//         onSubmit={(values, { setSubmitting, resetForm }) => {
//           dispatch(
//             createStoreGRN(values, setOpen, storeId, handlePrint, setSubmitting)
//           );
//         }}
//       >
//         {({
//           values,
//           errors,
//           touched,
//           setFieldValue,
//           handleChange,
//           handleBlur,
//           handleSubmit,
//           isSubmitting,
//         }) => (
//           <Form onSubmit={handleSubmit}>
//             {console.log(values, "valuesvalues")}
//             <Box sx={{ display: "flex", mb: 2, alignItems: "center" }}>
//               {/* <Select
//                 handleChange={handleChange}
//                 value={values.vendor}
//                 label={"vendor Id"}
//                 option={brandOwnerListForDropdown.map((item) => (
//                   <MenuItem key={item.id} value={item?.id}>
//                     {item?.user?.name}
//                   </MenuItem>
//                 ))}
//                 name="vendor"
//                 error={errors.vendor}
//               /> */}
//               <AutoComplete
//                 freeSolo={false}
//                 // handleChange={handleChange}
//                 value={values.vendor}
//                 onChange={(event, value) => {
//                   if (value !== null) {
//                     const pIndex = brandOwnerListForDropdown.findIndex(
//                       (x) => x.id === value.id
//                     );
//                     setFieldValue(`vendor`, value?.id);
//                   }
//                 }}
//                 key={values?.vendor}
//                 id={values?.vendor}
//                 helperText={touched?.vendor && errors?.vendor}
//                 error={Boolean(touched?.vendor && errors?.vendor)}
//                 name={`vendor`}
//                 label={"Vender"}
//                 // touched={touched.product}
//                 options={brandOwnerListForDropdown}
//                 getOptionLabel={(option) => `${option?.alias}`}
//                 // isRequired={true}
//                 getOptionSelected={(option, value) => {
//                   return `${option.id}` === `${value.id}`;
//                 }}
//                 defaultValue={() => {
//                   const pIndex = brandOwnerListForDropdown.findIndex(
//                     (x) => x?.id == values.vendor
//                   );
//                   return brandOwnerListForDropdown[pIndex];
//                 }}
//               />
//               <UploadImage
//                 handleChange={
//                   (e) =>
//                     dispatch(
//                       fileUpload(e.target.files[0], (res) => {
//                         setFieldValue("invoice", res.Location);
//                       })
//                     )
//                   // handleChanegImage(e, setFieldValue, "invoice")
//                 }
//                 lable="Invoice"
//                 image={values?.invoice}
//                 id="invoice"
//                 onDelete={() => setFieldValue("invoice", "")}
//                 errors={errors?.invoice}
//                 // onEdit = {}
//               />
//               {/* <p>{}</p> */}
//               {/*
//             <Input
//               value={values.invoice}
//               name="invoice"
//               type="text"
//               error={errors.invoice}
//               label="Invoice"
//               onChange={handleChange}
//               handleBlur={handleBlur}
//             /> */}
//               <Input
//                 value={values.PONumber}
//                 name="PONumber"
//                 type="text"
//                 error={errors.PONumber}
//                 label="PO Number"
//                 onChange={(e) => {
//                   setFieldValue("PONumber", e.target.value);
//                   // setFieldValue("", e.target.value);
//                 }}
//                 handleBlur={() => {
//                   dispatch(
//                     getPOById(values.PONumber, storeId, (res) => {
//                       if (res.store.id !== storeId) {
//                         Utils.showAlert(
//                           2,
//                           "This PO is not aplicable for this store"
//                         );
//                         return;
//                       }
//                       setDisabled(true);
//                       setFieldValue(
//                         "product",
//                         res.poProducts.map((item) => {
//                           return {
//                             product: item.product.id,
//                             invoiceQuantity: item.requiredQuantity,
//                             recievedQuantity: 0,
//                             // mrp: item?.product?.productMrp,
//                             total: 0,
//                             costPrice: item?.productCostPrice,
//                             sellingPrice: item?.productSellingPrice,
//                             batch: "",
//                           };
//                         })
//                       );

//                       setFieldValue("vendor", res?.vendor?.id);
//                     })
//                   );
//                 }}
//               />
//               <Input
//                 value={values.vehicle}
//                 name="vehicle"
//                 type="text"
//                 error={errors.vehicle}
//                 label="vehicle"
//                 onChange={handleChange}
//                 handleBlur={handleBlur}
//               />
//             </Box>
//             {/* <h1 onClick={handlePrint}>ni</h1> */}
//             <FieldArray
//               name="product"
//               render={(arrayHelpers) => {
//                 console.log(errors);
//                 return (
//                   <div>
//                     {values?.product && values?.product.length > 0
//                       ? values.product.map((val, index) => (
//                           <Box sx={{ display: "flex", mb: 2 }} key={index}>
//                             <AutoComplete
//                               freeSolo={false}
//                               values={values.product[index].product}
//                               onChange={(event, value) => {
//                                 if (value !== null) {
//                                   const pIndex = productsDropDownList.findIndex(
//                                     (x) => x.id === value.id
//                                   );
//                                   setFieldValue(
//                                     `product.${index}.product`,
//                                     value?.id
//                                   );
//                                 }
//                               }}
//                               helperText={
//                                 touched?.product &&
//                                 errors?.product &&
//                                 errors?.product[index]?.product
//                               }
//                               error={Boolean(
//                                 touched?.product &&
//                                   errors?.product &&
//                                   errors?.product[index]?.product
//                               )}
//                               name={`product.${index}.product`}
//                               label={"Product Id"}
//                               // touched={touched.product}
//                               options={productsDropDownList}
//                               getOptionLabel={(option) =>
//                                 `${option?.barcode} | ${option.name}`
//                               }
//                               // isRequired={true}
//                               getOptionSelected={(option, value) => {
//                                 return `${option.id}` === `${value?.id}`;
//                               }}
//                               defaultValue={() => {
//                                 const pIndex = productsDropDownList.findIndex(
//                                   (x) => x?.id == values.product[index].product
//                                 );
//                                 return productsDropDownList[pIndex];
//                               }}
//                               isDisabled={isDisabled}
//                             />
//                             <Input
//                               value={values.product[index].invoiceQuantity}
//                               name={`product.${index}.invoiceQuantity`}
//                               type="number"
//                               error={
//                                 errors.product &&
//                                 errors.product[index]?.invoiceQuantity
//                               }
//                               // error={errors.product}
//                               label="Inv. Quantity"
//                               onChange={handleChange}
//                               handleBlur={handleBlur}
//                               disabled={isDisabled}
//                             />
//                             <Input
//                               value={values.product[index].recievedQuantity}
//                               name={`product.${index}.recievedQuantity`}
//                               type="number"
//                               error={
//                                 errors.product &&
//                                 errors.product[index]?.recievedQuantity
//                               }
//                               label="Recd. Quantity"
//                               onChange={(e) => {
//                                 if (
//                                   e.target.value >
//                                   values.product[index].invoiceQuantity
//                                 ) {
//                                   return;
//                                 }
//                                 handleChange(e);

//                                 setFieldValue(
//                                   `product.${index}.total`,
//                                   +e.target.value *
//                                     values.product[index].costPrice
//                                 );
//                                 calculateGrandTotal(
//                                   values.product,
//                                   index,
//                                   e.target.value,
//                                   "recievedQuantity"
//                                 );
//                               }}
//                               handleBlur={handleBlur}
//                             />
//                             {/* <Input
//                               value={values.product[index].mrp}
//                               name={`product.${index}.mrp`}
//                               type="number"
//                               // error={errors.product}
//                               error={
//                                 errors.product && errors.product[index]?.mrp
//                               }
//                               label="mrp"
//                               onChange={handleChange}
//                               handleBlur={handleBlur}
//                             /> */}
//                             <Input
//                               value={values.product[index].sellingPrice}
//                               name={`product.${index}.sellingPrice`}
//                               type="number"
//                               // disabled={true}
//                               // disabled={isDisabled}
//                               error={
//                                 errors.product &&
//                                 errors.product[index]?.sellingPrice
//                               }
//                               // error={errors.product}
//                               label="sellingPrice"
//                               onChange={handleChange}
//                               handleBlur={handleBlur}
//                             />
//                             {/* <DatePicker
//                             value={values.product[index].batch}
//                             name={`product.${index}.batch`}
//                             handleChange={(newVal) =>
//                               setFieldValue(`product.${index}.batch`, newVal)
//                             }
//                             label="Batch"
//                             error={
//                               errors.product && errors.product[index]?.batch
//                             }
//                           /> */}
//                             <Input
//                               value={values.product[index].costPrice}
//                               name={`product.${index}.costPrice`}
//                               type="number"
//                               error={
//                                 errors.product &&
//                                 errors.product[index]?.costPrice
//                               }
//                               // error={errors.product}
//                               label="costPrice"
//                               onChange={(e) => {
//                                 handleChange(e);
//                                 setFieldValue(
//                                   `product.${index}.total`,
//                                   +e.target.value *
//                                     values.product[index].recievedQuantity
//                                 );

//                                 calculateGrandTotal(
//                                   values.product,
//                                   index,
//                                   e.target.value,
//                                   "costPrice"
//                                 );
//                               }}
//                               handleBlur={handleBlur}
//                               // disabled={isDisabled}
//                             />
//                             <Input
//                               value={values.product[index].total}
//                               name={`product.${index}.total`}
//                               type="number"
//                               disabled={true}
//                               error={
//                                 errors.product && errors.product[index]?.total
//                               }
//                               label="Total"
//                               onChange={handleChange}
//                               handleBlur={handleBlur}
//                             />
//                             {!isDisabled && (
//                               <IconButton
//                                 style={{
//                                   background: "rgb(231 112 112)",
//                                   color: "white",
//                                   width: 35,
//                                   height: 35,
//                                   borderRadius: 5,
//                                 }}
//                                 aria-label="delete"
//                                 size="small"
//                                 onClick={() => arrayHelpers.remove(index)}
//                               >
//                                 <DeleteIcon fontSize="inherit" />
//                               </IconButton>
//                             )}
//                           </Box>
//                         ))
//                       : null}
//                     <Box sx={{ display: "flex", mb: 2 }}>
//                       <Box
//                         sx={{
//                           width: "100%",
//                           pr: 2,
//                           maxWidth: "100%",
//                           textAlign: "right",
//                         }}
//                       >
//                         Grand Total
//                       </Box>

//                       <Input
//                         value={grandTotal}
//                         disabled
//                         name="grandTotal"
//                         type="number"
//                         // error={errors.batch}
//                         label="Grand Total "
//                       />

//                       {!isDisabled && (
//                         <IconButton
//                           style={{
//                             background: "green",
//                             color: "white",
//                             width: 35,
//                             height: 35,
//                             borderRadius: 5,
//                           }}
//                           aria-label="delete"
//                           size="small"
//                           onClick={() =>
//                             arrayHelpers.push({
//                               product: null,
//                               invoiceQuantity: null,
//                               recievedQuantity: null,
//                               // mrp: null,
//                               total: 0,
//                               // landingPrice: null,
//                               costPrice: 0,
//                               sellingPrice: 0,
//                               batch: "",
//                             })
//                           }
//                         >
//                           <AddBoxTwoToneIcon fontSize="inherit" />
//                         </IconButton>
//                       )}
//                     </Box>
//                   </div>
//                 );
//               }}
//             />

//             <Box sx={{ display: "flex", mb: 2, justifyContent: "flex-end" }}>
//               <Button
//                 variant="outlined"
//                 type="submit"
//                 isDisabled={isSubmitting}
//               >
//                 {isEdit?.length ? "update GRN" : "Add GRN"}
//               </Button>
//               <Button
//                 variant="outlined"
//                 onClick={() => setOpen(false)}
//                 style={{
//                   border: "1px solid #e13030",
//                   color: "#e13030",
//                   marginLeft: 10,
//                 }}
//               >
//                 Cancel
//               </Button>
//             </Box>
//           </Form>
//         )}
//       </Formik>
//     </>
//     //  </Authlayout>
//   );
// };

// export default AddUser;

import React, { useState } from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

// import IconButton from "@mui/material/IconButton";
// import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
// import DatePicker from "../../../component/date";
import Schema from "../../../schema";
import AutoComplete from "../../../component/input/autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, FieldArray } from "formik";
import { createStoreGRN } from "./action";
import Input from "../../../component/input";
import { Box } from "@mui/material";
import { getPOById } from "../po/action";
import Utils from "../../../utility";

const AddUser = ({
  setOpen,
  isEdit,
  storeId,
  handlePrint,
  grandTotal,
  setGrandTotal,
}) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  // const { productsDropDownList } = useSelector((state) => state.productsReducer);
  const [isDisabled, setDisabled] = useState(false);
  const { loading } = useSelector((state) => state.loaderReducer);
  const initialValues = {
    product: [],
    invoice: isEdit?.length ? isEdit[0]?.invoicePath || isEdit[0]?.invoice : "",
    PONumber: null,
    invoiceNumber: null,
    vendor: isEdit?.length ? isEdit[0]?.warehouseState : "",
  };
  const calculateGrandTotal = (values, index, currentValue, currentField) => {
    const grandTotal = values.reduce((total, item, i) => {
      if (i === index) {
        if (currentField === "costPrice")
          total = +currentValue * values[index].recievedQuantity + total;
        else total = +currentValue * values[index].costPrice + total;
      } else {
        total = item.total + total;
      }
      return total;
    }, 0);
    setGrandTotal(grandTotal);
  };
  const {
    // warehouseListForDropdown,
    // storeListForDropdown,
    brandOwnerListForDropdown,
    productsDropDownList,
    // brandOwnerListForDropdown,
  } = useSelector((state) => state.commonReducer);
  // const { brandOwnerListForDropdown } = useSelector((state) => state.brandOwnerReducer);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Schema.storeStockSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          // setSubmitting(true);
          if (loading) return;
          dispatch(
            createStoreGRN(values, setOpen, storeId, handlePrint, setSubmitting)
          );
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldError,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Box sx={{ display: "flex", mb: 2, alignItems: "center" }}>
              {/* <Select
                handleChange={handleChange}
                value={values.vendor}
                label={"vendor Id"}
                option={brandOwnerListForDropdown.map((item) => (
                  <MenuItem key={item.id} value={item?.id}>
                    {item?.user?.name}
                  </MenuItem>
                ))}
                name="vendor"
                error={errors.vendor}
              /> */}
              <AutoComplete
                freeSolo={false}
                // handleChange={handleChange}
                value={values.vendor}
                onChange={(event, value) => {
                  if (value !== null) {
                    setFieldValue(`vendor`, value?.id);
                  }
                }}
                key={values?.vendor}
                id={values?.vendor}
                helperText={touched?.vendor && errors?.vendor}
                error={Boolean(touched?.vendor && errors?.vendor)}
                name={`vendor`}
                label={"Vendor"}
                // touched={touched.product}
                options={brandOwnerListForDropdown || []}
                getOptionLabel={(option) =>
                  `${option?.alias} | ${option?.GST} `
                }
                // isRequired={true}
                getOptionSelected={(option, value) => {
                  return `${option.id}` === `${value.id}`;
                }}
                defaultValue={() => {
                  const pIndex = brandOwnerListForDropdown.findIndex(
                    (x) => x?.id == values.vendor
                  );
                  return brandOwnerListForDropdown[pIndex];
                }}
              />
              {/* <UploadImage
                handleChange={
                  (e) =>
                    dispatch(
                      fileUpload(e.target.files[0], (res) => {
                        setFieldValue("invoice", res.Location);
                      })
                    )
                  // handleChanegImage(e, setFieldValue, "invoice")
                }
                lable="Invoice"
                image={values?.invoice}
                id="invoice"
                onDelete={() => setFieldValue("invoice", "")}
                errors={errors?.invoice}
                // onEdit = {}
              /> */}
              {/* <p>{}</p> */}
              {/* 
            <Input
              value={values.invoice}
              name="invoice"
              type="text"
              error={errors.invoice}
              label="Invoice"
              onChange={handleChange}
              handleBlur={handleBlur}
            /> */}
              {/* <Input
                value={values.PONumber}
                name="PONumber"
                type="text"
                error={errors.PONumber}
                label="PO Number"
                onChange={(e) => {
                  setFieldValue("PONumber", e.target.value);
                }}
                handleBlur={() => {
                  dispatch(
                    getPOById(values.PONumber, storeId, (res) => {
                      if (res.store.id !== storeId) {
                        Utils.showAlert(
                          2,
                          "This PO is not aplicable for this store"
                        );
                        return;
                      }
                      setDisabled(true);
                      const poProducts = res.poProducts.map((item) => {
                        return {
                          product: item.product.id,
                          invoiceQuantity: item.requiredQuantity,
                          recievedQuantity: 0,
                          barcode: item.product.barcode,
                          // mrp: item?.product?.productMrp,
                          total: 0,
                          costPrice: item?.productCostPrice,
                          sellingPrice: item?.productSellingPrice,
                          batch: "",
                        };
                      });
                      setFieldValue("product", poProducts);
                      setFieldValue("poProduct", poProducts);

                      setFieldValue("vendor", res?.vendor?.id);
                    })
                  );
                }}
              /> */}
              <Input
                value={values.invoiceNumber}
                name="invoiceNumber"
                type="text"
                error={errors.invoiceNumber}
                label="Invoice number"
                onChange={handleChange}
                handleBlur={handleBlur}
              />
            </Box>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "30px",
              }}
            >
              <TextField
                id="standard-search"
                label="Search using barcode"
                type="search"
                variant="standard"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  if (!e.target.value) {
                    let newTotal = 0;
                    const nwPoProduct = values.poProduct.map((item) => {
                      if (
                        values?.product &&
                        item.barcode == values?.product[0]?.barcode
                      ) {
                        newTotal = newTotal + values?.product[0]?.total;
                        return values.product[0];
                      } else {
                        newTotal = newTotal + item.total;
                        return item;
                      }
                    });
                    setGrandTotal(newTotal);
                    setFieldValue("product", nwPoProduct);
                    // calculateGrandTotal(
                    //   nwPoProduct,
                    //   index,
                    //   e.target.value,
                    //   "recievedQuantity"
                    // );
                    setFieldValue("poProduct", nwPoProduct);
                    return;
                  }
                  const filteredProduct = values?.poProduct.filter(
                    (item) => item.barcode == e.target.value?.trim()
                  );
                  setFieldValue("product", filteredProduct);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            {/* <h1 onClick={handlePrint}>ni</h1> */}
            <FieldArray
              name="product"
              render={(arrayHelpers) => {
                return (
                  <div>
                    {values?.product && values?.product.length > 0
                      ? values.product.map((val, index) => (
                          <Box
                            sx={{ display: "flex", mb: 2 }}
                            key={val?.barcode}
                          >
                            <AutoComplete
                              freeSolo={false}
                              values={values.product[index].product}
                              onChange={(event, value) => {
                                if (value !== null) {
                                  const pIndex = productsDropDownList.findIndex(
                                    (x) => x.id === value.id
                                  );
                                  setFieldValue(
                                    `product.${index}.product`,
                                    value?.id
                                  );
                                }
                              }}
                              helperText={
                                touched?.product &&
                                errors?.product &&
                                errors?.product[index]?.product
                              }
                              error={Boolean(
                                touched?.product &&
                                  errors?.product &&
                                  errors?.product[index]?.product
                              )}
                              name={`product.${index}.product`}
                              label={"Product Id"}
                              // touched={touched.product}
                              options={productsDropDownList.filter(
                                (item) =>
                                  !values.product
                                    .map((x) => x.product)
                                    .includes(item?.id)
                              )}
                              getOptionLabel={(option) =>
                                `${option?.barcode} | ${option.name}`
                              }
                              // isRequired={true}
                              getOptionSelected={(option, value) => {
                                return `${option.id}` === `${value?.id}`;
                              }}
                              defaultValue={() => {
                                const pIndex = productsDropDownList.findIndex(
                                  (x) => x?.id == values.product[index].product
                                );
                                return productsDropDownList[pIndex];
                              }}
                              isDisabled={isDisabled}
                            />
                            {/* {console.log(values.product, "shashank")} */}
                            {/* <Input
                              value={values.product[index].invoiceQuantity}
                              name={`product.${index}.invoiceQuantity`}
                              type="number"
                              error={
                                errors.product &&
                                errors.product[index]?.invoiceQuantity
                              }
                              // error={errors.product}
                              label="Inv. Quantity"
                              onChange={handleChange}
                              handleBlur={handleBlur}
                              disabled={isDisabled}
                            /> */}
                            {/* {console.log(errors, "shashank")} */}
                            <Input
                              value={values.product[index].recievedQuantity}
                              name={`product.${index}.recievedQuantity`}
                              type="number"
                              error={
                                errors.product &&
                                errors.product[index]?.recievedQuantity
                              }
                              label="Recd. Quantity"
                              onChange={(e) => {
                                handleChange(e);
                                setFieldValue(
                                  `product.${index}.total`,
                                  +e.target.value *
                                    values.product[index].costPrice
                                );
                                calculateGrandTotal(
                                  values.product,
                                  index,
                                  e.target.value,
                                  "recievedQuantity"
                                );
                              }}
                              handleBlur={(e) => {
                                if (values.product.length !== 1) {
                                  setFieldValue(
                                    `poProduct.${index}.recievedQuantity`,
                                    e.target.value
                                  );
                                  setFieldValue(
                                    `poProduct.${index}.total`,
                                    +e.target.value *
                                      values.product[index].costPrice
                                  );
                                  calculateGrandTotal(
                                    values.poProduct,
                                    index,
                                    e.target.value,
                                    "recievedQuantity"
                                  );
                                  // handleChange(e);
                                  handleBlur(e);
                                }
                              }}
                            />
                            {/* <Input
                              value={values.product[index].mrp}
                              name={`product.${index}.mrp`}
                              type="number"
                              // error={errors.product}
                              error={
                                errors.product && errors.product[index]?.mrp
                              }
                              label="mrp"
                              onChange={handleChange}
                              handleBlur={handleBlur}
                            /> */}
                            <Input
                              value={values.product[index].sellingPrice}
                              name={`product.${index}.sellingPrice`}
                              type="number"
                              // disabled={true}
                              // disabled={isDisabled}
                              error={
                                errors.product &&
                                errors.product[index]?.sellingPrice
                              }
                              // error={errors.product}
                              label="sellingPrice"
                              onChange={handleChange}
                              handleBlur={(e) => {
                                if (values.product.length !== 1) {
                                  setFieldValue(
                                    `poProduct.${index}.sellingPrice`,
                                    e.target.value
                                  );
                                  handleBlur(e);
                                }
                              }}
                            />
                            {/* <DatePicker
                            value={values.product[index].batch}
                            name={`product.${index}.batch`}
                            handleChange={(newVal) =>
                              setFieldValue(`product.${index}.batch`, newVal)
                            }
                            label="Batch"
                            error={
                              errors.product && errors.product[index]?.batch
                            }
                          /> */}
                            <Input
                              value={values.product[index].costPrice}
                              name={`product.${index}.costPrice`}
                              type="number"
                              error={
                                errors.product &&
                                errors.product[index]?.costPrice
                              }
                              // error={errors.product}
                              label="Cost price incl taxes"
                              onChange={(e) => {
                                handleChange(e);
                                setFieldValue(
                                  `product.${index}.total`,
                                  +e.target.value *
                                    values.product[index].recievedQuantity
                                );

                                calculateGrandTotal(
                                  values.product,
                                  index,
                                  e.target.value,
                                  "costPrice"
                                );
                              }}
                              handleBlur={(e) => {
                                if (values.product.length !== 1) {
                                  setFieldValue(
                                    `poProduct.${index}.costPrice`,
                                    e.target.value
                                  );
                                  setFieldValue(
                                    `poProduct.${index}.total`,
                                    +e.target.value *
                                      values.product[index].recievedQuantity
                                  );

                                  handleBlur(e);
                                }
                              }}
                              disabled={loading}
                            />
                            <Input
                              value={values.product[index].total}
                              name={`product.${index}.total`}
                              type="number"
                              disabled={true}
                              error={
                                errors.product && errors.product[index]?.total
                              }
                              label="Total"
                              onChange={handleChange}
                              handleBlur={handleBlur}
                            />
                            {!isDisabled && (
                              <IconButton
                                style={{
                                  background: "rgb(231 112 112)",
                                  color: "white",
                                  width: 35,
                                  height: 35,
                                  borderRadius: 5,
                                }}
                                aria-label="delete"
                                size="small"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <DeleteIcon fontSize="inherit" />
                              </IconButton>
                            )}
                          </Box>
                        ))
                      : null}
                    <Box sx={{ display: "flex", mb: 2 }}>
                      <Box
                        sx={{
                          width: "100%",
                          pr: 2,
                          maxWidth: "100%",
                          textAlign: "right",
                        }}
                      >
                        Grand Total
                      </Box>

                      <Input
                        value={grandTotal}
                        disabled
                        name="grandTotal"
                        type="number"
                        // error={errors.batch}
                        label="Grand Total "
                      />

                      {!isDisabled && (
                        <IconButton
                          style={{
                            background: "green",
                            color: "white",
                            width: 35,
                            height: 35,
                            borderRadius: 5,
                          }}
                          aria-label="delete"
                          size="small"
                          onClick={() =>
                            arrayHelpers.push({
                              product: null,
                              // invoiceQuantity: null,
                              recievedQuantity: null,
                              // mrp: null,
                              total: 0,
                              // landingPrice: null,
                              costPrice: 0,
                              sellingPrice: 0,
                              batch: "",
                            })
                          }
                        >
                          <AddBoxTwoToneIcon fontSize="inherit" />
                        </IconButton>
                      )}
                    </Box>
                  </div>
                );
              }}
            />

            <Box sx={{ display: "flex", mb: 2, justifyContent: "flex-end" }}>
              <Button
                variant="outlined"
                type="submit"
                disabled={search || loading}
              >
                {isEdit?.length ? "update GRN" : "Add GRN"}
              </Button>
              <Button
                variant="outlined"
                onClick={() => setOpen(false)}
                style={{
                  border: "1px solid #e13030",
                  color: "#e13030",
                  marginLeft: 10,
                }}
              >
                Cancel
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
    //  </Authlayout>
  );
};

export default AddUser;
