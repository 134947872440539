import * as React from "react";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch } from "react-redux";
import { getOrdersById, updateSingleOrderStatus } from "./action";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const Index = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { ordersList } = useSelector((state) => state?.onlineOrdersReducer);

  var totalItems = [];
  ordersList?.map((data) =>
    data?.actualBillingSent?.map((b) => totalItems?.push(b?.quantity))
  );

  var DeliveryId;
  ordersList?.map((data) => (DeliveryId = data?.id));

  const sum = totalItems?.reduce((total, n) => total + n, 0);

  React.useEffect(() => {
    dispatch(getOrdersById({ orderId: id }));
    // eslint-disable-next-line
  }, []);

  const markAsDelivered = () => {
    dispatch(updateSingleOrderStatus(DeliveryId, "delivered"));
  };

  return (
    <>
      {ordersList?.map((data) => (
        <Box key={data?.id}>
          <Box sx={{ flex: 1, m: 1 }}>
            <p>
              <b>Order No</b>:- #{data?.id}
            </p>
            <p>
              <b>Customer Name</b>:- {data?.customer?.name}
            </p>
            <p>
              <b>Customer Phone</b>:- {data?.customer?.phoneNumber}
            </p>
            <p>
              <b>Customer Address</b>:- {data?.fromAddress?.addressLine1},{" "}
              {data?.fromAddress?.addressLine2}, {data?.fromAddress?.city},{" "}
              {data?.fromAddress?.state}.
            </p>
          </Box>
          <Button
            sx={{
              p: 2,
              width: "100%",
              backgroundColor: "yellow",
              textAlign: "center",
            }}
            onClick={() =>
              window.open(
                `https://maps.google.com/?q=${data?.store?.lat},${data?.store?.long}`
              )
            }
          >
            <b>Open Maps</b>
          </Button>
          <Box
            sx={{
              mt: 1,
              p: 2,
              backgroundColor: "skyblue",
              textAlign: "center",
              fontSize: 22,
              fontWeight: 500,
              cursor: "pointer",
            }}
            onClick={() => window.open(`tel:${data?.customer?.phoneNumber}`)}
          >
            Call Customer
          </Box>
          <Box>
            <h5>Items</h5>
            <Box>
              {data?.actualBillingSent?.map((b) => (
                <Box sx={{ display: "flex" }} key={b?.id}>
                  <span>
                    ( {b?.product?.barcode} ) Take out {b?.product?.name} (MRP:{" "}
                    {b?.sellingPrice}){" "}
                  </span>
                  <ClearIcon /> <span>{b?.quantity}</span>
                </Box>
              ))}
              <h4 style={{ margin: 10 }}>Total Items {sum}</h4>
            </Box>
            {data?.orderStatus === "delivered" ? null : (
              <Box
                sx={{
                  mt: 1,
                  p: 2,
                  backgroundColor: "yellow",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                <b onClick={markAsDelivered}>Mark Delivered</b>
              </Box>
            )}
          </Box>
        </Box>
      ))}
      {/* <a href={`https://maps.google.com/?q=28.231321,77.213214214`}>Open map</a> */}
    </>
  );
};

export default Index;
