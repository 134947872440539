import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AutoComplete from "../../component/input/autocomplete";
import { IconButton, Box } from "@mui/material";
import { NavLink } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";

import Fade from "@mui/material/Fade";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { useNavigate } from "react-router-dom";
import Modal from "../modal";
import { Formik, Form, FieldArray } from "formik";
import Input from "../input";
import { updatePassword } from "../../pages/userAccount/users/action";
import { useDispatch, useSelector } from "react-redux";
import Schema from "../../schema";
import MoreIcon from "@mui/icons-material/MoreVert";
import { changeUserPassword } from "./action";
import { logout } from "../../pages/auth/action";

export default function FadeMenu() {
  const dispatch = useDispatch();
  // const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [openChange, setOpenChange] = React.useState(false);
  const [openChangeUserPassword, setOpenChangeUserPassword] =
    React.useState(false);
  const { permissions, userlistForDropdown } = useSelector(
    (state) => state.commonReducer
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ display: "flex" }}>
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
          <>
            <NavLink
              to="#"
              style={{ padding: 10, fontWeight: 700 }}
              {...bindTrigger(popupState)}
            >
              Support
            </NavLink>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Typography sx={{ p: 2 }}>
                <b>24 hours tech Helpline</b>
                <br /> +91 83687 69360
              </Typography>
              <hr />
              <Typography
                sx={{ p: 2 }}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open("https://support.thenewshop.in/support/login")
                }
              >
                {" "}
                <b> Report other issues</b>{" "}
              </Typography>
              <br />
            </Popover>
          </>
        )}
      </PopupState>
      <Box
        sx={{
          display: "flex",
          textAlign: "center",
          lineHeight: 1.4,
          alignItems: "center",
          padding: "5px 10px",
          borderRadius: "8px",
          background: "#ebebeb",
          color: "black",
          marginRight: "20px",
        }}
      >
        Loggedin as:- {localStorage.getItem("username")}
      </Box>
      <IconButton
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        color="inherit"
        aria-label="open drawer"
        edge="start"
        sx={{
          marginRight: 1,
          display: "block",
          // ...(open && { display: 'none' }),
        }}
      >
        <MoreIcon sx={{ color: "black", fontSize: 30 }} />
      </IconButton>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={() => {
            setOpenChange(true);
            handleClose();
          }}
        >
          Change Password
        </MenuItem>
        {localStorage.getItem("username") == "mani" ? (
          <MenuItem
            onClick={() => {
              setOpenChangeUserPassword(true);
              handleClose();
            }}
          >
            Change User Password
          </MenuItem>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <MenuItem
          onClick={() => {
            dispatch(logout(navigate));
            dispatch({
              type: "USER_LOGOUT",
            });
            // localStorage.clear();
            handleClose();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
      <Modal
        header={"Change Password"}
        children={
          <div>
            {/* <h1>Change Password</h1> */}
            <Formik
              enableReinitialize
              initialValues={{
                newPassword: "",
                confirmPassword: "",
              }}
              validationSchema={Schema.ChangePassword}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                dispatch(updatePassword(values, setOpenChange));
              }}
            >
              {({
                values,
                errors,

                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Box sx={{ display: "flex", mb: 2 }}>
                    <Input
                      value={values.terms}
                      name="newPassword"
                      type="text"
                      error={errors.newPassword}
                      label="new Password"
                      onChange={handleChange}
                      handleBlur={handleBlur}
                      inputProps={{
                        autoComplete: "off",
                      }}
                    />
                    <Input
                      value={values.confirmPassword}
                      name="confirmPassword"
                      type="text"
                      inputProps={{
                        autoComplete: "off",
                      }}
                      error={errors.confirmPassword}
                      label="Confirm password"
                      onChange={handleChange}
                      handleBlur={handleBlur}
                    />
                  </Box>

                  <Box
                    sx={{ display: "flex", mb: 2, justifyContent: "flex-end" }}
                  >
                    <Button variant="outlined" type="submit">
                      Change Password
                    </Button>
                    <Button
                      onClick={() => setOpenChange(false)}
                      variant="outlined"
                      style={{
                        border: "1px solid #e13030",
                        color: "#e13030",
                        marginLeft: 10,
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </div>
        }
        handleClose={() => {
          setOpenChange(false);
          //   setIsEdit(null);
        }}
        open={openChange}
        minWidth={500}
      />
      <Modal
        header={"Change Users Password"}
        children={
          <div>
            {/* <h1>Change Password</h1> */}
            <Formik
              enableReinitialize
              initialValues={{
                userId: "",
                confirmPassword: "",
              }}
              // validationSchema={Schema.ChangePassword}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                dispatch(changeUserPassword(values, setOpenChangeUserPassword));
              }}
            >
              {({
                values,
                errors,
                setFieldValue,
                handleChange,
                handleBlur,
                touched,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Box sx={{ display: "flex", mb: 2 }}>
                    <AutoComplete
                      freeSolo={false}
                      // onBlur={handleBlur}
                      values={values.userId}
                      onChange={(event, value) => {
                        if (value !== null) {
                          // const index = propertyOwnerList.findIndex(
                          //   (x) => x.id === value.id
                          // );

                          setFieldValue("userId", value?.id);
                        }
                      }}
                      helperText={touched.userId && errors.userId}
                      error={Boolean(touched.userId && errors.userId)}
                      name={"userId"}
                      label={"userId"}
                      touched={touched.userId}
                      options={userlistForDropdown}
                      getOptionLabel={(option) => option?.name}
                      // isRequired={true}
                      getOptionSelected={(option, value) => {
                        return `${option.id}` === `${value.id}`;
                      }}
                      defaultValue={() => {
                        const index = userlistForDropdown?.findIndex(
                          (x) => x?.id == values.userId
                        );
                        return userlistForDropdown[index];
                      }}
                    />
                    <Input
                      value={values.confirmPassword}
                      name="confirmPassword"
                      type="text"
                      inputProps={{
                        autoComplete: "off",
                      }}
                      error={errors.confirmPassword}
                      label="Confirm password"
                      onChange={handleChange}
                      handleBlur={handleBlur}
                    />
                  </Box>

                  <Box
                    sx={{ display: "flex", mb: 2, justifyContent: "flex-end" }}
                  >
                    <Button variant="outlined" type="submit">
                      Change Password
                    </Button>
                    <Button
                      onClick={() => setOpenChangeUserPassword(false)}
                      variant="outlined"
                      style={{
                        border: "1px solid #e13030",
                        color: "#e13030",
                        marginLeft: 10,
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </div>
        }
        handleClose={() => {
          setOpenChangeUserPassword(false);
          //   setIsEdit(null);
        }}
        open={openChangeUserPassword}
        minWidth={500}
      />
    </div>
  );
}
