import Utils from "../../../utility";
import moment from "moment";
export const getOrderList = () => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const {
      order,
      sortField,
      storeId,
      offset,
      take,
      search,
      toDate,
      fromDate,
    } = getState().orderReducer;
    Utils.api.getApiCall(
      Utils.endPoints.orders,
      `?order=${order}&sortField=${sortField}&offset=${offset}&take=${take}&search=${search}&store=${
        storeId ? storeId : ""
      }${
        fromDate
          ? "&fromDate=" + moment(fromDate).format("YYYY-MM-DD") + " 00:00:00"
          : ""
      }${
        toDate ? "&toDate=" + moment(toDate).format("YYYY-MM-DD")+ " 23:59:59" : ""
      }`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.ORDER,
          payload: {
            orderList: respData.data?.order,
            count: respData?.data?.count,
            prevCount: respData?.data?.count,
          },
        });
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.orders} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const getOrderListInInterval = (callBack) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const {
      order,
      sortField,
      storeId,
      offset,
      take,
      search,
      toDate,
      fromDate,
      count,
    } = getState().orderReducer;
    Utils.api.getApiCall(
      Utils.endPoints.orders,
      `?order=${order}&sortField=${sortField}&offset=${offset}&take=${take}&search=${search}&store=${
        storeId ? storeId : ""
      }${
        fromDate
          ? "&fromDate=" + moment(fromDate).format("YYYY-MM-DD HH:mm:ss")
          : ""
      }${
        toDate ? "&toDate=" + moment(toDate).format("YYYY-MM-DD HH:mm:ss") : ""
      }`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.ORDER,
          payload: {
            orderList: respData.data?.order,
            // count: respData?.data?.count,
          },
        });
        callBack(respData?.data?.count, count);
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.orders} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const getOrderExportList = (csvDownload, setSubmitting) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const { order, sortField, storeId, offset, toDate, fromDate } =
      getState().orderReducer;
    Utils.api.getApiCall(
      Utils.endPoints.orders,
      `?order=${order}&sortField=${sortField}&offset=${offset}&search=&store=${
        storeId ? storeId : ""
      }&fromDate=${moment(fromDate).format("YYYY-MM-DD")}T00:00&toDate=${moment(
        toDate
      ).format("YYYY-MM-DD")}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.ORDER,
          payload: {
            exportData: respData.data?.order,
          },
        });
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        setTimeout(() => {
          csvDownload.current.link.click();
        }, 500);
        setSubmitting(false);
        // if (respData.data?.order?.length)
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.orders} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        setSubmitting(false);
      }
    );
  };
};

export const fileUpload = (file) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const { images } = getState().productsReducer;
    // const { brandName } = values;
    var dataToSend = new FormData();
    dataToSend.append("file", file);

    Utils.api.postApiCall(
      Utils.endPoints.fileUpload,
      dataToSend,
      (respData) => {
        dispatch({
          type: Utils.ActionName.PRODUCTS,
          payload: { images: [...images, respData.data.Location] },
        });
        // setOpen(false);
        Utils.showAlert(1, "File uploaded successfully");
        // dispatch(getProducts());
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `${Utils.endPoints.fileUpload} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};

export const orderInvoice = (id) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    // const { images } = getState().productsReducer;
    // // const { brandName } = values;
    // var dataToSend = new FormData();
    // dataToSend.append("file", file);

    Utils.api.getApiCall(
      Utils.endPoints.orderInvoice + id,
      "",
      (respData) => {
        var winparams =
          "dependent=yes,locationbar=no,scrollbars=yes,menubar=yes," +
          "resizable,screenX=50,screenY=50,width=850,height=1050";

        var htmlPop =
          "<embed width=100% height=100%" +
          ' type="application/pdf"' +
          ' src="data:application/pdf;base64,' +
          escape(respData?.data) +
          '"></embed>';

        var printWindow = window.open("", "PDF", winparams);
        printWindow.document.write(htmlPop);
        // printWindow.print();

        // const linkSource = `data:application/pdf;base64,${respData.data}`;
        // const downloadLink = document.createElement("a");
        // const fileName = "abc.pdf";
        // downloadLink.href = linkSource;
        // downloadLink.download = fileName;
        // downloadLink.click();

        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // dispatch({
        //   type: Utils.ActionName.PRODUCTS,
        //   payload: { images: [...images, respData.data.Location] },
        // });
        // setOpen(false);
        Utils.showAlert(1, "successfully");
        // dispatch(getProducts());
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error?.join(","));
        Utils.showAlert(
          2,
          `${Utils.endPoints.orderInvoice} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};

export const generatReport = (values, setSubmitting) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });

    const { storeId, toDate, fromDate } = values;
    Utils.api.getApiCall(
      Utils.endPoints.orderCSVReport,
      `?fromDate=${moment(fromDate).format("YYYY-MM-DD")}T00:00&toDate=${moment(
        toDate
      ).format("YYYY-MM-DD")}T23:59${storeId ? `&store=${storeId}` : ""}`,
      (respData) => {
        // dispatch({
        //   type: Utils.ActionName.ORDER,
        //   payload: {
        //     exportData: respData.data?.order,
        //   },
        // });

        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        const linkSource = `data:application/csv;base64,${respData.data}`;
        const downloadLink = document.createElement("a");
        const fileName = `order_report.csv`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        // setTimeout(() => {
        //   csvDownload.current.link.click();
        // }, 500);
        setSubmitting(false);
        // if (respData.data?.order?.length)
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.orderCSVReport} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        setSubmitting(false);
      }
    );
  };
};
